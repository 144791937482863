import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';
import Header from './Header';
import SideBar from './SideBar';
import Content from './Content';

export default class Admin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      drawerPos: 2,
      drawerShow: true
    };
  }
  _handleDrawer = () => {
    this.setState({ drawerShow: !this.state.drawerShow });
  };
  render() {
    return (
      <div
        className="admin"
         
      >

        <div className='app-header'>
        <Header
          callback={this._handleDrawer.bind(this)}
          toggletheme={this.toggletheme}
        />
        </div>

        <div className='app-wrapper'>
          <div className='side-menu'>
          <SideBar drawerPos={this.state.drawerPos} />
          </div>

          <div className='contant-area contentPage'>
          <Content />
          </div>
        </div>


        {/* <Header
          callback={this._handleDrawer.bind(this)}
          toggletheme={this.toggletheme}
        />
        <div className='container-fluid'>
          <Row style={{ marginTop: '71px' }}>
            <Col
              className='col-2'
              style={{
                 
              }}
            >
              <SideBar drawerPos={this.state.drawerPos} />
            </Col>

            <Col
   className='col-10'
              style={{
                marginTop: '0',
                paddingLeft: '0px',
                marginLeft: this.state.drawerPos > 1 ? '0px' : ' 0 10px'
              }}
            >
              <div className="contentPage">
                <Content />
              </div>
            </Col>
          </Row>
        </div> */}
      </div>
    );
  }
}
