import React, { Component } from "react";
export default class Page404 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      count: 0
    };
  }
  UNSAFE_componentWillMount = () => {
    this.timer = setInterval(() => {
      if (this.state.count < 404) {
        this.setState({
          count: this.state.count + 1
        });
      } else {
        clearInterval(this.timer);
      }
    }, 1);
  };
  render() {
    return (
      <div className="page404">
        <div class="container">
          <div class="row">
            <div class="xs-12 md-6 mx-auto">
              <div id="countUp">
                <div class="number" data-count="404">
                  {this.state.count}
                </div>
                <div class="text">Page not found</div>
                <div class="text">This may not mean anything.</div>
                <div class="text">
                  I'm probably working on something that has blown up.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
