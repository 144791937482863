import React, { useState, useRef } from 'react'
import Toaster from 'src/admin/utils/Toaster';
import imageCompression from 'browser-image-compression';
import FileUpload from 'src/admin/templates/FileUpload';
import LocalStorage from 'src/admin/config/LocalStorage';
import { useLocation, useParams } from 'react-router'
import APIRequest from '../utils/APIRequest';
import ConfigAPIURL from '../config/ConfigAPIURL';
import { useHistory } from "react-router-dom";
import {
  TextField,
} from '@material-ui/core';
import { useEffect } from 'react';
import isEmpty from '../utils/isEmpty';
import MenuItem from '@material-ui/core/MenuItem';
import { RichTextEditor } from '@mantine/rte';

export default function CreateAssignmentPage(props) {
  const history = useHistory()
  const [files, setFiles] = React.useState([]);
  const [title, setTitle] = React.useState([]);
  const [desc, setDesc] = React.useState([]);
  var [fileurl, setFileurl] = React.useState([]);

  const [titleerror, setTitleError] = useState({});
  const [descerror, setDescError] = useState({});
  const [fileerror, setFileError] = useState({});

  var [faculty, setFaculty] = useState([])

  var [selectedfaculty, setSelectedFaculty] = React.useState('');

  const [fileUploadStatus, setFileUploadStatus] = useState(false);
  const profileImageFileUpload = useRef();
  const [profileImageFileBase64, setProfileImageFileBase64] = useState('');
  const [profileImageFile, setProfileImageFile] = useState('');
  const [aws, setAws] = useState(LocalStorage.aws);
  const fileInputRef = useRef(null);

  // const location = useLocation();
  // const params = new URLSearchParams(location.search);
  // const course = params.get('course');
  // const batch = params.get('batch');

  var [options, setOptions] = useState([])
  var [selectedBatch, setSelectedBatch] = React.useState('');
  var [selectedCourse, setSelectedCourse] = React.useState('');
  var [courseOptions, setCourseOptions] = useState([])
  var [batch, setBatch] = useState([])

  const handleTitleChange = (event) => {
    setTitle(event.target.value)
  }

  const handleDescChange = (event) => {
    setDesc(event)
  }
  const handleFileChange = (e) => {

    const data = [];

    for (var i = 0; i < e.target.files.length; i++) {
      data.push(e.target.files[i]);
    }
    setFiles((old) => [...old, ...data])
  }

  const oncancel = () => {
    setFiles([])
    history.push('/admin/assignment');
  }

  const filedelete = (index) => {
    var newFileList = Array.from(files);
    newFileList.splice(index, 1);
    setFiles(newFileList)
  }

  useEffect(() => {
    getBatch()
    getCourse()
  }, [])

  const handleBatchChange = (e) => {
    selectedBatch = e.target.value
    setSelectedBatch(selectedBatch);
  }

  const handleFacultyChange = (e) => {
    selectedfaculty = e.target.value
    setSelectedFaculty(selectedfaculty)
  }

  const getBatch = () => {
    APIRequest.request('POST', ConfigAPIURL.listOfDropdowns, JSON.stringify({ name: "Batch Name" })).then(
      response => {
        if (response.code === 100 && response.data.responseCode === 109) {
          options = response.data.result.options
          setOptions(options);
          batch = options.filter(e => e.active && !isEmpty(e.value)).map(e => e.value)
          setBatch(batch);
          setSelectedBatch(batch[0])
        }
      }
    );
  }

  const handleCourseChange = (e) => {
    selectedCourse = e.target.value;
    setSelectedCourse(selectedCourse);
  }

  const getCourse = () => {
    APIRequest.request('GET', ConfigAPIURL.adminAllModuleList, '').then(
      response => {
        if (response.code === 100 && response.data.responseCode === 109) {
          courseOptions = response.data.module
          setCourseOptions(courseOptions);
          setSelectedCourse(courseOptions[0]['_id'])
          getFacultyDrop();
        }
      }
    );
  }

  const getFacultyDrop = () => {
    APIRequest.request('GET', ConfigAPIURL.facultydrop).then(res => {
      if (!isEmpty(res) && res.code === 100) {
        if (res.data.responseCode === 109) {
          faculty = res.data.users
          setFaculty(faculty)
          selectedfaculty = faculty[0]['_id']
          setSelectedFaculty(selectedfaculty)

        }
      }
    });
  }
  const submit = async (e) => {
    e.preventDefault()
    const isValid = formValidation()
    if (isValid) {
      let formFileData = new FormData();
      for (var i = 0; i < files.length; i++) {
        let file = files[i];

        if (file !== undefined && file !== null) {
          if (
            file.type !== undefined &&
            file.type !== null
          ) {
            let reader = new FileReader();
            let url = '';
            if (
              files[i].type.startsWith('image') &&
              files[i].size > 1000000
            ) {
              Toaster.info(
                'Compressing file. Please wait for a while.',
                'topRight'
              );
              var options = {
                maxSizeMB: 1,
                maxWidthOrHeight: 1920,
                useWebWorker: true
              };
              const compressedFile = await imageCompression(
                files[i],
                options
              );
              formFileData.append(`attachment`, compressedFile);
            } else if (files[i].size < 200000000) {
              formFileData.append(`attachment`, file);
            } else {
              Toaster.error(
                'File size is too large, Max. file size is 200mb.',
                'topRight'
              );
              return;
            }
          }
        }
      }
      formFileData.append("bucketName", "quizImage");
      formFileData.append("type", "assignment");
      APIRequest.multipartForDataRequest('PUT', ConfigAPIURL.adminUploadFile, formFileData).then(response => {
        if (response.code === 100 && response.data.responseCode === 109) {

          //submit api

          const data = {
            title: title,
            description: desc,
            files: response.data.attachmentUrl,
            batchName: selectedBatch,
            course: selectedCourse,
            user: selectedfaculty

          }

          APIRequest.request('POST', ConfigAPIURL.createassignment, JSON.stringify(data)).then(
            response => {
              if (response.code === 100 && response.data.responseCode === 109) {
                Toaster.sucess(
                  'Assignment created successfully.',
                  'topRight'
                );
                history.push('/admin/assignment');
              }
            }
          );
        }
      });
    }

  }

  const formValidation = () => {
    const titleerror = {};
    const descerror = {};
    const fileerror = {};

    let isValid = true;

    if (title == undefined || title == '') {
      titleerror.titleempty = "Please input title"
      isValid = false
    }
    if (desc == undefined || desc == '') {
      descerror.descempty = "Please input description"
      isValid = false
    }

    if (files == undefined || files == '') {
      fileerror.docempty = "Please upload file"
      isValid = false
    }

    setTitleError(titleerror);
    setDescError(descerror);
    setFileError(fileerror)
    return isValid;

  }

  return (
    <div className="py-4 create-assignment">
      <h5 className="fw-bold text-black ps-3">Create Assignment</h5>

      <div className="border-primary bg-white py-4 mt-4 px-3">
        <div className="row">
          <div className="col-md-3">
            <TextField
              className='drop_down_common mt-3'
              label="Course Name"
              select
              variant="outlined"
              value={selectedCourse}
              onChange={handleCourseChange}
            >
              {courseOptions.map(({ title, _id }, index) => <MenuItem value={_id} >{title}</MenuItem>)}
            </TextField>
          </div>
          <div className="col-md-3">
            <TextField
              className='drop_down_common mt-3'
              label="Batch Name"
              select
              variant="outlined"
              value={selectedBatch}
              onChange={handleBatchChange}
            >
              {batch.map((index) => <MenuItem value={index} >{index}</MenuItem>)}
            </TextField>
          </div>
          <div className="col-md-3">
            <TextField
              className='drop_down_common mt-3'
              label="Faculty Name"
              select
              value={selectedfaculty}
              variant="outlined"
              onChange={handleFacultyChange}
            >
              {faculty.map((item) => <MenuItem value={item._id} >{item.name}</MenuItem>)}
            </TextField>
          </div>
        </div>

        <label className='mt-4 small' >Title</label>
        <input type="text" name="" id="" placeholder='Enter Title' className='form-control' onChange={handleTitleChange} />
        {Object.keys(titleerror).map((key) => {
          return <div className='err-msg'>{titleerror[key]}</div>
        })}
        <label className="mt-4 small">Description</label>
        {/* <textarea name="" id="" cols="30" rows="4" className='form-control' placeholder='Enter Discription'
         onChange={handleDescChange}></textarea> */}

         <div className='rich_editor'>
        <RichTextEditor onChange={handleDescChange} />

        {Object.keys(descerror).map((key) => {
          return <div className='err-msg'>{descerror[key]}</div>
        })}
        </div>
        <div className='description'>
        </div>
        <div className="drag-drop">
          <input name="" multiple id="" className='form-control mt-2'
            ref={fileInputRef}
            type="file"
            onChange={event => handleFileChange(event)}
          />
        </div>

        <small className='form-text text-muted'>
          Max file upload limit is 200 MB.
        </small>
        {Object.keys(fileerror).map((key) => {
          return <div className='err-msg'>{fileerror[key]}</div>
        })}

        {files.map((item, index) => {
          return (
            <div className='d-flex file-lists my-2' key={index}>
              <p className='mb-1'>{item.name}</p>
              <a onClick={() => filedelete(index)}> <span className="ms-1 text-danger">remove</span></a>
            </div>
          );
        })}
          <div class="alert alert-primary small mt-2 fw-600" role="alert">
          You can add one or multiple files. To add more files click on the file uploader.
          </div>

        <div className="text-end mt-4">
          <div className="btn btn-secondary me-3" onClick={oncancel}>Cancel</div>
          <div className="btn btn-primary" onClick={submit}>Submit</div>
        </div>
        {/* <FileUpload
        ref={profileImageFileUpload}
        aws={aws}
        file={profileImageFile}
        base64={profileImageFileBase64}
        bucket={aws.bucket}
        callback={_s3ImageUrlProfileUploadImage}
      /> */}
      </div>
    </div>
  )
}
