import React from 'react'
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import MenuItem from '@material-ui/core/MenuItem';
import Select from 'react-select'
import { useEffect, useState, useRef } from 'react';
import APIRequest from '../utils/APIRequest';
import ConfigAPIURL from '../config/ConfigAPIURL';
import isEmpty from '../utils/isEmpty';
import Toaster from 'src/admin/utils/Toaster';

export default function FacultySendPopup(userid) {

  const [open, setOpen] = React.useState(false);
  var [options, setOptions] = useState([])
  var [batch, setBatch] = useState([])
  var [courseOptions, setCourseOptions] = useState([])
  var [selectedBatch, setSelectedBatch] = React.useState('');
  var [selectedCourse, setSelectedCourse] = React.useState('');
  var [userOptions, setUserOptions] = useState([])
  var [personName, setPersonName] = useState([]);
  const [checked, setChecked] = React.useState(false);
  const [emailerror, setEmailError] = useState({});
  const [courseid, setCourseId] = useState('');
  var [selectedoptions, setSelectedOptions] = useState([]);

  const handleCourseChange = (e) => {
    setCourseId(e.target.value)
    setSelectedCourse(e.target.value);
    getUsersList(e.target.value, selectedBatch);
    setPersonName([])
    setSelectedOptions([])
  }

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setChecked(true)
    getCourse()
    setPersonName()
  };
  const handleBatchChange = (e) => {
    setSelectedBatch(e.target.value);
    setPersonName([])
    setSelectedOptions([])
    getUsersList(selectedCourse, e.target.value);
  }

  const handleCheckChange = () => {
    setChecked(!checked);
    if (checked == false) {
      setPersonName()
    }
  }

  const handleEmailChange = (newValue) => {
    let useremails = newValue.map(e => ({ user: e.value }))
    setPersonName(useremails);
    setSelectedOptions(newValue);
  }

  useEffect(() => {
    getCourse()
  }, [])

  const getBatch = () => {
    APIRequest.request('POST', ConfigAPIURL.listOfDropdowns, JSON.stringify({ name: "Batch Name" })).then(
      response => {
        if (response.code === 100 && response.data.responseCode === 109) {
          options = response.data.result.options
          setOptions(options);
          batch = options.filter(e => e.active && !isEmpty(e.value)).map(e => e.value)
          setBatch(batch);
          setSelectedBatch(batch[0])
          getUsersList(selectedCourse, batch[0])
        }
      }
    );
  }

  const getCourse = () => {
    APIRequest.request('GET', ConfigAPIURL.adminAllModuleList, '').then(
      response => {
        if (response.code === 100 && response.data.responseCode === 109) {
          courseOptions = response.data.module
          setCourseOptions(courseOptions);
          selectedCourse = courseOptions[0]['_id'];
          setSelectedCourse(courseOptions[0]['_id'])
          setCourseId(courseOptions[0]['_id'])
          getBatch()
        }
      }
    );
  }

  const getUsersList = (course, batch) => {
    APIRequest.request('GET', ConfigAPIURL.baseUrl + '/admin/user/batch-users?courseName=' + course + '&batchName=' + batch).then(res => {
      if (!isEmpty(res) && res.code === 100) {
        if (res.data.responseCode === 109) {
          setUserOptions(res.data.traineeUsers)
          userOptions = res.data.traineeUsers
        }
      }
    });
  }

  const submit = (e) => {
    if (checked == true) {
      let useremails = userOptions.map(e => ({ user: e._id }));
      setPersonName(useremails);
      const data = {
        batchName: selectedBatch,
        course: courseid,
        users: useremails,
        isAllUsers: checked,
      }
      let id = userid.userid
      if (useremails == '') {
        window.alert("no users under this selection")
      }
      else {
        APIRequest.request('PUT', ConfigAPIURL.sendassignment + id, JSON.stringify(data)).then(
          response => {
            if (response.code === 100 && response.data.responseCode === 109) {
              Toaster.sucess(
                'Assignment sent successfully.',
                'topRight'
              );
              handleClose()
            }
          }
        );
      }
    }
    else {
      e.preventDefault()
      const isValid = formValidation()
      const data = {
        batchName: selectedBatch,
        course: courseid,
        users: personName,
        isAllUsers: checked,
      }
      let id = userid.userid
      if (personName != '') {
        APIRequest.request('PUT', ConfigAPIURL.sendassignment + id, JSON.stringify(data)).then(
          response => {
            if (response.code === 100 && response.data.responseCode === 109) {
              Toaster.sucess(
                'Assignment sent successfully.',
                'topRight'
              );
              handleClose()
            }
          }
        );
      }
    }
  }
  const formValidation = () => {
    const emailerror = {};
    let isValid = true;
    if (personName == undefined || personName == '') {
      emailerror.noemail = "Please select users"
      isValid = false
    }
    setEmailError(emailerror);
    return isValid;
  }
  return (
    <div className=''>
      <Button onClick={handleClickOpen} className="btn btn-primary">
        Share
      </Button>
      <Dialog open={open} onClose={handleClose} maxWidth="xs" fullWidth="true">
        <DialogTitle className='fw-700'>Share Assignment</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <div className='mt-4'>
              <TextField
                className='drop_down_common'
                label="Course Name"
                select
                variant="outlined"
                value={selectedCourse}
                onChange={e => handleCourseChange(e)}
              >
                {courseOptions.map(({ title, _id }, item, index) => <MenuItem value={_id} >{title}</MenuItem>)}
              </TextField>
            </div>
            <div className='mt-5'>
              <TextField
                className='drop_down_common'
                label="Batch Name"
                select
                variant="outlined"
                value={selectedBatch}
                onChange={handleBatchChange}
              >
                {batch.map((index) => <MenuItem value={index} >{index}</MenuItem>)}
              </TextField>
            </div>
            <div className="mt-4 mb-2 ms-2">
              <input type="checkbox" name="" id="" checked={checked} onChange={handleCheckChange} />&nbsp;&nbsp;All Users
            </div>
            {checked ? '' : <div className="mt-4 ps-1 pe-1">
              <Select
                value={selectedoptions}
                defaultValue=""
                isMulti
                name=""
                className="basic-multi-select"
                classNamePrefix="select"
                options={userOptions.map(e => ({ label: e.email, value: e._id }))}
                onChange={newValue => handleEmailChange(newValue)}
              />
              {Object.keys(emailerror).map((key) => {
                return <div style={{ color: "red" }}>{emailerror[key]}</div>
              })}
            </div>}
          </DialogContentText>
          <DialogContent>
          </DialogContent>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} className='btn btn-secondary'>Cancel</Button>
          <Button onClick={submit} className='btn btn-primary'>Submit</Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}
