import React, { Suspense, Fragment, lazy } from 'react';
import { Switch, HashRouter, Redirect, Route } from 'react-router-dom';
import DashboardLayout from 'src/layouts/DashboardLayout';
import DocsLayout from 'src/layouts/DocsLayout';
import Admin from 'src/admin/Admin';
import MainLayout from 'src/layouts/MainLayout';
import HomeView from 'src/views/home/HomeView';
import LoadingScreen from 'src/components/LoadingScreen';
import AuthGuard from 'src/components/AuthGuard';
import GuestGuard from 'src/components/GuestGuard';
import PrivateRoute from './admin/privateRouteValidation/PrivateRoute';
import AuthNewGuard from './admin/privateRouteValidation/AuthNewGuard';

export const renderRoutes = (routes = []) => (
  <Suspense fallback={<LoadingScreen />}>
    <HashRouter>
      <Switch>
        {routes.map((route, i) => {
          const Guard = route.guard || Fragment;
          const Layout = route.layout || Fragment;
          const Component = route.component;

          return (
            <Route
              key={i}
              path={route.path}
              exact={route.exact}
              render={props => (
                <Guard>
                  <Layout>
                    {route.routes ? (
                      renderRoutes(route.routes)
                    ) : (
                      <Component {...props} />
                    )}
                  </Layout>
                </Guard>
              )}
            />
          );
        })}
      </Switch>
    </HashRouter>
  </Suspense>
);

const routes = [
  {
    exact: true,
    path: '/404',
    component: lazy(() => import('src/views/errors/NotFoundView'))
  },

  {
    exact: true,
    path: '/invite/:id',
    component: lazy(() => import('src/views/invite-users/Invite'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/',
    component: () => <Redirect to="/login" />
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/login',
    component: lazy(() => import('src/views/auth/LoginView'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/forgot-password',
    component: lazy(() => import('src/views/forgot-password/ForgotPassword'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/reset-password/:token',
    component: lazy(() => import('src/views/forgot-password/ResetPassword'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/feedback-form/:role/:roleName',
    component: lazy(() => import('src/views/feedback-form/FeedbackForm'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/register',
    component: lazy(() => import('src/views/auth/RegisterView'))
  },
  {
    path: '/app',
    guard: AuthNewGuard,
    layout: DashboardLayout,
    routes: [
      {
        exact: true,
        path: '/app',
        component: () => <Redirect to="/app/courses" />
      },
      {
        exact: true,
        path: '/app/courses',
        component: () => <Redirect to="/app/courses/browse" />
      },
      {
        exact: true,
        path: '/app/courses/browse',
        component: lazy(() => import('src/views/course/CourseBrowseView'))
      },
      {
        exact: true,
        path: '/app/social',
        component: () => <Redirect to="/app/social/profile" />
      },
      {
        exact: true,
        path: '/app/social/profile',
        component: lazy(() => import('src/views/social/ProfileView'))
      },
      {
        exact: true,
        path: '/app/account',
        component: lazy(() => import('src/views/account/AccountView'))
      },
      {
        exact: true,
        path: '/app/trainee-assignment',
        component: lazy(() =>
          import('src/views/trainee-assignment/TraineeAssignment')
        )
      },
      {
        exact: true,
        path: '/app/certificates',
        component: lazy(() =>
          import('src/views/certificates/Certificates')
        )
      },
      {
        exact: true,
        path: '/app/view-assignment/:assignid',
        component: lazy(() =>
          import('src/views/trainee-assignment/ViewAssignment')
        )
      },
      {
        exact: true,
        guard: GuestGuard,
        path: '/app/change-password',
        component: lazy(() =>
          import('src/views/auth/change-password/ChangePassword')
        )
      },
      {
        exact: true,
        path: '/app/trainee-user-manual',
        component: lazy(() => import('src/views/trainee-user-manual/TraineeUserManual'))
      },
      {
        exact: true,
        path: '/app/contact',
        component: lazy(() => import('src/views/contact/contact'))
      },
    ]
  },
  {
    path: '/faculty',
    guard: AuthNewGuard,
    layout: DashboardLayout,
    routes: [
      {
        exact: true,
        path: '/faculty/assignment',
        component: lazy(() =>
          import('src/admin/faculty-assignment/FacultyAssignment')
        )
      },
      {
        exact: true,
        path: '/faculty/assignment-manage/:userid',
        component: lazy(() =>
          import('src/admin/faculty-assignment/FacultyAssignmentTabs')
        )
      },
      {
        exact: true,
        path: '/faculty/view-assignment/:userid',
        component: lazy(() =>
          import('src/admin/faculty-assignment/FacultyViewAssignment')
        )
      },
      
      {
        exact: true,
        path: '/faculty/assessment',
        component: lazy(() =>
          import('src/admin/faculty-assessment/FacultyAssessment')
        )
      }
    ]
  },
  {
    path: '/course/:id',
    guard: AuthNewGuard,
    routes: [
      {
        exact: true,
        path: '/course/:id',
        component: lazy(props => import('src/views/course/CourseDetails'))
      },
      {
        exact: true,
        path: '/course/chapter/certificate',
        component: lazy(props => import('src/node'))
      }
    ]
  },

  {
    exact: true,
    path: '/admin/login',
    component: lazy(() => import('src/general/Login'))
  },
  {
    path: '/admin',
    guard: PrivateRoute,
    layout: Admin,
    routes: [
      {
        exact: true,
        path: '/admin',
        component: () => <Redirect to="/admin/plan" />
      },

      {
        exact: true,
        path: '/admin/plan',
        component: lazy(() => import('src/admin/plan/Plans'))
      },
      {
        exact: true,
        path: '/admin/course',
        component: lazy(() => import('src/admin/module/v2/courses'))
      },
      {
        exact: true,
        path: '/admin/profile',
        component: lazy(() => import('src/admin/profile/Profile'))
      },
      {
        exact: true,
        path: '/admin/quiz/results',
        component: lazy(() => import('src/admin/results/Results'))
      },
      {
        exact: true,
        path: '/admin/user',
        component: lazy(() => import('src/admin/user/User'))
      },
      {
        exact: true,
        path: '/admin/quiz',
        component: lazy(() => import('src/admin/quiz/Quiz'))
      },
      {
        exact: true,
        path: '/admin/chapter',
        component: lazy(() => import('src/admin/chapter/Chapter'))
      },
      {
        exact: true,
        path: '/admin/settings',
        component: lazy(() => import('src/admin/settings/Settings'))
      },
      {
        exact: true,
        path: '/admin/Batches',
        component: lazy(() => import('src/admin/batches/Batches'))
      },
      {
        exact: true,
        path: '/admin/assignment-manage',
        component: lazy(() => import('src/admin/assignment/AssignmentTabs'))
      },
      {
        exact: true,
        path: '/admin/assignment',
        component: lazy(() => import('src/admin/assignment/Assignment'))
      },

      {
        exact: true,
        path: '/admin/create-assignment',
        component: lazy(() => import('src/admin/assignment/CreateAssignmentPage'))
      },

      {
        exact: true,
        path: '/admin/view-assignment',
        component: lazy(() => import('src/admin/assignment/ViewAssignment'))
      },

      {
        exact: true,
        path: '/admin/assessment',
        component: lazy(() => import('src/admin/assessment/Assessment'))
      },

      {
        exact: true,
        path: '/admin/certificates',
        component: lazy(() => import('src/admin/certificates/Certificates'))
      },

      {
        exact: true,
        path: '/admin/change-password',
        component: lazy(() =>
          import('src/admin/change-password/ChangePassword')
        )
      },
      {
        exact: true,
        path: '/admin/feedback',
        component: lazy(() => import('src/admin/feedback/feedback'))
      },
      {
        exact: true,
        path: '/admin/logout',
        component: lazy(() => import('src/admin/Admin'))
      }

      // {
      //   component: () => <Redirect to="/404" />
      // }
    ]
  },
  {
    path: '*',
    layout: MainLayout,
    routes: [
      // {
      //   exact: true,
      //   path: '/',
      //   component: HomeView
      // },
      {
        component: () => <Redirect to="/404" />
      }
    ]
  }
];

export default routes;
