import React from 'react';
import {
  FaShare,
  FaReply,
  FaMoneyBillWave,
  FaScrewdriver,
  FaListAlt,
  FaUsers,
  FaCalendarDay,
  FaUserAlt,
  FaMoneyBillWaveAlt,
  FaFileAlt,
  FaListUl
} from 'react-icons/fa';
import { FcDataConfiguration } from 'react-icons/fc';
import { BsFillAwardFill } from 'react-icons/bs';
import { TiTickOutline } from 'react-icons/ti';
import GroupIcon from '@material-ui/icons/Group';
import AssignmentIcon from '@mui/icons-material/Assignment';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn'
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import MilitaryTechIcon from '@mui/icons-material/MilitaryTech';
import { AiOutlineFileProtect } from 'react-icons/ai';
import { GiScissors, GiWorld } from 'react-icons/gi';
import { GrChapterAdd } from 'react-icons/gr';
import { FiSettings } from 'react-icons/fi';
import { IoIosPeople, IoIosFunnel, IoIosCreate } from 'react-icons/io';
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer';
import PostAddIcon from '@material-ui/icons/PostAdd';
import SettingsIcon from '@material-ui/icons/Settings';
import { GiTakeMyMoney } from 'react-icons/gi';
import PeopleIcon from '@material-ui/icons/People';
import DashboardIcon from '@material-ui/icons/Dashboard';
import CreateIcon from '@material-ui/icons/Create';
import FeedbackIcon from '@mui/icons-material/Feedback';
import {
  AiOutlineTable,
  AiOutlineVerticalLeft,
  AiFillBank
} from 'react-icons/ai';
// import LocalStorage from '../../config/LocalStorage';

class SideBarMenu {
  //Base URL For Live & Staging
  static menu = [
    //User
    {
      display: 'Users',
      link: '/admin/user',
      linkPage: true,
      onclick: this.toggleLocation,
      toDisplay: 'User',
      urlEndWith: 'user',
      className: '',
      icon: (
        <GroupIcon
          fontSize="large"
          className="sideNavIcon"
          style={{ color: '#D6B268', backgroundColor: '#000' }}
        />
      ),
      eventKey: 'user',
      subMenu: []
    },
    //Plan
    // {
    //   display: 'Plan',
    //   link: '/admin/plan',
    //   linkPage: true,
    //   onclick: this.toggleLocation,
    //   toDisplay: 'Plan',
    //   urlEndWith: 'plan',
    //   className: 'd-none',
    //   icon: (
    //     <DashboardIcon
    //       fontSize="large"
    //       className="sideNavIcon"
    //       style={{
    //         color: '#D6B268',
    //         backgroundColor: '#000'
    //       }}
    //     />
    //   ),
    //   eventKey: 'plan',
    //   subMenu: []
    // },
    //Module
    {
      display: 'Courses',
      link: '/admin/course',
      linkPage: true,
      onclick: this.toggleLocation,
      toDisplay: 'Courses',
      urlEndWith: 'course',
      className: '',
      icon: (
        <LibraryBooksIcon
          fontSize="large"
          className="sideNavIcon"
          style={{ color: '#D6B268', backgroundColor: '#000' }}
        />
      ),
      eventKey: 'module',
      subMenu: []
    },
    //Quiz
    {
      display: 'Quiz',
      link: '/admin/quiz',
      linkPage: true,
      onclick: this.toggleLocation,
      toDisplay: 'Quiz',
      urlEndWith: 'quiz',
      className: '',
      icon: (
        <CreateIcon
          fontSize="large"
          className="sideNavIcon"
          style={{
            color: '#D6B268',
            backgroundColor: '#000'
          }}
        />
      ),
      eventKey: 'quiz',
      subMenu: []
    },
    //Chapter
    {
      display: 'Chapter',
      link: '/admin/chapter',
      linkPage: true,
      onclick: this.toggleLocation,
      toDisplay: 'Chapter',
      urlEndWith: 'chapter',
      className: '',
      icon: (
        <PostAddIcon
          fontSize="large"
          className="sideNavIcon"
          style={{ color: '#D6B268', backgroundColor: '#000' }}
        />
      ),
      eventKey: 'chapter',
      subMenu: []
    },
    //QuizResults
    {
      display: 'Results',
      link: '/admin/quiz/results',
      linkPage: true,
      onclick: this.toggleLocation,
      toDisplay: 'Results',
      urlEndWith: 'results',
      className: '',
      icon: (
        <QuestionAnswerIcon
          fontSize="large"
          className="sideNavIcon"
          style={{ color: '#D6B268', backgroundColor: '#000' }}
        />
      ),
      eventKey: 'results',
      subMenu: []
    },
    {
      display: 'Batches',
      link: '/admin/batches',
      linkPage: true,
      onclick: this.toggleLocation,
      toDisplay: 'Batches',
      urlEndWith: 'batches',
      className: '',
      icon: (
        <GroupIcon
          fontSize="large"
          className="sideNavIcon"
          style={{ color: '#D6B268', backgroundColor: '#000' }}
        />
      ),
      eventKey: 'module',
      subMenu: []
    },
    {
      display: 'Assignment',
      link: '/admin/assignment',
      linkPage: true,
      onclick: this.toggleLocation,
      toDisplay: 'Assignment',
      urlEndWith: 'assignment',
      className: '',
      icon: (
        <AssignmentIcon 
        fontSize="large"
          className="sideNavIcon"
          style={{ color: '#D6B268', backgroundColor: '#000' }}/>
      ),
      eventKey: 'module',
      subMenu: []
    },
    {
      display: 'Certificates',
      link: '/admin/certificates',
      linkPage: true,
      onclick: this.toggleLocation,
      toDisplay: 'Certificates',
      urlEndWith: 'certificates',
      className: '',
      icon: (
        <MilitaryTechIcon 
        fontSize="large"
          className="sideNavIcon"
          style={{ color: '#D6B268', backgroundColor: '#000' }}/>
      ),
      eventKey: 'module',
      subMenu: []
    },
    {
      display: 'Assessment',
      link: '/admin/assessment',
      linkPage: true,
      onclick: this.toggleLocation,
      toDisplay: 'Assessment',
      urlEndWith: 'assessment',
      className: '',
      icon: (
        <AssignmentTurnedInIcon 
        fontSize="large"
          className="sideNavIcon"
          style={{ color: '#D6B268', backgroundColor: '#000' }}/>
      ),
      eventKey: 'module',
      subMenu: []
    },
    {
      display: 'Feedback',
      link: '/admin/feedback',
      linkPage: true,
      onclick: this.toggleLocation,
      toDisplay: 'Feedback',
      urlEndWith: 'feedback',
      className: '',
      icon: (
        <FeedbackIcon
        fontSize="large"
          className="sideNavIcon"
          style={{ color: '#D6B268', backgroundColor: '#000' }}/>
      ),
      eventKey: 'module',
      subMenu: []
    },
    //Settings
    {
      display: 'Settings',
      link: '/admin/settings',
      linkPage: true,
      onclick: this.toggleLocation,
      toDisplay: 'Settings',
      urlEndWith: 'settings',
      className: '',
      icon: (
        <SettingsIcon
          fontSize="large"
          className="sideNavIcon"
          style={{ color: '#D6B268', backgroundColor: '#000' }}
        />
      ),
      eventKey: 'settings',
      subMenu: []
    },
    //Logout
    // {
    //   display: 'Logout',
    //   link: '/admin/logout',
    //   linkPage: true,
    //   // onclick: this.toggleLocation,
    //   toDisplay: 'Logout',
    //   urlEndWith: 'logout',
    //   className: '',
    //   icon: (
    //     <PowerSettingsNewIcon
    //       fontSize="large"
    //       className="sideNavIcon"
    //       style={{ color: '#D6B268', backgroundColor: '#000' }}
    //     />
    //   ),
    //   eventKey: 'logout',
    //   subMenu: []
    // }
    // //Payment
    // {
    // 	display: ' payment',
    // 	link: '/user/payment',
    // 	linkPage: false,
    // 	onclick: null,
    // 	urlEndWith: 'payment',
    // 	className: '',
    // 	icon: <FaFileAlt className="sideNavIcon" style={{ color: '#ed9c28' }} />,
    // 	eventKey: 'payment',
    // 	subMenu: [
    // 		{
    // 			display: ' inbound',
    // 			link: '#/user/payment/inbound',
    // 			localStorage: true,
    // 			toDisplay: "inbound",
    // 			urlEndWith: 'inbound',
    // 			className: '',
    // 			icon: <FaShare className="sideNavIcon" />,
    // 			eventKey: 'payment_inbound',
    // 			subMenu: [],
    // 		},
    // 		{
    // 			display: ' core',
    // 			link: '#/user/payment/core',
    // 			localStorage: true,
    // 			toDisplay: "core",
    // 			urlEndWith: 'core',
    // 			className: '',
    // 			icon: <MdNewReleases className="sideNavIcon" />,
    // 			eventKey: 'payment_core',
    // 			subMenu: [
    // 				{
    // 					display: ' screening',
    // 					link: '#/user/payment/core/corescreening',
    // 					localStorage: true,
    // 					urlEndWith: 'corescreening',
    // 					toDisplay: "screening",
    // 					className: '',
    // 					icon: <TiTickOutline className="sideNavIcon" />,
    // 				},
    // 				{
    // 					display: ' error',
    // 					link: '#/user/payment/core/coreerror',
    // 					localStorage: true,
    // 					toDisplay: "error",
    // 					urlEndWith: 'coreerror',
    // 					className: '',
    // 					icon: <GiScissors className="sideNavIcon" />,
    // 				},
    // 				{
    // 					display: ' repairs',
    // 					link: '#/user/payment/core/corerepair',
    // 					localStorage: true,
    // 					toDisplay: "repairs",
    // 					urlEndWith: 'corerepair',
    // 					className: '',
    // 					icon: <AiOutlineTable className="sideNavIcon" />,
    // 				},
    // 			],
    // 		},
    // 		{
    // 			display: ' outbound',
    // 			link: '#/user/payment/outbound',
    // 			localStorage: true,
    // 			toDisplay: "outbound",
    // 			urlEndWith: 'outbound',
    // 			className: '',
    // 			icon: <FaReply className="sideNavIcon" />,
    // 			eventKey: 'payment_outbound',
    // 			subMenu: [],
    // 		},
    // 	],
    // },
    // //Statement
    // {
    // 	display: ' statement',
    // 	link: '/user/statement',
    // 	linkPage: false,
    // 	onclick: null,
    // 	urlEndWith: 'statement',
    // 	className: '',
    // 	icon: <FaFileAlt className="sideNavIcon" style={{ color: '#D6B268', backgroundColor: "#000" }} />,
    // 	eventKey: 'statement',
    // 	subMenu: [
    // 		{
    // 			display: ' inboundstatement',
    // 			link: '#/user/statement/inboundstatement',
    // 			localStorage: true,
    // 			toDisplay: "inboundstatement",
    // 			urlEndWith: 'inboundstatement',
    // 			className: '',
    // 			icon: <FaShare className="sideNavIcon" />,
    // 			eventKey: 'statement_inboundstatement',
    // 			subMenu: [],
    // 		},
    // 		{
    // 			display: ' detailsstatement',
    // 			link: '#/user/statement/details',
    // 			localStorage: true,
    // 			toDisplay: "detailsstatement",
    // 			urlEndWith: 'details',
    // 			className: '',
    // 			icon: <FaListUl className="sideNavIcon" />,
    // 			eventKey: 'statement_detailsstatement',
    // 			subMenu: [],
    // 		},
    // 		{
    // 			display: ' cashPosition',
    // 			link: '#/user/statement/cashposition',
    // 			localStorage: true,
    // 			toDisplay: "cashPosition",
    // 			urlEndWith: 'cashposition',
    // 			className: '',
    // 			icon: <FaListUl className="sideNavIcon" />,
    // 			eventKey: 'statement_cashposition',
    // 			subMenu: [],
    // 		},
    // 	],
    // },
    // //Task
    // {
    // 	display: ' task',
    // 	link: '/user/task',
    // 	linkPage: false,
    // 	onclick: null,
    // 	urlEndWith: 'task',
    // 	className: '',
    // 	icon: <MdDns className="sideNavIcon" style={{ color: '#47a447' }} />,
    // 	eventKey: 'task',
    // 	subMenu: [
    // 		{
    // 			display: ' repairs',
    // 			link: '#/user/task/repairs',
    // 			localStorage: true,
    // 			urlEndWith: 'repairs',
    // 			toDisplay: "taskrepair",
    // 			className: '',
    // 			icon: <FiSettings className="sideNavIcon" />,
    // 			eventKey: 'task_repairs',
    // 			subMenu: [],
    // 		},
    // 		{
    // 			display: ' rejects',
    // 			link: '#/user/task/rejects',
    // 			localStorage: true,
    // 			urlEndWith: 'rejects',
    // 			toDisplay: "taskreject",
    // 			className: '',
    // 			icon: <MdClose className="sideNavIcon" />,
    // 			eventKey: 'task_rejects',
    // 			subMenu: [],
    // 		},
    // 	],
    // },
    // //ACK/NACK
    // {
    // 	display: ' ack_nack',
    // 	link: '/user/ack_nack',
    // 	linkPage: true,
    // 	onclick: this.toggleLocation,
    // 	toDisplay: "ack_nack",
    // 	urlEndWith: 'ack_nack',
    // 	className: '',
    // 	icon: <MdThumbUp className="sideNavIcon" style={{ color: '#ed9c28' }} />,
    // 	eventKey: 'dashboard',
    // 	subMenu: [],
    // },
    // //Reports
    // {
    // 	display: ' reports',
    // 	link: '/user/reports',
    // 	linkPage: true,
    // 	onclick: this.toggleLocation,
    // 	toDisplay: "reports",
    // 	urlEndWith: 'reports',
    // 	className: '',
    // 	icon: <MdTimeline className="sideNavIcon" style={{ color: '#0088cc' }} />,
    // 	eventKey: 'reports',
    // 	subMenu: [],
    // },
    // //Settings
    // {
    // 	display: ' settings',
    // 	link: '/user/settings',
    // 	linkPage: true,
    // 	onclick: this.toggleLocation,
    // 	urlEndWith: 'settings',
    // 	toDisplay: "settings",
    // 	className: '',
    // 	icon: <MdSettings className="sideNavIcon" style={{ color: '#D6B268', backgroundColor: "#000" }} />,
    // 	eventKey: 'settings',
    // 	subMenu: [],
    // },
    // //Maintenances
    // {
    // 	display: ' reference_data',
    // 	link: '/user/maintenance',
    // 	linkPage: false,
    // 	onclick: null,
    // 	urlEndWith: 'maintenance',
    // 	className: '',
    // 	icon: <FaScrewdriver className="sideNavIcon" style={{ color: '#47a447' }} />,
    // 	eventKey: 'maintenance',
    // 	subMenu: [
    // 		{
    // 			display: ' tenant',
    // 			link: '#/user/maintenance/tenant',
    // 			localStorage: true,
    // 			toDisplay: "tenant",
    // 			urlEndWith: 'tenant',
    // 			className: '',
    // 			icon: <PeopleIcon className="sideNavIcon" />,
    // 			eventKey: 'maintenance_tenant',
    // 			subMenu: [],
    // 		},

    // 	],
    // },
    // // Security
    // {
    // 	display: ' security',
    // 	link: '/user/security',
    // 	linkPage: false,
    // 	onclick: null,
    // 	urlEndWith: 'security',
    // 	className: '',
    // 	icon: <MdSecurity className="sideNavIcon" style={{ color: '#ed9c28' }} />,
    // 	eventKey: 'security',
    // 	subMenu: [
    // 		{
    // 			display: ' functionlist',
    // 			link: '#/user/security/functionlist',
    // 			localStorage: true,
    // 			urlEndWith: 'functionlist',
    // 			toDisplay: "functionlist",
    // 			className: '',
    // 			icon: <FaListAlt className="sideNavIcon" />,
    // 			eventKey: 'security_functionlist',
    // 			subMenu: [],
    // 		},

    // 	],
    // },
  ];
}

export default SideBarMenu;
