import LocalStorage from '../config/LocalStorage';

const APIRequest = {
  request: async function(method, url, body) {
    let config = {
      method: method,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      credentials: 'include'
    };
    if (body !== '') {
      config = { ...config, body };
    }
    return fetch(url, config)
      .then(response => response.json())
      .then(this.returnResponse)
      .then((res) => {
        if(res.data.responseCode === 108 && !window.location.href.includes("/admin") && !window.location.href.includes("/login")){
          window.location.reload();
        }
        return res
      })
      .catch(response => {
        return { code: 100, data: { responseCode: 0 } };
      });
  },
  multipartForDataRequest: function(method, url, body) {
    let config = {
      method: method,
      headers: {
        'User-Token': LocalStorage.token,
        'Accept-Language':
          JSON.parse(localStorage.getItem('lng')) !== undefined &&
          JSON.parse(localStorage.getItem('lng')) !== null
            ? JSON.parse(localStorage.getItem('lng')).code
            : ''
      },
      credentials: 'include'
    };
    if (body !== '') {
      config = { ...config, body };
    }
    return fetch(url, config)
      .then(response => {
        response.headers.forEach((val, key) => {
          if (
            key === 'reconnection' &&
            val === 'true' &&
            sessionStorage.getItem('payhub.session') !== undefined &&
            sessionStorage.getItem('payhub.session') !== null &&
            sessionStorage.getItem('payhub.session') !== ''
          ) {
            sessionStorage.setItem(
              'payhub.session',
              response.headers.get('user-token')
            );
            LocalStorage.token = response.headers.get('user-token');
          }
        });
        return response.json();
      })
      .then(this.returnResponse)
      .catch(response => {
        // window.location.href = '/#/login'
        return { returncode: 0, errors: [{ errormsg: 'Timeout Error.' }] };
      });
  },
  returnResponse: async function(response) {
    return Promise.resolve(response);
  }
};
export default APIRequest;
