class ConfigAPIURL {
  //Base URL For Live & Staging
  // static baseUrl = process.env.REACT_APP_API_URL || 'http://localhost:4000'; //http://10.1.1.26:3000
  static hostName = 'https://staging.cricket-education.com'; // Staging
  static baseUrl = '/api'; //staging
  //static baseUrl = 'https://cricket-education.com';
  //static baseUrl = 'http://localhost:4000'; // Local
  static conferenceBaseUrl = 'https://live.cricket-education.com/';

  static policyUserAnalytics = this.baseUrl + '/user/policy/addanalytics';
  static submitPolicyAnalytics = this.baseUrl + '/user/policy/submitanalytics';
  static checkPolicyQuiztime = this.baseUrl + '/admin/policy/check/quiz/time';
  //List of APIs and request method type
  static sessionValidation = this.baseUrl + '/user/islogin'; //get
  static sessionFirebaseValidation = this.baseUrl + '/user/user/islogin'; //get
  static sessionTraineeValidation = this.baseUrl + '/user/user/istraineelogin';
  static login = this.baseUrl + '/user/accountLogin'; //post -email, password
  static loginTrainee = this.baseUrl + '/user/accountLoginTrainee';
  static logout = this.baseUrl + '/user/logout'; //get
  static logoutUsers = this.baseUrl + '/user/logout/all'; //put
  static forgotPassword = this.baseUrl + '/user/forgot/password/'; //put
  static passwordGenaration = this.baseUrl + '/user/password/generate/'; //put
  static emailValidation = this.baseUrl + '/user/email/confirmation?'; //get- email,category,token
  static plans = this.baseUrl + '/plans'; //get
  static userApprove = this.baseUrl + '/admin/user/approve'; //post
  static moveToWaitlist = this.baseUrl + '/admin/user/move-to-waitlist'; //post
  static moveToOther = this.baseUrl + '/admin/user/move-to-other'; //post
  static queryOptions = this.baseUrl + '/admin/query/Options';
  static register = this.baseUrl + '/user/registration'; //post
  static industryType = this.baseUrl + '/user/register/dropdown'; //GET
  static stateData = this.baseUrl + '/user/country/states';
  static registerVerifyEmail = this.baseUrl + '/user/registration/'; //get
  static awsConfig = this.baseUrl + '/config/aws'; //get
  static getNotification = this.baseUrl + '/user/getNotification';
  static getNotificationId = this.baseUrl + '/user/notification/';
  static readAllNotifications = this.baseUrl + '/user/notification/read/all';
  static listOfDropdowns = this.baseUrl + '/user/custom/dropdown'; //post

  //--------------------------------User API's--------------------------------//
  static userModulesList = this.baseUrl + '/user/modules'; //get //using now
  static createModules = this.baseUrl + '/user/modules/create/'; //post -module title, description, module image
  static updateModule = this.baseUrl + '/user/modules/update/'; //post -module title, description, module image
  static updateCourses = this.baseUrl + '/admin/user/update-courses';
  static resendSignupMail = this.baseUrl + '/admin/user/resend-invite/';
  static retakeCourse = this.baseUrl + '/admin/user/retake-course';
  static verifyTitle = this.baseUrl + '/user/modules/create/'; //POST title
  static moduletitles = this.baseUrl + '/admin/module/titles'; // get
  static checkPermissionModules =
    this.baseUrl + '/user/modules/create/permission'; //get

  static listenModules = this.baseUrl + '/user/module/'; //get
  static getPolicyInfo = this.baseUrl + '/user/policy/getInfoById?policyId='; //get
  static updateCourseStatus =
    this.baseUrl + '/user/update-course-chapter-status';
  static updateCourseSuspendData =
    this.baseUrl + '/user/update-course-chapter-suspended-data';

  static policyTitle = this.baseUrl + '/user/policy/parent/'; //post module id
  static createPolicy = this.baseUrl + '/user/policy/create'; //post
  static verifyPolicyTitle = this.baseUrl + '/user/policy/create/title/'; //get
  static policyCreatePermission =
    this.baseUrl + '/user/policy/create/permission'; //get
  static updatePolicyPermission =
    this.baseUrl + '/user/policy/update/permission/'; //get
  static validatePolicyNo = this.baseUrl + '/user/policy/check?policyNo=';
  static getBatchUserList = this.baseUrl + '/user/get-batch-user/';
  static createassignment = this.baseUrl + '/admin/assignment';
  static getassignment = this.baseUrl + '/user/assignment';
  static sendassignment = this.baseUrl + '/admin/assignment/add-user/';
  static getassignmentdetail = this.baseUrl + '/user/get-assignmet-user-detail';
  static gettraineeassignment = this.baseUrl + '/user/trainee-assignment';
  static geteachassignmnet = this.baseUrl + '/user/trainee-assignment/';
  static submitanswer = this.baseUrl + '/user/trainee-assignment/';
  static submitscore = this.baseUrl + '/user/aprrove-or-reject-assignment/';
  static resubmitassignment = this.baseUrl + '/user/resubmit-assignment/';
  static getincategory = this.baseUrl + '/user/get-in-course-categories';
  static getpostcategory = this.baseUrl + '/user/get-post-course-categories';
  static gettraineestatus = this.baseUrl + '/user/trainee-assignment-count';
  static changepassword = this.baseUrl + '/admin/user/change-password';
  static forgotpassword2 = this.baseUrl + '/user/forgot/password';
  static resetpassword2 = this.baseUrl + '/user/reset/password';
  // ---- user role API's ---- //

  static userRoleList = this.baseUrl + '/user/roles'; //POST
  static userRoleCreate = this.baseUrl + '/user/role/create'; //POST
  static userRoleDelete = this.baseUrl + '/user/role/delete'; //PUT
  static userRolesDetails = this.baseUrl + '/user/role/details'; //POST
  static userPermissionUpdate = this.baseUrl + '/user/role/permission/update'; //PUT
  static userPermissionEmployee = this.baseUrl + '/user/role/permission/grant'; //PUT
  static userEmployeeUnassigned =
    this.baseUrl + '/user/role/permission/unassigned';
  static userPolicyDetails = this.baseUrl + '/user/role/policy/unassigned'; //PUT
  static userPolicyUpdate = this.baseUrl + '/user/role/policy/update'; //PUT

  //----User Policy API's----//
  static savePolicyDraft = this.baseUrl + '/user/policy/save'; //put
  static publishEdit = this.baseUrl + '/user/policy/update'; //put
  static publishDraft = this.baseUrl + '/user/policy/save/publish'; //post
  static listOfPolicy = this.baseUrl + '/user/policy/list'; //post

  static submitChapterQuizResults = this.baseUrl + '/user/result/submit';
  static getQuizResults = this.baseUrl + '/user/result/quizresults';
  static resultPostUrl = this.baseUrl + '/user/result/submitScormQuiz';

  //----User Dashboard API's----//
  static userDashboard = this.baseUrl + '/user/dashboard'; //get

  //----User Purchase API's----//
  static userListOfPlan = this.baseUrl + '/user/plans'; //get
  static userPlanSubscribedValidation = this.baseUrl + '/user/plan/validation/'; //get-planId
  static userPlanPurchase = this.baseUrl + '/user/purchase/plan'; //post
  static userPolicyList = this.baseUrl + '/user/purchase/policy/list'; //post- moduleId, status
  static userPolicyPurchase = this.baseUrl + '/user/purchase/policy'; //put

  //----User Profile API's----//
  static resetPassword = this.baseUrl + '/user/reset/password'; //put -password
  static userProfileInfo = this.baseUrl + '/user/profile'; //get
  static userProfileUpdate = this.baseUrl + '/user/profile/update';

  //----User Policy Drafts API's----//
  static userPolicyDrafts = this.baseUrl + '/user/policy/save/list'; //post
  static userPolicyDraftsDelete = this.baseUrl + '/user/policy/delete'; //delete
  static userPolicyCalculations =
    this.baseUrl + '/user/purchase/policy/payment '; //delete

  //----User Employee API's----//
  static userEmployee = this.baseUrl + '/user/employees'; //post
  static userCreateEmployeePermission =
    this.baseUrl + '/user/employee/create/permission '; //get
  static userEmployeeCreate = this.baseUrl + '/user/employee/create'; //post
  static userEmployeeUpdate = this.baseUrl + '/user/employee/update'; //put
  static userEmployeeDelete = this.baseUrl + '/user/employee/delete'; //delete
  static userEmployeeResetPasswordAttempt =
    this.baseUrl + '/user/employee/reset/passwordAttempt'; //delete

  //----User Custom Plan API's----//
  static userCustomPlanCalculation = this.baseUrl + '/user/plan/custom/payment'; //post //calculation
  static userCustomPlanSubscribe = this.baseUrl + '/user/plan/custom/subscribe'; //post
  static userCustomPlanRenew = this.baseUrl + '/user/plan/details'; //post
  static userGetService = this.baseUrl + '/admin/service/getService'; //post
  //--------------------------------Admin API's--------------------------------//
  //----Admin User API's----//
  static generateCertificate =
    this.baseUrl + '/admin/user/generate-certificate';
  static getCertificate = this.baseUrl + '/admin/user/get-certificates';
  static getadminincategory = this.baseUrl + '/admin/get-in-course-categories';
  static getadminpostcategory =
    this.baseUrl + '/admin/get-post-course-categories';
  static adminsubmitscore =
    this.baseUrl + '/admin/aprrove-or-reject-assignment/';
  static adminresubmitassignment = this.baseUrl + '/admin/resubmit-assignment/';
  static adminUserLogin = this.baseUrl + '/admin/user/change-status/';
  static getadminassignment = this.baseUrl + '/admin/assignment';
  static adminUserList = this.baseUrl + '/admin/users'; //post
  static sendMeeting = this.baseUrl + '/admin/user/send-meeting-invite';
  static importCertificate = this.baseUrl + '/admin/user/import-certificates';
  static createAdmin = this.baseUrl + '/admin/user/create';
  static adminUserActivate = this.baseUrl + '/admin/user/activate'; //post
  static adminUserDetails = this.baseUrl + '/admin/user/details'; //get
  static adminUserDelete = this.baseUrl + '/admin/user/delete'; //delete
  static adminUserUpdate = this.baseUrl + '/admin/user/update'; //put
  static adminMpdateMultipleUser = this.baseUrl + '/admin/user/multiple/update'; //put
  static userPostInfo = this.baseUrl + '/admin/getpostinfo';
  static adminUserResetPasswordAttempt =
    this.baseUrl + '/admin/user/reset/passwordAttempt'; //put
  static adminListOfPlan = this.baseUrl + '/admin/plan/list?userId='; //get
  static adminUpgradePlan = this.baseUrl + '/admin/user/plan/upgrade'; //put
  static adminSubscribedServices =
    this.baseUrl + '/admin/service/getsubscribed'; //put
  static adminSelectedPolicy =
    this.baseUrl + '/admin/user/policy/details?userId='; //get
  static adminUpgradePolicy = this.baseUrl + '/admin/user/policy/add'; //put
  static adminEmployeeDetails = this.baseUrl + '/admin/user/employee/details'; //post
  static adminUserPolicyDetails = this.baseUrl + '/admin/user/policy/details'; //post
  static adminUserPlanDetails = this.baseUrl + '/admin/user/plan/details'; //post
  static getAdminPlanList = this.baseUrl + '/admin/plan/list/dropdown'; //get
  //----Admin Modules API's----//
  static adminModulesList = this.baseUrl + '/admin/modules'; //post
  static adminModulesCreate = this.baseUrl + '/admin/module/create'; //post
  static adminModulesUpdate = this.baseUrl + '/admin/module/update'; //put
  static adminModulesDelete = this.baseUrl + '/admin/module/delete'; //delete
  static adminCheckModulesTitle = this.baseUrl + '/admin/modules/create/'; //post
  static adminPlansList = this.baseUrl + '/admin/plan'; //post
  static adminAllModuleList = this.baseUrl + '/admin/module/list'; //get
  static adminAllSelectedPolicyList =
    this.baseUrl + '/admin/policy/ladderList  '; //post
  static facultydrop = this.baseUrl + '/admin/get-all-faculties';
  static adminOptionList = this.baseUrl + '/admin/options';
  static adminOptionsCreate = this.baseUrl + '/admin/options/create';
  static adminOptionsUpdate = this.baseUrl + '/admin/options/update';
  static optionsGetInfoById = this.baseUrl + '/admin/options/getinfobyid';
  static incoursecategory = this.baseUrl + '/admin/in-course-category';
  static postcoursecategory = this.baseUrl + '/admin/post-course-category';

  static modulesGetInfoById = this.baseUrl + '/admin/module/getinfobyid'; //post
  static publishOrUnpublishModule =
    this.baseUrl + '/admin/module/publishorunpublishmodule'; //post
  static getadminassignmentdetail =
    this.baseUrl + '/admin/get-assignmet-user-detail/';
  // ---- Admin Services API's --- //
  static adminServiceList = this.baseUrl + '/admin/service'; //post
  static adminServiceCreate = this.baseUrl + '/admin/service/create';
  static adminServiceUpdate = this.baseUrl + '/admin/service/update'; //put
  static adminServiceDelete = this.baseUrl + '/admin/service/delete'; //delete
  //----Admin Plan API's----//
  static adminPlanCreate = this.baseUrl + '/admin/Plan/create'; //post
  static adminPlanUpdate = this.baseUrl + '/admin/Plan/update'; //put
  static adminPlanDelete = this.baseUrl + '/admin/Plan/delete'; //delete
  //----Admin Policy API's----//
  static adminPolicy = this.baseUrl + '/admin/policy'; //post
  static adminPolicyCreate = this.baseUrl + '/admin/policy/create'; //post
  static adminPolicyUpdate = this.baseUrl + '/admin/policy/update'; //put
  static adminPolicyDelete = this.baseUrl + '/admin/policy/delete'; //delete
  static adminPolicyInfo = this.baseUrl + '/admin/policy/info?policyId='; //get -{policyId=}
  static adminPolicyDetails = this.baseUrl + '/admin/policy/details'; //get -{?policyId}
  static adminPolicyTitle = this.baseUrl + '/admin/policy/parent/';

  //Upload File
  static adminUploadFile = this.baseUrl + '/admin/upload/file';

  // Admin Quiz API's----//'

  static adminQuiz = this.baseUrl + '/admin/quiz'; //post
  static adminQuizCreate = this.baseUrl + '/admin/quiz/create'; //post
  static adminQuizUpdate = this.baseUrl + '/admin/quiz/update'; //put
  static adminQuizDelete = this.baseUrl + '/admin/quiz/delete'; //delete
  static adminQuizGetEditable = this.baseUrl + '/admin/quiz/geteditable';
  static adminQuizOptions = this.baseUrl + '/admin/quiz/value';

  static userQuizVerify = this.baseUrl + '/user/quiz/verify';

  // User Post related API's----//'
  static updatePost = this.baseUrl + '/user/post/update';
  static userCreatePost = this.baseUrl + '/user/post/create';
  static userGetAllPosts = this.baseUrl + '/user/post/all';
  static addComment = this.baseUrl + '/user/post/addComment';
  static deletePost = this.baseUrl + '/user/post/delete';
  static getPostInfo = this.baseUrl + '/user/post/getpostinfo';
  static deleteComment = this.baseUrl + '/user/post/deleteComment';
  static updateComment = this.baseUrl + '/user/post/updateComment';
  static updateLike = this.baseUrl + '/user/post/update/like';

  static getAdminQuizResults = this.baseUrl + '/admin/user/quiz/results/all';

  static adminResultDelete = this.baseUrl + '/admin/result/delete'; //delete
  static viewQuizResults = this.baseUrl + '/admin/user/quiz/results/view';
  static downloadQuizResults =
    this.baseUrl + '/admin/user/quiz/results/download';

  static getTraineeUsersList = this.baseUrl + '/admin/user/trainee';
  static getPolicyTitle = this.baseUrl + '/admin/policyList';

  static addCrickRepProof = this.baseUrl + '/user/addrespresentation';
  static deleteCrickRepProof = this.baseUrl + '/user/deletecricketrepproof';

  static uploadQuiz = this.baseUrl + '/admin/quiz/upload/questions';

  static uploadQuizImages = this.baseUrl + '/admin/quiz/upload/image';
  static uploadHistory = this.baseUrl + '/admin/list/all/upload/file';
  static listUniqueCategories = this.baseUrl + '/admin/list/unique/category';

  // result download api
  static adminUserResultDownload = this.baseUrl + '/admin/result/user/download'; // post

  static newAccountSignup = this.baseUrl + '/user/newAccountSignup'; //post
  static updateModuleToUsers = this.baseUrl + '/admin/user/modulesupdat';

  // Feedback Api
  static getAllFeedbacks = this.baseUrl + '/admin/feedback/list'; //post
  static getAllFeedbackUsers = this.baseUrl + '/admin/feedback/listUsers'; //post
  static createFeedbackUsers = this.baseUrl + '/admin/feedback/createUser'; //post
  static listRoleNames = this.baseUrl + '/admin/feedback/listRoleNames'; //post
  static shareFeedbackForm = this.baseUrl + '/admin/feedback/sendFeedbackMail'; //post
  static submitFeedbackForm = this.baseUrl + '/user/feedback/create'; //post
  static createUserRole = this.baseUrl + '/admin/feedback/createRole'; //post
  static updateUserRole = this.baseUrl + '/admin/feedback/updateRole'; //post
  static getAllRoles = this.baseUrl + '/admin/feedback/listRoles'; //post
  static getRoleAndNameById =
    this.baseUrl + '/user/feedback/getRoleAndNameById'; //post
  //static getNameById = this.baseUrl + '/user/feedback/getNameById'; //post
}

export default ConfigAPIURL;
