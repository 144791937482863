import React, {
  useRef,
  useState
} from 'react';
import { Link } from 'react-router-dom';
import { Link as RouterLink } from 'react-router-dom';
import { useHistory } from 'react-router-dom';

import { useSnackbar } from 'notistack';
import  LocalStorage  from '../../../admin/config/LocalStorage';

import {
  Avatar,
  Box,
  ButtonBase,
  Hidden,
  IconButton,
  Menu,
  MenuItem,
  SvgIcon,
  Tooltip,
  Typography,
  makeStyles
} from '@material-ui/core';
import useAuth from 'src/hooks/useAuth';
import {
  LogOut as LogOutIcon,
} from 'react-feather';

const useStyles = makeStyles((theme) => ({
  avatar: {
    height: 32,
    width: 32,
    marginRight: theme.spacing(1)
  },
  popover: {
    width: 200
  }
}));

const Account = () => {
  const classes = useStyles();
  const history = useHistory();
  const ref = useRef(null);
  const { user, logout } = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const [isOpen, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleLogout = async () => {
    try {
      handleClose();
      await logout();
      history.push('/');
    } catch (err) {
      console.error(err);
      enqueueSnackbar('Unable to logout', {
        variant: 'error'
      });
    }
  };

  return (
    <>
    <nav class="navbar navbar-expand-lg navbar-dark bg-dark">
  {/* <a class="navbar-brand" href="#">Navbar</a> */}
  <button class="navbar-toggler trainee_nav_button" type="button" data-toggle="collapse" data-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>
  <div class="collapse navbar-collapse" id="navbarNavDropdown">
    <ul class="navbar-nav trainee_nav_ul">
    {(localStorage.getItem('Trainee')) == 'Trainee'?<li class="nav-item">
    <RouterLink class="nav-link" to="/app/trainee-user-manual">
         User Manual
          </RouterLink>
      </li>: ''}
      {(localStorage.getItem('Trainee')) == 'Trainee'?<li class="nav-item">
      <RouterLink class="nav-link" to="/app/contact">
         Contact
          </RouterLink>
      </li>: ''}
      <li class="nav-item dropdown user-dd">
      <div class="dropdown-toggle align-center" type="button" data-toggle="dropdown">
    <Box p={1} className='align-center'>
      
        <Box>
          <Link className='username text-right'
            component={RouterLink}
            to="/app/account"
            variant="h5"
            color="textPrimary"
            underline="none"
          >
            {LocalStorage.userName}
          </Link>
          {(localStorage.getItem('Trainee')) == 'Trainee'? <Typography className='level text-right'>
           Trainee
          </Typography>: ''}

          {(localStorage.getItem('Faculty')) == 'Faculty'? <Typography className='level text-right'>
           Faculty 
          </Typography>: ''}
          {/* <Typography className='level text-right'>
            Level {LocalStorage.tier}
          </Typography> */}
        </Box>
        <Box className='ms-3'>
          {/* <RouterLink to="/app/account">
            <Avatar alt="User" src={LocalStorage.avatar} className="user_avatar" />
          </RouterLink> */}
           <Avatar alt="User" src={LocalStorage.avatar} className="user_avatar" />
        </Box>
      </Box>
     </div>
    <ul class="dropdown-menu">
    {(localStorage.getItem('Trainee')) == 'Trainee'?<li><Link to="/app/account" className='dropdown-item'> My Profile </Link></li>: ''}
    <li><Link to="/app/change-password" className='dropdown-item'> Change Password </Link></li>
      <li><a href="#" className='dropdown-item'  ref={ref}
          onClick={handleLogout}>
        Logout
      </a></li>
    </ul>
      </li>
    </ul>
  </div>
</nav>
 
     

     
    </>
    // <>
    //   <Box
    //     display="flex"
    //     alignItems="center"
    //     component={ButtonBase}
    //     onClick={handleOpen}
    //     ref={ref}
    //   >
    //     <Avatar
    //       alt="User"
    //       className={classes.avatar}
    //       src={user.avatar}
    //     />
    //     <Hidden smDown>
    //       <Typography
    //         variant="h6"
    //         color="inherit"
    //       >
    //         {user.name}
    //       </Typography>
    //     </Hidden>
    //   </Box>
    //   <Menu
    //     onClose={handleClose}
    //     anchorOrigin={{
    //       vertical: 'bottom',
    //       horizontal: 'center'
    //     }}
    //     keepMounted
    //     PaperProps={{ className: classes.popover }}
    //     getContentAnchorEl={null}
    //     anchorEl={ref.current}
    //     open={isOpen}
    //   >
    //     <MenuItem
    //       component={RouterLink}
    //       to="/app/social/profile"
    //     >
    //       Profile
    //     </MenuItem>
    //     <MenuItem
    //       component={RouterLink}
    //       to="/app/account"
    //     >
    //       Account
    //     </MenuItem>
    //     <MenuItem onClick={handleLogout}>
    //       Logout
    //     </MenuItem>
    //   </Menu>
    // </>
  );
}

export default Account;
