import React, { useState } from 'react';
import {
  MDBTabs,
  MDBTabsItem,
  MDBTabsLink,
  MDBTabsContent,
  MDBTabsPane
} from 'mdb-react-ui-kit';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import search from '../../assets/images/search.png'
import media from '../../assets/images/Media.png'
import doc from '../../assets/images/Doc.png'
import Avatar from '../../assets/images/Avatar.jpg'
import download from '../../assets/images/downloadicon.png'
import VisibilityIcon from '@mui/icons-material/Visibility';
import MenuItem from '@material-ui/core/MenuItem';
import AWS from 'aws-sdk';
import { useLocation, useParams, useHistory, Link } from 'react-router-dom';
import LocalStorage from 'src/admin/config/LocalStorage';
import APIRequest from '../utils/APIRequest';
import ConfigAPIURL from '../config/ConfigAPIURL';
import { useEffect, useRef } from 'react';
import {
  TextField,
} from '@material-ui/core';
import isEmpty from '../utils/isEmpty';
import moment from 'moment';
import FacultySendPopup from '../faculty-assignment/FacultySendPopup';
import parse from 'html-react-parser';
import { CSVLink } from "react-csv";
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import FilterListIcon from '@material-ui/icons/FilterList';

export default function AssignmentTabs() {
  const history = useHistory()
  const [basicActive, setBasicActive] = useState('tab2');
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const assigmentid = params.get('assigmentid');
  var [assignments, setAssignments] = React.useState();
  var [aws, setAws] = useState(LocalStorage.aws)

  var [selectedCourse, setSelectedCourse] = React.useState('');
  var [courseid, setCourseId] = React.useState('');
  var [courseOptions, setCourseOptions] = useState([])
  var [batch, setBatch] = useState([])
  var [selectedBatch, setSelectedBatch] = React.useState('');
  var [options, setOptions] = useState([])
  var [assignmentusers, setAssignUsers] = React.useState([]);
  var [searchKey, setSearchKey] = useState('');
  var [exportdata, setExportData] = useState([])


  useEffect(() => {
    // getCourse()
    getAssignment()
    getBatch()
    
  }, [])

  const handleBasicClick = (value) => {
    if (value === basicActive) {
      return;
    }
    setBasicActive(value);
  };
  const getFileName = fileName =>{
    let tempFileName =  fileName.split('_');
    return tempFileName[tempFileName.length-1];
  }

  const handleCourseChange = (e) => {
    const clickedOption = courseOptions.find(item => item.title === e.target.value);
     courseid = `${clickedOption._id}`;
    setCourseId(courseid)
    selectedCourse =  e.target.value;
    setSelectedCourse(e.target.value);
    getAssignmentUsers();
  }

  const getCourse= () =>{
    APIRequest.request('GET', ConfigAPIURL.adminAllModuleList, '').then(
      response => {
        if (response.code === 100 && response.data.responseCode === 109) {
          courseOptions = response.data.module
          setCourseOptions(courseOptions);
          selectedCourse = courseOptions[0]['title']
          setSelectedCourse(courseOptions[0]['title'])
          getAssignmentUsers()
        }
      }
    );
  }

  const handleBatchChange = (e) => {
    selectedBatch =  e.target.value;
    setSelectedBatch(e.target.value);
    getAssignmentUsers();
  }

  const getBatch = () => {
    APIRequest.request('POST', ConfigAPIURL.listOfDropdowns,  JSON.stringify({ name:"Batch Name"})).then(
      response => {
        if (response.code === 100 && response.data.responseCode === 109) {
          options = response.data.result.options
          setOptions(options);
          batch = options.filter(e => e.active && !isEmpty(e.value)).map(e => e.value)
          setBatch(batch);
          getCourse()
        }
      }
    );
  }
  const handleDownload = (url) => {
    AWS.config.update({
      credentials: new AWS.CognitoIdentityCredentials({
        IdentityPoolId: aws.CognitoIdentityCredentials
          .IdentityPoolId
      }),
      region: aws.CognitoIdentityCredentials.region
    });
    let extractUrl = url.split('/');

    var s3 = new AWS.S3({ signatureVersion: 'v4' });
    let originalUrl = extractUrl[5];
    
    let bucket = aws.bucket.split('/');
    const params = {
      Bucket: bucket[0] + '/quizImage' ,
      Key: originalUrl,
    };
    function downloadBlob(blob, name = `${originalUrl}`) {
      // Convert your blob into a Blob URL (a special url that points to an object in the browser's memory)
      const blobUrl = URL.createObjectURL(blob);
      // Create a link element
      const link = document.createElement('a');
      // Set link's href to point to the Blob URL
      link.href = blobUrl;
      link.download = name;
      // Append link to the body
      document.body.appendChild(link);
      // Dispatch click event on the link
      // This is necessary as link.click() does not work on the latest firefox
      link.dispatchEvent(
        new MouseEvent('click', {
          bubbles: true,
          cancelable: true,
          view: window,
        })
      );

      // Remove link from body
      document.body.removeChild(link);
    }
    let template =extractUrl[5];
    s3.getObject(params, (err, data) => {
      if (err) {
        console.log('error in fetching',err, err.stack);
      } else {
        let blob=new Blob([data.Body], {type: data.ContentType});
        let link=document.createElement('a');
        link.href=window.URL.createObjectURL(blob);
        link.download=template;
        link.click();
      }
    });
  };
  const getAssignment = () => {
    APIRequest.request('GET', ConfigAPIURL.getadminassignment +'/'+ assigmentid, '').then(
      response => {
        if (response.code === 100 && response.data.responseCode === 109) {
          if(response.data.assignment == null){
              window.alert("assignment null")
              history.push('/admin/assignment');
              }else{
              assignments = response.data.assignment
              setAssignments(assignments);
              }
        }
      }
    );
  }
  const handleSearchChange = (e) =>{
    searchKey = e.target.value;
    setSearchKey(searchKey)
    getAssignmentUsers()
  }
  const getAssignmentUsers = ()=>{
     APIRequest.request('GET', ConfigAPIURL.baseUrl+'/admin/get-assignmet-users?batchName='+ selectedBatch + '&course='+courseid +'&id='+ assigmentid+'&searchKey='+searchKey, '').then(
       response => {
         if (response.code === 100 && response.data.responseCode === 109) {
           if(response.data.getAssignment == null){
             window.alert("Empty table in manage")
             //history.push('/faculty/assignment');
             assignmentusers = []
             setAssignUsers(assignmentusers)
           }else{
                 assignmentusers = response.data.getAssignment
                 setAssignUsers(assignmentusers)
           }
           let newexportdata = assignmentusers.map((item,user,index)=>{
            let newItem ={};
            newItem['Name'] = item.user.name;
            newItem['Email'] = item.user.email;
            newItem['Assigned Date'] = moment(item.assignDate * 1000).format("DD-MM-YYYY");
            newItem['Course Name'] = item.course ? item.course.title : '';
            newItem['Batch Name'] = item.batchName;
            newItem['Status'] = item.status;
            newItem['Score'] = item.grandTotal;
            newItem['Evaluated By'] = item.updatedBy ? item.updatedBy.name : '';
            newItem['Last Updated'] = (moment(item.updatedAt * 1000).format("DD-MM-YYYY"));
            return newItem;

          });
          setExportData(newexportdata)
         }
       }
     );
   }

  return (
    <div className='assignment-tabs'>
    {/* <div className="row">
    <div className="col-sm-6"> */}
     <MDBTabs className='mb-4'>
          <MDBTabsItem>
          <MDBTabsLink className='tablink' onClick={() => handleBasicClick('tab1')} active={basicActive === 'tab1'}>
            <b className='fw-600'>Assignment</b>
          </MDBTabsLink>
        </MDBTabsItem>
      <MDBTabsItem>
          <MDBTabsLink className='tablink' onClick={() => handleBasicClick('tab2')} active={basicActive === 'tab2'}>
            <b className='fw-600'> Manage</b>
          </MDBTabsLink>
        </MDBTabsItem>
      </MDBTabs>
     {/* </div>
     <div className="col-sm-6 align-right header-btn">
     </div>
     </div>  */}

      <MDBTabsContent className='mt-3 bg-white p-4'>
        <MDBTabsPane show={basicActive === 'tab1'} className="question-info">
          <h5 className='fw-700 text-black'>{assignments && assignments.title}</h5>
          {assignments &&<div className='rt-content' dangerouslySetInnerHTML={{ __html:parse( assignments.description)}}></div>}
          <div>
          {assignments && assignments.files.map((item)=>
            <div className="lesson-download mt-3">
            <img src={doc} alt="" className='lesson-img'/>
            <div>
            <span className='fw-700 text-nowrap'> {getFileName(item.fileName)}</span><span className='grey text-capitalize text-nowrap'>Type : {item.fileType}</span>
            </div> 
            <button className='transparent' onClick={()=>{handleDownload(item.fileName)}}><img src={download} alt="" /></button>
            </div>)}
          </div>
        </MDBTabsPane>
        <MDBTabsPane show={basicActive === 'tab2'} className="pt-1">
          <div>
          </div>
          <div >
          <div className="header-btn ">
        <div className="row mb-4">
                <div className="col-md-3">
                  <TextField
                    className='drop_down_common mt-2'
                    label="Course Name"
                    select
                    variant="outlined"
                    value={selectedCourse}
                    onChange={handleCourseChange}
                  >
                    {courseOptions.map(({ title }, index) => <MenuItem value={title} >{title}</MenuItem>)}

                  </TextField>
                </div>

                <div className="col-md-3">
                  <TextField
                    className='drop_down_common mt-2'
                    label="Batch Name"
                    select
                    variant="outlined"
                    value={selectedBatch}
                    onChange={handleBatchChange}
                  >
                    {batch.map((index) => <MenuItem value={index} >{index}</MenuItem>)}

                  </TextField>
                </div>

    <div className="col-md-6 d-flex align-items-center justify-content-end">
    <FacultySendPopup userid = {assigmentid} />
    </div>
     </div>
          </div>
            <div className="batches_btn w-full">
              <div className="row w-100">
              <div className="col-md-3">
              <div className='search'>
              <img src={search} alt="" />
                <input type="search" name="" id="" className='form-control' placeholder='Search here' onChange={handleSearchChange}/>
                </div>
              </div>
              <div className="col-md-9 text-end">
                    <div className='d-flex align-items-center justify-content-end'>
                  <div className='export_users align-center me-2'>     
                       
                  {exportdata.length > 0 &&
                    <CSVLink className='export_users align-center me-2' data={exportdata} filename={"assignment.xls"}>     
                    <Tooltip title="Export">
                      <IconButton>
                        <CloudDownloadIcon />
                      </IconButton>
                    </Tooltip>
                      </CSVLink>
                    }
              
                      </div>



                    </div>
                  </div>
              </div>
            </div>
            <TableContainer component={Paper} className="Assingment-table">
              <Table className='MuiTable-root MuiTable-stickyHeader w-100' sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell align="">Assigned Date</TableCell>
                    <TableCell align="">Course</TableCell>
                    <TableCell align="">Batch</TableCell>
                    <TableCell align="">Status</TableCell>
                    <TableCell >Score</TableCell>
                    <TableCell >Evaluated By</TableCell>
                    <TableCell >Last Updated</TableCell>
                    <TableCell align="right">Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                {assignmentusers.length >0 && assignmentusers.map((user ,index) =>

                  <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                    <TableCell align="left">
                      <div className='userrow'><img src={Avatar} alt="" /><div>{user.user.name}<p>{user.user.email}</p></div></div>
                    </TableCell>
                    <TableCell className='inner-cell' align="">{moment(user.assignDate * 1000).format("DD-MM-YYYY")}</TableCell>
                    <TableCell className='inner-cell' align="">{user.course.title}</TableCell>
                    <TableCell className='inner-cell' align="">{user.batchName}</TableCell>
                    {(user.status == 'pending')? <TableCell className='inner-cell' align="">
                      <span className='badge-brown'>Pending</span>
                      </TableCell> : ''}
                      {(user.status == 'resubmit')? <TableCell className='inner-cell' align="">
                      <span className='badge-brown'>Resubmit</span>
                      </TableCell> : ''}
                      {(user.status == 'completed')? <TableCell className='inner-cell' align="">
                      <span className='badge-green'>Completed</span>
                      </TableCell> : ''}
                      {(user.status == 'approve')? <TableCell className='inner-cell' align="">
                      <span className='badge-blue'>Approved</span>
                      </TableCell> : ''}
                      {(user.status == 'reject')? <TableCell className='inner-cell' align="">
                      <span className='badge-red'>Reject</span>
                      </TableCell> : ''}
                    <TableCell className='inner-cell'>{user.grandTotal}</TableCell>
                    <TableCell className='inner-cell'>{user.updatedBy && user.updatedBy.name}</TableCell>
                    <TableCell className='inner-cell'>{moment(user.updatedAt * 1000).format("DD-MM-YYYY h:mm a")}</TableCell>
                    <TableCell className='inner-cell' align="right">
                    <Link to={`/admin/view-assignment?userid=${user._id}`} className='trasparent'><VisibilityIcon /></Link>
                    </TableCell>
                  </TableRow> )}
                </TableBody>
              </Table>
            </TableContainer>
          </div>

        </MDBTabsPane>
      </MDBTabsContent>

    </div>

  )
}
