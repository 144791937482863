import React, { Component } from 'react';
import { Route, Redirect, withRouter } from 'react-router-dom';
import ConfigAPIURL from '../config/ConfigAPIURL';
import APIRequest from '../utils/APIRequest';
import LocalStorage from '../config/LocalStorage';
class PrivateRoute extends Component {
  constructor(props) {
    super(props);
    this.state = {
      haveAcces: false,
      loaded: false
    };
  }
  UNSAFE_componentWillReceiveProps = () => {
    this.checkAcces();
  };
  UNSAFE_componentWillMount = () => {
    this.checkAcces();
  };

  checkAcces = () => {
    const { history } = this.props;

    APIRequest.request('GET', ConfigAPIURL.sessionValidation, '')
      .then(response => {
        if (
          response.code === 100 &&
          response.data !== undefined &&
          response.data.responseCode === 109
        ) {
          if (response.data.user !== undefined && response.data.user !== null) {
            LocalStorage.userId = response.data.user._id;
            LocalStorage.userName = response.data.user.name;
            LocalStorage.userEmail = response.data.user.email;
            LocalStorage.userMobile = response.data.user.mobileName;
            LocalStorage.userType = response.data.userType;
            LocalStorage.userProfileImage = response.data.user.profileImage;
            LocalStorage.notificationCount = response.data.notificationCount;
          }
          if (
            response.data.user.userType === 'admin' &&
            history.location.pathname.startsWith('/admin')
          ) {
            this.setState({
              haveAcces: true,
              loaded: true
            });
          } else if (
            (response.data.userType === 'user' ||
              response.data.userType === 'employee') &&
            history.location.pathname.startsWith('/user')
          ) {
            if (response.data.userType === 'employee') {
              let isEmployee = response.data.employee.permission.permission;
              isEmployee.map(data => {
                if (data.label === 'Policy') {
                  localStorage.setItem(
                    'isEmployeePermissionsPolicy',
                    JSON.stringify(data)
                  );
                }
              });
            }
            LocalStorage.planName = response.data.user.plan.title;
            LocalStorage.planCreatedDate =
              response.data.user.subscription.createdAt;
            LocalStorage.planEndedDate = response.data.user.subscription.endAt;
            this.setState({
              haveAcces: true,
              loaded: true
            });
          } else {
            history.push('/admin/login');
          }
        } else if (
          response.code === 100 &&
          response.data !== undefined &&
          response.data.responseCode === 110 &&
          !history.location.pathname.includes('/user/purchase')
        ) {
          if (response.data.user !== undefined && response.data.user !== null) {
            LocalStorage.userName = response.data.user.name;
            LocalStorage.userEmail = response.data.user.email;
            LocalStorage.userMobile = response.data.user.mobileName;
            LocalStorage.userType = response.data.userType;
            LocalStorage.userProfileImage = response.data.user.profileImage;
          }
          history.push('/user/purchase');
        } else if (
          response.code === 100 &&
          response.data !== undefined &&
          response.data.responseCode === 108
        ) {
          LocalStorage.userName = '';
          LocalStorage.userEmail = '';
          LocalStorage.userMobile = '';
          LocalStorage.userType = '';
          LocalStorage.userProfileImage = '';
          LocalStorage.notificationCount = 0;
          history.push('/admin/login');
        } else {
          this.setState({
            haveAcces: true,
            loaded: true
          });
        }
      })
      .catch(() => {
        history.push('/');
      });
  };

  render() {
    const { component: Component, ...rest } = this.props;
    const { loaded, haveAcces } = this.state;
    if (!loaded) return null;
    return (
      <Route
        {...rest}
        render={props => {
          return haveAcces === true ? (
            <Component {...props} />
          ) : (
            <Redirect to="/admin/login" />
          );
        }}
      />
    );
  }
}

export default withRouter(PrivateRoute);
