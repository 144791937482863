class LocalStorage {
  static theme = 'dark';

  static userId = '';
  static userName = '';
  static userMobile = '';
  static userEmail = '';
  static userProfileImage = '';
  static userType = '';
  static razorPay = '';
  static notificationCount = 0;

  static planName = '';
  static planCreatedDate = '';
  static planEndedDate = '';
  static lng = {
    displayName: 'English',
    code: 'en_IN'
  };

  // static aws = {
  //   CognitoIdentityCredentials: {
  //     IdentityPoolId: 'ap-south-1:649af36f-e5a4-4138-8b30-1b2c67a5b4e6',
  //     region: 'ap-south-1'
  //   },
  //   accessKeyId: 'AKIAVZSKAPZUM3BMM6FZ',
  //   secretAccessKey: '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',
  //   smtpAccessKeyId: 'AKIAVZSKAPZUMDOIUWQR',
  //   smtpSecretAccessKey: 'BITjpfyduWf5AKisdIXJTsKVkvZ++f9lRLM5IfBwwqQD',
  //   region: 'us-east-1',
  //   bucket: 'pimarq/crictrain',
  //   link: 'https://s3.ap-south-1.amazonaws.com/'
  // };
  static aws = {
    CognitoIdentityCredentials: {
      IdentityPoolId: 'ap-south-1:ddd3a4f0-5659-4069-bd1a-43b1fa82e289',
      // IdentityPoolId: 'ap-south-1:649af36f-e5a4-4138-8b30-1b2c67a5b4e6',
      region: 'ap-south-1'
    },
    accessKeyId: 'AKIA5G4WQMXFUR4KRCEW',
    secretAccessKey: '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',
    smtpAccessKeyId: 'AKIAVZSKAPZUMDOIUWQR',
    smtpSecretAccessKey: 'BITjpfyduWf5AKisdIXJTsKVkvZ++f9lRLM5IfBwwqQD',
    region: 'ap-south-1',
    bucket: 'cricket-education/web',
    link: 'https://s3.ap-south-1.amazonaws.com/'
  };

  
  static permission = {}; //For Button Permission Check And Other Usage in Content Side
  static maintenanceOptions = []; //For Side Menu
  //side menu with url with role id
  static adminSideMenuWithRoleID = {
    Dashboard: '/admin/dashboard',
    Users: '/admin/users',
    Role: '/admin/security/role',
    setting: '/admin/setting',
    '': '/admin/profile',
  };
  static adminButtonPermission = [];
  static buttonPermissionDefault = [
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
  ];
  static dataTablePageSize = [5, 10, 25, 50, 100, 250, 500, 1000];
  static dateFormatList = [
    'd.M.yyyy',
    'd.M.yyyy.',
    'd.MM.yyyy',
    'dd.MM.yyyy',
    'dd.MM.yyyy.',
    'd/M/yyyy',
    'd/MM/yyyy',
    'dd/MM/yyyy',
    'dd/MMMM/yy',
    'dd/MM/yy',
    'd-M-yyyy',
    'dd-MM-yyyy',
    'dd-MMMM-yy',
    'dd-MM-yy',
    'M/d/yyyy',
    'MM-dd-yy',
    'MM-dd-yyyy',
    'MM/dd/yy',
    'MM/dd/yyyy',
    'MMMM-dd-yy',
    'MMMM-dd-yyyy',
    'MMMM/dd/yy',
    'MMMM/dd/yyyy',
    'yyyy. M. d',
    'yyyy.M.d',
    'yyyy.MM.dd.',
    'yyyy.d.M',
    'yy-MM-dd',
    'yy-MMMM-dd',
    'yyyy-M-d',
    'yyyy-MM-dd',
    'yyyy-MMMM-dd',
    'yy/MM/dd',
    'yy/MMMM/dd',
    'yyyy/M/d',
    'yyyy/MM/dd',
    'yyyy/MMMM/dd',
    'yyMMdd',
    'yyyyMMdd',
  ];
}

export default LocalStorage;
