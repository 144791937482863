import React, { useState, Component } from 'react';
import MenuItem from '@material-ui/core/MenuItem';
// import {
//   TextField,
// } from '@material-ui/core';
import TextField from '@mui/material/TextField';
import Send from './send';
import Invite from './Meeting';
import SimpleDateTime from 'react-simple-timestamp-to-date';
import APIRequest from '../utils/APIRequest';
import ConfigAPIURL from '../config/ConfigAPIURL';
import isEmpty from '../utils/isEmpty';
import PropTypes from 'prop-types';
import Switch from '@mui/material/Switch';
import Avatar from '../../assets/images/Avatar.jpg';
import search from '../../assets/images/search.png';
import users from '../../assets/images/users.png';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { CSVLink } from 'react-csv';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import FilterListIcon from '@material-ui/icons/FilterList';
import { Javascript, ThirtyFpsSelect } from '@mui/icons-material';

import Toaster from '../utils/Toaster';

import AWS from 'aws-sdk';

import LocalStorage from 'src/admin/config/LocalStorage';

import FileUpload from 'src/admin/templates/FileUpload';

import {
  MDBBtn,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter
} from 'mdb-react-ui-kit';

/**********************switches ************************/
// function ControlledSwitches() {

//   };

// return (

// );

/********************************************************table****************************** */
// function createData(name, email, phoneno, dateofbirth, document, login) {
//   return { name, email, phoneno, dateofbirth, document, login};
// }

// const rows = [
//   createData(<div className='align-center'><img src={Avatar} className='User_image me-4' /> <p className='mt-3'>Andrew Garfield</p></div>, 'aswin@gmail.com', '883773783839', '22-4-1999', <a href =''>Aadhar</a>,<ControlledSwitches />),

// ];

/********************************************************batches component****************************** */

export class batches extends Component {
  state = {
    options: [],
    batch: [],
    selectedBatch: '',
    courseOptions: [],
    course: [],
    selectedCourse: '',
    batchLists: [],
    totalChapterCount: 0,
    checked: true,
    exportdata: [],
    searchKey: '',
    id: '',
    aws: LocalStorage.aws,
    scrollableModal: false,
    scrollableModal2: false,
    userId: '',
    moveToBatch: '',
    batchUser: '',
    reason: '',
    userBatch: '',
    withHeldReason: '',
    oldBatchName: ''
  };

  componentDidMount() {
    this.fetchCourse();
  }
  setScrollableModalfn = (id, name, batchName) => {
    this.setState({
      scrollableModal: !this.state.scrollableModal
    });
    this.setState({
      reason: ''
    });
    this.setState({
      userId: id
    });
    this.setState({
      batchUser: name
    });
    this.setState({
      userBatch: batchName
    });
  };

  moveToWaitList = id => {
    const data = {
      reason: this.state.reason,
      batchName: this.state.userBatch
    };
    APIRequest.request(
      'POST',
      ConfigAPIURL.moveToWaitlist + '/' + this.state.userId,
      JSON.stringify(data)
    ).then(response => {
      if (response.code === 100 && response.data.responseCode === 109) {
        this.setState({
          scrollableModal: !this.state.scrollableModal
        });
        this.getBatchesList(
          this.state.selectedCourse,
          this.state.selectedBatch,
          this.state.searchKey
        );
      }
    });
  };

  moveToOther = id => {
    const data = { batchName: this.state.moveToBatch };
    APIRequest.request(
      'POST',
      ConfigAPIURL.moveToOther + '/' + this.state.userId,
      JSON.stringify(data)
    ).then(response => {
      if (response.code === 100 && response.data.responseCode === 109) {
        this.setState({
          scrollableModal2: !this.state.scrollableModal2
        });
        this.getBatchesList(
          this.state.selectedCourse,
          this.state.selectedBatch,
          this.state.searchKey
        );
      }
    });
  };

  setScrollableModalfn2 = (id, withHeldReason, oldBatchName) => {
    this.setState({
      scrollableModal2: !this.state.scrollableModal2
    });
    this.setState({
      userId: id
    });
    this.setState({
      withHeldReason: withHeldReason
    });
    this.setState({
      oldBatchName: oldBatchName
    });

    this.setState({
      moveToBatch: ''
    });
  };
  handleBatchChange = e => {
    this.setState({
      selectedBatch: e.target.value
    });

    this.getBatchesList(
      this.state.selectedCourse,
      e.target.value,
      this.state.searchKey
    );
  };
  handleBatchForChange = e => {
    this.setState({
      moveToBatch: e.target.value
    });
  };
  handleReasonChange = e => {
    this.setState({
      reason: e.target.value
    });
  };

  handleCourseChange = e => {
    this.setState({
      selectedCourse: e.target.value
    });
    this.getBatchesList(
      e.target.value,
      this.state.selectedBatch,
      this.state.searchKey
    );
  };

  handleDownload = url => {
    AWS.config.update({
      credentials: new AWS.CognitoIdentityCredentials({
        IdentityPoolId: this.state.aws.CognitoIdentityCredentials.IdentityPoolId
      }),
      region: this.state.aws.CognitoIdentityCredentials.region
    });
    let extractUrl = url.split('/');

    var s3 = new AWS.S3({ signatureVersion: 'v4' });
    let originalUrl = extractUrl[4];

    const params = {
      Bucket: this.state.aws.bucket,
      Key: originalUrl
    };
    function downloadBlob(blob, name = `${originalUrl}`) {
      // Convert your blob into a Blob URL (a special url that points to an object in the browser's memory)
      const blobUrl = URL.createObjectURL(blob);
      // Create a link element
      const link = document.createElement('a');
      // Set link's href to point to the Blob URL
      link.href = blobUrl;
      link.download = name;
      // Append link to the body
      document.body.appendChild(link);
      // Dispatch click event on the link
      // This is necessary as link.click() does not work on the latest firefox
      link.dispatchEvent(
        new MouseEvent('click', {
          bubbles: true,
          cancelable: true,
          view: window
        })
      );

      // Remove link from body
      document.body.removeChild(link);
    }
    let template = extractUrl[4];
    s3.getObject(params, (err, data) => {
      if (err) {
        console.log('error in fetching', err, err.stack);
      } else {
        let blob = new Blob([data.Body], { type: data.ContentType });
        let link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = template;
        link.click();
      }
    });
  };

  handleSearchChange = e => {
    this.setState({ searchKey: e.target.value });
    this.getBatchesList(
      this.state.selectedCourse,
      this.state.selectedBatch,
      e.target.value
    );
  };

  fetchBatch = () => {
    APIRequest.request(
      'POST',
      ConfigAPIURL.listOfDropdowns,
      JSON.stringify({ name: 'Batch Name' })
    ).then(response => {
      if (response.code === 100 && response.data.responseCode === 109) {
        this.options = response.data.result.options;
        this.setState({ options: response.data.result.options });
        this.setState({
          batch: this.options
            .filter(e => e.active && !isEmpty(e.value))
            .map(e => e.value)
        });
        this.setState({
          selectedBatch: this.state.batch[this.state.batch.length - 1]
        });
        this.setState({ searchKey: '' });
        this.getBatchesList(
          this.state.selectedCourse,
          this.state.batch[this.state.batch.length - 1],
          this.state.searchKey
        );
      }
    });
  };

  fetchCourse = () => {
    APIRequest.request('GET', ConfigAPIURL.adminAllModuleList, '').then(
      response => {
        if (response.code === 100 && response.data.responseCode === 109) {
          this.courseOptions = response.data.module;
          this.setState({ courseOptions: response.data.module });
          this.setState({ selectedCourse: this.state.courseOptions[0]['_id'] });
          this.fetchBatch();
        }
      }
    );
  };

  getBatchesList = (course, batch, searchKey) => {
    APIRequest.request(
      'GET',
      ConfigAPIURL.baseUrl +
        '/admin/user/batch-users?courseName=' +
        course +
        '&batchName=' +
        batch +
        '&searchKey=' +
        searchKey
    ).then(res => {
      if (!isEmpty(res) && res.code === 100) {
        if (res.data.responseCode === 109) {
          this.setState({
            batchLists: res.data.traineeUsers,
            totalChapterCount: res.data.totalChapterCount
          });
          this.batchLists = res.data.traineeUsers;
          console.log(this.batchLists);
          let newexportdata = this.state.batchLists.map(e => ({
            name: e.name == undefined ? (e.name = '') : e.name,
            email: e.email,
            phone: e.mobileNo == undefined ? (e.mobileNo = '') : e.mobileNo,
            course: e.courseAssociationName,
            batch: e.batchName,
            DOB: new Date(e.dob * 1000).toLocaleDateString('en-US')
          }));
          this.setState({ exportdata: newexportdata });
        }
      }
    });
  };
  resendInvite = id => {
    APIRequest.request(
      'POST',
      ConfigAPIURL.resendSignupMail + id,
      JSON.stringify({})
    ).then(response => {
      if (response.code === 100 && response.data.responseCode === 109) {
        // this.componentDidMount()
        Toaster.sucess('Welcome email sent successfully', 'topRight');
      }
    });
  };
  retakeCourse = (userId, courseId) => {
    const data = {
      userId: userId,
      courseId: courseId
    };
    APIRequest.request(
      'POST',
      ConfigAPIURL.retakeCourse,
      JSON.stringify(data)
    ).then(response => {
      console.log(response);

      if (response.code === 100 && response.data.responseCode === 109) {
        // this.componentDidMount()
        Toaster.sucess('Course reset successfully', 'topRight');
        this.getBatchesList(
          this.state.selectedCourse,
          this.state.selectedBatch,
          this.state.searchKey
        );
      }
    });
  };
  loginChange = id => {
    APIRequest.request('GET', ConfigAPIURL.adminUserLogin + id).then(
      response => {
        if (response.code === 100 && response.data.responseCode === 109) {
          // this.componentDidMount()
          this.getBatchesList(
            this.state.selectedCourse,
            this.state.selectedBatch,
            this.state.searchKey
          );
        }
      }
    );
  };

  camelCase = str => {
    return str.substring(0, 1).toUpperCase() + str.substring(1);
  };

  filterColumns = data => {
    // Get column names
    const columns = Object.keys(data[0]);
    let headers = [];
    columns.forEach((col, idx) => {
      // OR if (idx !== 0)
      headers.push({ label: this.camelCase(col), key: col });
    });
    return headers;
  };

  // const [scrollableModal, setScrollableModal] = useState(false);

  render() {
    return (
      <div className="batches p-0">
        {/* <div className='page-title'>
          <h3>Batch</h3>
          <p className='grey fw-500'> Learn At Your Own Speed.</p>
        </div> */}
        <div className="Batches-outer-btngroup mt-0 mb-4">
          <div className="row">
            {/* <div className="col-md-3 mb-2">
              <TextField
                className='drop_down_common mt-3'
                label="Select Course"
                select
                variant="outlined"
                value={this.state.selectedCourse}
                onChange={this.handleCourseChange}
              >
                {this.state.courseOptions.map(({ title,_id }, index) => <MenuItem value={_id} >{title}</MenuItem>)}
              </TextField>
            </div>
            <div className="col-md-3">
              <TextField
                className='drop_down_common mt-3'
                label="Select Batch"
                select
                variant="outlined"
                value={this.state.selectedBatch}
                onChange={this.handleBatchChange}
              >
                {this.state.batch.map(item => <MenuItem value={item} >{item}</MenuItem>)}
              </TextField>
            </div>
            <div className="col-md-3"></div> */}
            <div className="d-flex align-items-center col-md-4">
              <h5 className="text-black fw-bold">Batches</h5>
            </div>
            <div className="col-md-8 d-flex align-items-center gap-3 justify-content-end popups ">
              <Invite />
              <Send />
            </div>
            <div className="row mt-2">
              <div className="col-md-3">
                <TextField
                  className="drop_down_common dropdow-item"
                  label="Select Course"
                  select
                  variant="outlined"
                  value={this.state.selectedCourse}
                  onChange={this.handleCourseChange}
                >
                  {this.state.courseOptions.map(({ title, _id }, index) => (
                    <MenuItem value={_id}>{title}</MenuItem>
                  ))}
                </TextField>
              </div>

              <div className="col-md-3">
                <TextField
                  className="drop_down_common dropdown-item"
                  label="Select Batch"
                  select
                  variant="outlined"
                  value={this.state.selectedBatch}
                  onChange={this.handleBatchChange}
                >
                  {this.state.batch.map(item => (
                    <MenuItem value={item}>{item}</MenuItem>
                  ))}
                </TextField>
              </div>
            </div>
          </div>
        </div>
        <div className="">
          <div>
            <div className=" btn-group w-full">
              <div className="batches_btn w-full">
                <div className="row">
                  <div className="col-md-3">
                    <div className="search">
                      <img src={search} alt="" />
                      <input
                        type="text"
                        name=""
                        id=""
                        className="form-control"
                        placeholder="Search Here"
                        onChange={this.handleSearchChange}
                      />
                    </div>
                  </div>
                  <div className="col-md-9 text-end">
                    <div className="align-right">
                      {this.state.exportdata.length > 0 && (
                        <CSVLink
                          className="export_users align-center me-2"
                          data={this.state.exportdata}
                          headers={this.filterColumns(this.state.exportdata)}
                          filename={'Batch.csv'}
                        >
                          <Tooltip title="Export Users">
                            <IconButton>
                              <CloudDownloadIcon />
                            </IconButton>
                          </Tooltip>
                        </CSVLink>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-0 table-total">
            <TableContainer component={Paper}>
              <Table
                className="MuiTable-root MuiTable-stickyHeader w-100"
                aria-label="simple table"
              >
                <TableHead>
                  <TableRow>
                    <TableCell width={250}>Name</TableCell>
                    <TableCell>Email</TableCell>
                    <TableCell>Phone</TableCell>
                    <TableCell>Date of Birth</TableCell>
                    <TableCell>Document</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell>Login</TableCell>
                    <TableCell>Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {this.state.batchLists.map((data, index) => (
                    <TableRow
                      className="new-table"
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell>
                        <div className="align-center">
                          <img src={Avatar} className="User_image me-2" />{' '}
                          <p className="pt-1 mb-0">{data.name}</p>
                        </div>
                      </TableCell>
                      <TableCell>{data.email}</TableCell>
                      <TableCell>{data.mobileNo}</TableCell>
                      <TableCell>
                        {' '}
                        {data.dob && (
                          <SimpleDateTime
                            dateSeparator="-"
                            showTime="0"
                            format="DMY"
                          >
                            {data.dob}
                          </SimpleDateTime>
                        )}
                      </TableCell>
                      <TableCell>
                        <a
                          href="javascript:void(0)"
                          onClick={() => {
                            this.handleDownload(data.ageProof);
                          }}
                        >
                          {data.documentType}
                        </a>
                      </TableCell>
                      <TableCell>
                        {data.analytics?.length === this.state.totalChapterCount
                          ? 'Completed'
                          : 'Pending'}
                      </TableCell>
                      <TableCell className="switch">
                        <Switch
                          onClick={() => {
                            this.loginChange(data._id);
                          }}
                          checked={data.active == true ? true : false}
                          inputProps={{ 'aria-label': 'controlled' }}
                        />
                      </TableCell>
                      <TableCell>
                        {data.emailVerified == false &&
                          data.status == 'pending' && (
                            <a
                              href="javascript:void(0);"
                              onClick={() => this.resendInvite(data._id)}
                              className="mb-2 d-inline-block"
                            >
                              Reinvite
                            </a>
                          )}
                        <div className="action-btn">
                          {data.batchName != 'Waitlist' && (
                            <div>
                              <button
                                className="withheald"
                                onClick={() =>
                                  this.setScrollableModalfn(
                                    data._id,
                                    data.name,
                                    data.batchName
                                  )
                                }
                              >
                                Withheld
                              </button>
                            </div>
                          )}

                          {data.batchName == 'Waitlist' && (
                            <div>
                              <button
                                className="release"
                                onClick={() =>
                                  this.setScrollableModalfn2(
                                    data._id,
                                    data.withHeldReason,
                                    data.oldBatchName
                                  )
                                }
                              >
                                Release
                              </button>
                            </div>
                          )}
                          {data.analytics?.length ===
                            this.state.totalChapterCount && (
                            <button
                              className="withheald mb-2 d-inline-block"
                              onClick={() =>
                                this.retakeCourse(data._id, data.course._id)
                              }
                            >
                              Reset Course
                            </button>
                          )}
                        </div>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
              <MDBModal show={this.state.scrollableModal} tabIndex="-1">
                <MDBModalDialog scrollable>
                  <MDBModalContent>
                    <MDBModalHeader>
                      <MDBModalTitle>Withheld</MDBModalTitle>
                      <MDBBtn
                        className="btn-close"
                        color="none"
                        onClick={() => this.setScrollableModalfn()}
                      ></MDBBtn>
                    </MDBModalHeader>
                    <MDBModalBody>
                      <TextField
                        id="outlined-textarea"
                        label="Enter Reason"
                        onChange={this.handleReasonChange}
                        value={this.state.reason}
                        placeholder="Enter Reason here..."
                        multiline
                        className="w-100 mb-3 mt-2"
                      />
                      <h6>
                        Do you want to withheld the student{' '}
                        {this.state.batchUser}?
                      </h6>
                    </MDBModalBody>
                    <MDBModalFooter>
                      <MDBBtn
                        color="secondary"
                        onClick={() => this.setScrollableModalfn()}
                      >
                        No
                      </MDBBtn>
                      <MDBBtn onClick={() => this.moveToWaitList()}>Yes</MDBBtn>
                    </MDBModalFooter>
                  </MDBModalContent>
                </MDBModalDialog>
              </MDBModal>
              <MDBModal
                className="release-modal-outer"
                show={this.state.scrollableModal2}
                tabIndex="-1"
              >
                <MDBModalDialog scrollable className="release-modal">
                  <MDBModalContent>
                    <MDBModalHeader>
                      <MDBModalTitle>Release</MDBModalTitle>
                      <MDBBtn
                        className="btn-close"
                        color="none"
                        onClick={() => this.setScrollableModalfn2()}
                      ></MDBBtn>
                    </MDBModalHeader>
                    <MDBModalBody>
                      <div className="mb-3">
                        <label>Previous Batch</label>
                        <p>{this.state.oldBatchName}</p>
                      </div>
                      <div className="mb-3">
                        <label>Withhealth Reason</label>
                        <p>{this.state.withHeldReason}</p>
                      </div>
                      <TextField
                        className="drop_down_common mt-3"
                        label="Release Batch"
                        select
                        variant="outlined"
                        value={this.state.moveToBatch}
                        onChange={this.handleBatchForChange}
                      >
                        {this.state.batch.map(item => (
                          <MenuItem value={item}>{item}</MenuItem>
                        ))}
                      </TextField>
                    </MDBModalBody>
                    <MDBModalFooter>
                      <MDBBtn
                        color="secondary"
                        onClick={() => this.setScrollableModalfn2()}
                      >
                        Close
                      </MDBBtn>
                      <MDBBtn onClick={() => this.moveToOther()}>Submit</MDBBtn>
                    </MDBModalFooter>
                  </MDBModalContent>
                </MDBModalDialog>
              </MDBModal>
            </TableContainer>
          </div>
        </div>
      </div>
    );
  }
}

export default batches;
