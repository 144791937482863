import React, { Component } from 'react';
import { Navbar, Nav } from 'react-bootstrap';
import { MdMenu } from 'react-icons/md';
import { FaUser } from 'react-icons/fa';
import LocalStorage from './config/LocalStorage';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Tooltip } from '@material-ui/core';
import { Typography, Box, Badge, Avatar, Hidden } from '@material-ui/core';
import Logo from 'src/components/Logo';

export default class Header extends Component {
  render() {
    return (
      <div className='admin-header'>
          <header>
          <Navbar
        className=""
        expand="lg"
        fixed="top"
        style={{
          boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px'
        }}
      >
        <div
          style={{
            width: '100%',
            padding: '0 16px',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}
        >
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Nav>
              <MdMenu
                style={{ color: '#fff', fontSize: '30px', marginRight: '20px' }}
                onClick={() => this.props.callback()}
              />
            </Nav>


        <div className='logo-details py-1'>
         <Logo className="topbar__logo" />
            <div className='divider-line'></div>
            <div>
              <Typography variant="h5" color="inherit">
              <span className='d-block lh-base'>National Cricket Academy</span> Board of Control for Cricket in India
              </Typography>
            </div>
          </div>


            {/* <Navbar.Toggle>
          <FaUser
            variant="contained"
            style={{ color: '#fff', fontSize: '20px' }}
            color="primary"
            component="span"
          />
        </Navbar.Toggle> */}
          </div>
          {/* <Navbar.Collapse
          id="basic-navbar-nav"
          style={{ color: '#fff', fontSize: '20px', fontWeight: 'bold' }}
          className="justify-content-end"
        >
          {LocalStorage.userName} */}
          {/* <Link
            href={'/#/admin/profile'}
            color="inherit"
            className={'userProfile'}
          >
            {' ' + LocalStorage.userName} 
          </Link> */}
          {/* </Navbar.Collapse> */}
        <ul className='navbar-nav'>
        <li class="nav-item dropdown user-dd">
      <div class="dropdown-toggle align-center drop-profile" type="button" data-toggle="dropdown">
      <div className='align-center'>
        
      <div class="MuiBox-root MuiBox-root-14 align-center me-3">
        <div class="MuiBox-root MuiBox-root-15">
          <a class="username text-right" variant="h5" color="textPrimary" underline="none">  {LocalStorage.userName.length > 9
                ? LocalStorage.userName.slice(0, 9)
                : LocalStorage.userName}</a>
          <p class="MuiTypography-root level text-right MuiTypography-body1">Admin </p>
          </div>
            </div>
   
          <Avatar style={{ backgroundColor: 'orange',}}>
            {LocalStorage.userName[0]}
          </Avatar>
          </div>
     </div>
    <ul class="dropdown-menu drop-profile-content">
    <li><Link to="/admin/change-password" className='dropdown-item'> Change Password </Link></li>
      <li> 
      <Link to="/admin/logout" className='dropdown-item'> Logout </Link>
       </li>
      
    </ul>
      </li>
        </ul>
       
        </div>
      </Navbar>  
          </header> 
      </div>
    );
  }
}
