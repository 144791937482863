import React, { useState } from 'react';
import Avatar from '../../assets/images/Avatar.jpg';
import certificateImg from '../../assets/images/ceritificate-base.jpg';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import search from '../../assets/images/search.png';
import MenuItem from '@material-ui/core/MenuItem';
import Modal from 'react-bootstrap/Modal';
import { Button } from 'react-bootstrap';
import { TextField } from '@material-ui/core';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { useEffect, useRef } from 'react';
import APIRequest from '../utils/APIRequest';
import ConfigAPIURL from '../config/ConfigAPIURL';
import isEmpty from '../utils/isEmpty';

import Toaster from 'src/admin/utils/Toaster';
import {
  MDBTabs,
  MDBTabsItem,
  MDBTabsLink,
  MDBTabsContent,
  MDBTabsPane
} from 'mdb-react-ui-kit';
import { CSVLink } from 'react-csv';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import FilterListIcon from '@material-ui/icons/FilterList';

export default function Certificates() {
  const [basicActive, setBasicActive] = useState('tab1');
  const [showViewCertificate, setshowViewCertificate] = useState(false);
  const [showGenerateCertificate, setshowGenerateCertificate] = useState(false);
  const [showBulkCertificate, setshowBulkCertificate] = useState(false);
  var [courseOptions, setCourseOptions] = useState([]);
  var [batch, setBatch] = useState([]);
  var [selectedBatch, setSelectedBatch] = React.useState('');
  var [selectedCourse, setSelectedCourse] = React.useState('');
  var [courseid, setCourseId] = useState('');
  var [options, setOptions] = useState([]);
  const [name, setName] = React.useState([]);
  const [certificate, setCertificate] = React.useState({});
  const [user, setUser] = React.useState([]);
  const [duration, setDuration] = React.useState([]);
  const [result, setResult] = React.useState('Passed');
  const [nameerror, setNameError] = useState({});
  const [durerror, setDurError] = useState({});
  var [selectedBatch2, setSelectedBatch2] = React.useState('');
  var [selectedCourse2, setSelectedCourse2] = React.useState('');
  var [selectedBatch3, setSelectedBatch3] = React.useState('');
  var [selectedCourse3, setSelectedCourse3] = React.useState('');
  const [fileerr, setFileError] = useState({});
  const [file, setFile] = useState();
  var [searchKey, setSearchKey] = React.useState('');
  var [certificateId, setCertificateId] = React.useState('');

  var [certificates, setCertificates] = React.useState([]);
  var [userList, setUserList] = React.useState([]);
  var [userListForDownload, setUserListForDownload] = React.useState([]);
  const headers = [
    { label: 'Name', key: 'name' },
    { label: 'Email', key: 'email' },
    { label: 'Course', key: 'course.title' },

    { label: 'Batch', key: 'batchName' },

    { label: 'Result', key: 'result' },

    { label: 'Duration', key: 'result' }
  ];

  const handleBasicClick = value => {
    if (value === basicActive) {
      return;
    }

    setBasicActive(value);
  };

  const closeViewCertificate = () => {
    setshowViewCertificate(false);
  };
  const handleViewCertificate = certificate => {
    setCertificate(certificate);
    setshowViewCertificate(true);
  };

  const handleBulkCertificate = () => {
    setshowBulkCertificate(true);
  };

  const closeBulkCertificate = () => {
    setshowBulkCertificate(false);
  };

  const closeGenerateCertificate = () => {
    setName('');
    setshowGenerateCertificate(false);
  };
  const handleGenerateCertificate = (id, item) => {
    setUser(id);
    if (item.name) {
      setName(item.name);
    }
    if (item.results.length > 0 && item.results[0].result) {
      setResult(item.results[0].result === 'Failed' ? 'Fail' : 'Pass');
    }
    setshowGenerateCertificate(true);
  };

  const handleFileChange = event => {
    setFile(event.target.files[0]);
  };

  useEffect(() => {
    getBatch();
  }, []);

  const getBatchesList = (course, batch, searchKey) => {
    APIRequest.request(
      'GET',
      ConfigAPIURL.baseUrl +
        '/admin/user/certificate-users?courseName=' +
        course +
        '&batchName=' +
        batch +
        '&searchKey=' +
        searchKey
    ).then(res => {
      if (!isEmpty(res) && res.code === 100) {
        if (res.data.responseCode === 109) {
          userList = res.data.traineeUsers;
          setUserList(userList);
        }
      }
    });
  };
  const getBatchesListForDownload = (course, batch, searchKey) => {
    APIRequest.request(
      'GET',
      ConfigAPIURL.baseUrl +
        '/admin/user/batch-users?courseName=' +
        selectedCourse3 +
        '&batchName=' +
        selectedBatch3 +
        '&searchKey='
    ).then(res => {
      if (!isEmpty(res) && res.code === 100) {
        if (res.data.responseCode === 109) {
          userList = res.data.traineeUsers;
          setUserListForDownload(userList);
        }
      }
    });
  };

  const getBatch = () => {
    APIRequest.request(
      'POST',
      ConfigAPIURL.listOfDropdowns,
      JSON.stringify({ name: 'Batch Name' })
    ).then(response => {
      if (response.code === 100 && response.data.responseCode === 109) {
        options = response.data.result.options;
        setOptions(options);
        batch = options
          .filter(e => e.active && !isEmpty(e.value))
          .map(e => e.value);
        setBatch(batch);
        selectedBatch = batch[0];
        setSelectedBatch(batch[0]);
        selectedBatch2 = batch[0];
        setSelectedBatch2(batch[0]);
        selectedBatch3 = batch[0];
        setSelectedBatch3(batch[0]);
        getCourse();
      }
    });
  };

  const getCourse = () => {
    APIRequest.request('GET', ConfigAPIURL.adminAllModuleList, '').then(
      response => {
        if (response.code === 100 && response.data.responseCode === 109) {
          courseOptions = response.data.module;
          setCourseOptions(courseOptions);
          selectedCourse = courseOptions[0]['title'];
          setSelectedCourse(selectedCourse);
          selectedCourse2 = courseOptions[0]['title'];
          setSelectedCourse2(selectedCourse2);
          selectedCourse3 = courseOptions[0]['_id'];
          setSelectedCourse3(selectedCourse3);
          courseid = courseOptions[0]['_id'];
          setCourseId(courseid);
          getBatchesList(courseid, selectedBatch, searchKey);
          getBatchesListForDownload();
        }
      }
    );
  };

  function captureDiv() {
    const div = document.getElementById('capture');
    html2canvas(div, { scale: 3 }).then(canvas => {
      const imgData = canvas.toDataURL('image/png');
      const imgWidth = 210; // A4 size width in mm
      const pageHeight = 297; // A4 size height in mm
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      const doc = new jsPDF('p', 'mm');
      const position = 0;
      doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
      doc.save(certificate.name + '_certificate.pdf');
    });
  }

  const handleCourseChange = e => {
    selectedCourse = e.target.value;
    setSelectedCourse(selectedCourse);
    const clickedOption = courseOptions.find(
      item => item.title === selectedCourse
    );
    courseid = `${clickedOption._id}`;
    setCourseId(courseid);
    getBatchesList(courseid, selectedBatch, searchKey);
  };

  const handleBatchChange = e => {
    selectedBatch = e.target.value;
    setSelectedBatch(selectedBatch);
    getBatchesList(courseid, selectedBatch, searchKey);
  };

  const handleSearchChange = e => {
    searchKey = e.target.value;
    console.log('searchKey>>', searchKey);
    getBatchesList(courseid, selectedBatch, searchKey);
  };
  const handleCertificateChange = e => {
    setCertificateId(e.target.value);
  };

  const handleCourseChange2 = e => {
    selectedCourse2 = e.target.value;
    setSelectedCourse2(selectedCourse2);
  };
  const handleResult = e => {
    setResult(e.target.value);
  };

  const handleBatchChange2 = e => {
    selectedBatch2 = e.target.value;
    setSelectedBatch2(selectedBatch2);
  };
  const handleCourseChange3 = e => {
    selectedCourse3 = e.target.value;
    setSelectedCourse3(selectedCourse3);
    getBatchesListForDownload();
  };

  const handleBatchChange3 = e => {
    selectedBatch3 = e.target.value;
    setSelectedBatch3(selectedBatch3);
    getBatchesListForDownload();
  };
  const onNameChange = e => {
    setName(e.target.value);
  };
  const onDurationChange = e => {
    setDuration(e.target.value);
  };

  const formValidation = () => {
    const nameerror = {};
    const durerror = {};

    let isValid = true;

    if (name == undefined || name == '') {
      nameerror.nameempty = 'Please input name';
      isValid = false;
    }
    if (duration == undefined || duration == '') {
      durerror.durempty = 'Please input duration';
      isValid = false;
    }
    setNameError(nameerror);
    setDurError(durerror);
    return isValid;
  };

  const submit = async e => {
    e.preventDefault();
    const isValid = formValidation();
    if (isValid) {
      //api call
      const data = {
        user: user,
        name: name,
        batch: selectedBatch,
        course: selectedCourse,
        result: result,
        duration: duration
      };

      APIRequest.request(
        'POST',
        ConfigAPIURL.generateCertificate,
        JSON.stringify(data)
      ).then(response => {
        if (response.code === 100 && response.data.responseCode === 109) {
          Toaster.sucess('Certificate generated successfully.', 'topRight');
          setName('');
          setResult('Passed');
          setDuration('');
          setshowGenerateCertificate(false);
          getBatchesList(courseid, selectedBatch, searchKey);
        }
      });
    }
  };
  const searchCertificate = async e => {
    e.preventDefault();
    if (certificateId == undefined || certificateId == '') {
      nameerror.nameempty = 'Please enter certificate id';
    } else {
      //api call
      const data = {
        createdAt: certificateId
      };

      APIRequest.request(
        'POST',
        ConfigAPIURL.getCertificate,
        JSON.stringify(data)
      ).then(response => {
        if (response.code === 100 && response.data.responseCode === 109) {
          setCertificates(response.data.certificates);
        }
      });
    }
  };

  const formValidation2 = () => {
    const fileerr = {};
    let isValid2 = true;

    if (file == undefined) {
      fileerr.phoneempty = 'Please input file';
      isValid2 = false;
    }

    setFileError(fileerr);

    return isValid2;
  };

  const submitBulk = async e => {
    e.preventDefault();
    const isValid2 = formValidation2();
    if (isValid2) {
      //api call
      const formData = new FormData();
      formData.append('file', file);

      APIRequest.multipartForDataRequest(
        'POST',
        ConfigAPIURL.importCertificate,
        formData
      ).then(response => {
        if (response.code === 100 && response.data.responseCode === 109) {
          setshowBulkCertificate(false);
          getBatchesList(courseid, selectedBatch, searchKey);
        }
      });
    }
  };

  return (
    <div className="certificates-page-admin p-0">
      <div className="d-flex align-items-center col-md-4 mb-4">
        <h5 className="text-black fw-bold">Certificates</h5>
      </div>

      <MDBTabs className="mb-3">
        <MDBTabsItem>
          <MDBTabsLink
            onClick={() => handleBasicClick('tab1')}
            active={basicActive === 'tab1'}
          >
            <span className="px-3">Certificates</span>
          </MDBTabsLink>
        </MDBTabsItem>
        <MDBTabsItem>
          <MDBTabsLink
            onClick={() => handleBasicClick('tab2')}
            active={basicActive === 'tab2'}
          >
            <span className="px-3">Verify</span>
          </MDBTabsLink>
        </MDBTabsItem>
      </MDBTabs>

      <MDBTabsContent>
        <MDBTabsPane show={basicActive === 'tab1'}>
          <div className="row mb-3 mt-4">
            <div className="col-md-3 mb-2">
              <TextField
                className="drop_down_common mt-3"
                label="Select Course"
                select
                variant="outlined"
                value={selectedCourse}
                onChange={handleCourseChange}
              >
                {courseOptions.map(({ title }, index) => (
                  <MenuItem value={title}>{title}</MenuItem>
                ))}
              </TextField>
            </div>
            <div className="col-md-3">
              <TextField
                className="drop_down_common mt-3"
                label="Select Batch"
                select
                variant="outlined"
                value={selectedBatch}
                onChange={handleBatchChange}
              >
                {batch.map(index => (
                  <MenuItem value={index}>{index}</MenuItem>
                ))}
              </TextField>
            </div>
            <div className="col-md-6 d-flex align-items-center justify-content-end">
              <button
                className="btn btn-primary"
                onClick={handleBulkCertificate}
              >
                Bulk Certificate
              </button>
            </div>
          </div>

          <div className="table-container">
            <div className=" btn-group w-full">
              <div className="batches_btn w-full">
                <div className="row">
                  <div className="col-md-3">
                    <div className="search">
                      <img src={search} alt="" />
                      <input
                        type="text"
                        name=""
                        id=""
                        className="form-control"
                        placeholder="Search Here"
                        onChange={handleSearchChange}
                      />
                    </div>
                  </div>
                  <div className="col-md-9 text-end">
                    <div className="align-right">
                      {/* <div className='export_users align-center me-2' >     
                    <Tooltip title="Export">
                      <IconButton>
                        <CloudDownloadIcon />
                      </IconButton>
                    </Tooltip>
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell align="">Certificate</TableCell>
                    <TableCell align="">Name</TableCell>
                    <TableCell align="">Course</TableCell>
                    <TableCell align="">Batch</TableCell>
                    <TableCell align="">Result</TableCell>
                    <TableCell align="">Action</TableCell>
                  </TableRow>
                </TableHead>
                {userList &&
                  userList.map(item => (
                    <TableBody>
                      <TableRow
                        sx={{
                          '&:last-child td, &:last-child th': { border: 0 }
                        }}
                      >
                        <TableCell align="" width="250">
                          {item.certificate.length > 0 && (
                            <span>{item.certificate[0].createdAt}</span>
                          )}{' '}
                        </TableCell>
                        <TableCell align="" width="350">
                          <div className="align-center">
                            <img src={Avatar} className="User_image me-4" />
                            <div>
                              <span className="text-capitalize">
                                {item?.name}
                              </span>
                              <p className="small mb-0 pb-0">{item?.email}</p>
                            </div>
                          </div>
                        </TableCell>
                        <TableCell align="" width="350">
                          {item?.course.title}
                        </TableCell>
                        <TableCell align="" width="350">
                          {item?.batchName}
                        </TableCell>
                        <TableCell align="" width="350">
                          {item.results.length > 0 && item?.results[0].result}
                        </TableCell>

                        <TableCell align="">
                          {item.certificate.length > 0 && (
                            <button
                              className="btn btn-sm btn-primary me-1"
                              onClick={() =>
                                handleViewCertificate(item.certificate[0])
                              }
                            >
                              View
                            </button>
                          )}
                          {item.certificate.length == 0 &&
                          item.results.length &&
                          item.results[0].result === 'Passed' ? (
                            <button
                              className="btn btn-sm btn-primary-transparent"
                              onClick={() =>
                                handleGenerateCertificate(item._id, item)
                              }
                            >
                              Generate
                            </button>
                          ) : (
                            <div></div>
                          )}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  ))}
              </Table>
            </TableContainer>
          </div>
        </MDBTabsPane>
        <MDBTabsPane show={basicActive === 'tab2'}>
          <div className="mt-4">
            <div className="d-flex align-items-center justify-content-between">
              <div class="input-group col-5">
                <input
                  type="search"
                  class="form-control rounded"
                  onChange={handleCertificateChange}
                  placeholder="Enter Certificate ID"
                  aria-label="Search"
                  aria-describedby="search-addon"
                />
                <button
                  type="button"
                  class="btn btn-primary btn-sm"
                  onClick={searchCertificate}
                >
                  <span class="text-capitalize">Search</span>
                </button>
              </div>
            </div>

            <div className="table-container custom-table mt-4">
              <table class="table">
                <thead>
                  <tr>
                    <th scope="col">Certificate ID</th>
                    <th scope="col">Name</th>
                    <th scope="col">Course</th>
                    <th scope="col">Batch</th>
                    <th scope="col">Result</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {certificates &&
                    certificates.map(item => (
                      <>
                        <tr>
                          <td>{item.createdAt}</td>
                          <td>
                            <div className="align-center">
                              <img src={Avatar} className="User_image me-4" />
                              <div>
                                <p className="small mb-0 pb-0 text-capitalize">
                                  {item.user.name}
                                </p>
                                <span className="small">{item.user.email}</span>
                              </div>
                            </div>
                          </td>
                          <td>{item?.course}</td>
                          <td>{item?.batchName}</td>
                          <td>{item?.result}</td>
                          <td>
                            <button
                              className="btn btn-sm btn-primary me-1"
                              onClick={() => handleViewCertificate(item)}
                            >
                              View
                            </button>
                          </td>
                        </tr>
                      </>
                    ))}
                  {certificates && certificates.length === 0 && (
                    <tr>
                      <td colSpan="6">No results found.</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </MDBTabsPane>
      </MDBTabsContent>

      {/* popups */}

      <Modal
        show={showGenerateCertificate}
        onHide={closeGenerateCertificate}
        className="generate-certificate-model"
      >
        <div className="px-4 py-3" closeButton>
          <h6 className="text-black">Generate Certificate</h6>
        </div>
        <Modal.Body>
          <div className="row mt-2">
            <div className="col-4 form-section">
              <TextField
                className="drop_down_common mb-3 mt-3"
                label="Candidate Name"
                text
                variant="outlined"
                value={name}
                onChange={onNameChange}
              ></TextField>
              {Object.keys(nameerror).map(key => {
                return <div className="err-msg">{nameerror[key]}</div>;
              })}
              <TextField
                className="drop_down_common mb-3 mt-3"
                label="Select Course"
                select
                variant="outlined"
                value={selectedCourse}
                disabled
                onChange={handleCourseChange2}
              >
                {courseOptions.map(({ title }, index) => (
                  <MenuItem value={title}>{title}</MenuItem>
                ))}
              </TextField>

              <TextField
                className="drop_down_common mt-3 mb-1"
                label="Select Batch"
                select
                variant="outlined"
                value={selectedBatch}
                disabled
                onChange={handleBatchChange2}
              >
                {batch.map(index => (
                  <MenuItem value={index}>{index}</MenuItem>
                ))}
              </TextField>
              <TextField
                className="drop_down_common mb-1 mt-4"
                label="Select Result"
                select
                variant="outlined"
                value={result}
                disabled
                onChange={handleResult}
              >
                {/* <MenuItem value="Distinction">Distinction</MenuItem> */}
                <MenuItem value="Pass">Pass</MenuItem>
                <MenuItem value="Fail">Fail</MenuItem>
              </TextField>
              <TextField
                className="drop_down_common mb-3 mt-4"
                label="Duration"
                text
                variant="outlined"
                value={duration}
                onChange={onDurationChange}
              ></TextField>
              {Object.keys(durerror).map(key => {
                return <div className="err-msg">{durerror[key]}</div>;
              })}
            </div>
            <div className="col-8">
              <div className="certificate-container">
                <img src={certificateImg} className="certificate-img" />
                <div className="positioned-text text-center">
                  <p className="mb-3">This is to certify that</p>
                  <p className="candidate-name text-capitalize">{name}</p>
                  <p>
                    has successfully completed the {selectedCourse} Courses
                    with​
                  </p>
                  <h5 className="highlighted text-uppercase">{result}</h5>
                  <p>conducted by</p>
                  <h5 className="highlighted">NATIONAL CRICKET ACADEMY​</h5>
                  <p>
                    during the month/s:{' '}
                    <span className="text-capitalize">{duration}</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <div className="text-end p-4">
          <Button
            className="me-2"
            variant="secondary"
            onClick={closeGenerateCertificate}
          >
            Close
          </Button>
          <Button variant="primary" onClick={submit}>
            Submit
          </Button>
        </div>
      </Modal>

      <Modal
        show={showViewCertificate}
        onHide={closeViewCertificate}
        className="view-certificate-model"
      >
        <div className="px-4 py-3" closeButton>
          <h6 className="text-black mb-0">View Certificate</h6>
        </div>
        <Modal.Body>
          <div className="certificate-container" id="capture">
            <img src={certificateImg} className="certificate-img" />
            <div className="positioned-text text-center">
              <p className="mb-3">This is to certify that</p>
              <p className="candidate-name text-capitalize">
                {certificate.name}
              </p>
              <p>
                has successfully completed the {certificate.course} courses
                with​ result
              </p>
              <h5 className="highlighted text-uppercase">
                {certificate.result}
              </h5>
              <p>conducted by</p>
              <h5 className="highlighted">NATIONAL CRICKET ACADEMY​</h5>
              <p>
                during the months:{' '}
                <span className="text-capitalize">{certificate.duration}</span>
              </p>
            </div>
            <p className="contact-detail">
              For information contact nca.edu@bcci.tv{' '}
            </p>
            <p className="certificate-id">{certificate.createdAt}</p>
          </div>
        </Modal.Body>
        <div className="text-end px-4 pt-2 pb-2">
          <Button
            className="me-2"
            variant="secondary"
            onClick={closeViewCertificate}
          >
            Close
          </Button>
          <Button variant="primary" onClick={captureDiv}>
            Download
          </Button>
        </div>
      </Modal>

      <Modal
        show={showBulkCertificate}
        onHide={closeBulkCertificate}
        className="view-certificate-model bulk-certificate"
      >
        <div className="px-4 py-3" closeButton>
          <h6 className="text-black mb-0">Bulk Certificate</h6>
        </div>
        <Modal.Body>
          <div className="mt-4">
            <TextField
              className="drop_down_common"
              label="Select Course"
              select
              variant="outlined"
              value={selectedCourse3}
              onChange={handleCourseChange3}
            >
              {courseOptions.map(({ _id, title }, index) => (
                <MenuItem value={_id}>{title}</MenuItem>
              ))}
            </TextField>
          </div>
          <div className="mt-5">
            <TextField
              className="drop_down_common"
              label="Select Batch"
              select
              variant="outlined"
              value={selectedBatch3}
              onChange={handleBatchChange3}
            >
              {batch.map(index => (
                <MenuItem value={index}>{index}</MenuItem>
              ))}
            </TextField>
          </div>
          <div className="batches_drag_drop">
            <input
              onChange={handleFileChange}
              type="file"
              name=""
              id=""
              className="form-control mt-2"
            />
            {Object.keys(fileerr).map(key => {
              return <div style={{ color: 'red' }}>{fileerr[key]}</div>;
            })}
          </div>

          <div className="mt-4">
            <CSVLink
              data={userListForDownload}
              filename={'batchdata.csv'}
              headers={headers}
            >
              Download Batch Data
            </CSVLink>
          </div>
        </Modal.Body>
        <div className="text-end px-4 pt-2 pb-2">
          <Button
            className="me-2"
            variant="secondary"
            onClick={closeBulkCertificate}
          >
            Close
          </Button>
          <Button variant="primary" onClick={submitBulk}>
            Submit
          </Button>
        </div>
      </Modal>
    </div>
  );
}
