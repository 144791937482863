import ConfigAPIURL from '../config/ConfigAPIURL';
import APIRequest from './APIRequest';
const Logout = {
  logout: function() {
    APIRequest.request('GET', ConfigAPIURL.logout, '').then(response => {
      if (response.code === 100) {
        window.location.href = '/#/admin/login';
      }
    });
  }
};
export default Logout;
