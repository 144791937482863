import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  AppBar,
  Box,
  Hidden,
  IconButton,
  Typography,
  Toolbar,
  makeStyles,
  SvgIcon
} from '@material-ui/core';
import { Menu as MenuIcon } from 'react-feather';
import Logo from 'src/components/Logo';
import { THEMES } from 'src/constants';
import Account from './Account';
import Notifications from './Notifications';
import Search from './Search';
import Settings from './Settings';

const useStyles = makeStyles(theme => ({
  root: {
    zIndex: theme.zIndex.drawer + 100,
    ...(theme.name === THEMES.LIGHT
      ? {
          boxShadow: 'none',
          backgroundColor: theme.palette.primary.main
        }
      : {}),
    ...(theme.name === THEMES.ONE_DARK
      ? {
          backgroundColor: theme.palette.background.default
        }
      : {})
  },
  logo: {
    marginRight: theme.spacing(2)
  },
  toolbar: {
    minHeight: 64
  }
}));

const TopBar = ({ className, onMobileNavOpen, ...rest }) => {
  const classes = useStyles();

  return (
    <div className='app-header'>
    <AppBar className={clsx(classes.root, className)} {...rest}>
      <Toolbar className={classes.toolbar}>
        <Hidden lgUp>
          <IconButton color="inherit" onClick={onMobileNavOpen} className='trainee_left_collapse_button'>
            <SvgIcon fontSize="medium">
              <MenuIcon className='top-menu-icon'/>
            </SvgIcon>
          </IconButton>
        </Hidden>
        <Hidden mdDown>
         <div className='logo-details'>
         <Logo className="topbar__logo" />
            <div className='divider-line'></div>
            <Hidden mdDown>
              <Typography variant="h5" color="inherit">
              <span className='d-block lh-base'>National Cricket Academy</span> Board of Control for Cricket in India
              </Typography>
            </Hidden>
          </div>
        </Hidden>
        <Box ml={2} flexGrow={1} />
        {/* <Search /> */}
        {/* <Notifications /> */}
        {/* <Settings /> */}
        <Box ml={2}>
          <Account />
        </Box>
      </Toolbar>
    </AppBar>
    </div>
  );
};

TopBar.propTypes = {
  className: PropTypes.string,
  onMobileNavOpen: PropTypes.func
};

TopBar.defaultProps = {
  onMobileNavOpen: () => {}
};

export default TopBar;
