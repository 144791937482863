import React, { useState } from 'react';
import 'date-fns';
import {
  MDBTabs,
  MDBTabsItem,
  MDBTabsLink,
  MDBTabsContent,
  MDBTabsPane
} from 'mdb-react-ui-kit';
import MenuItem from '@material-ui/core/MenuItem';
import { TextField } from '@material-ui/core';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Tooltip from '@material-ui/core/Tooltip';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import search from '../../assets/images/search.png';
import IconButton from '@material-ui/core/IconButton';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import VisibilityIcon from '@mui/icons-material/Visibility';
import EditIcon from '@mui/icons-material/Edit';
import DateFnsUtils from '@date-io/date-fns';
import {
  DateTimePicker,
  KeyboardDateTimePicker,
  DatePicker,
  MuiPickersUtilsProvider
} from '@material-ui/pickers';
import APIRequest from '../utils/APIRequest';
import ConfigAPIURL from '../config/ConfigAPIURL';
import { useEffect, useRef } from 'react';
import { Button } from 'react-bootstrap';
import FormValidation from 'src/admin/utils/FormValidation';
import { render } from 'nprogress';
import { KeyboardDatePicker } from '@material-ui/pickers';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import MomentUtils from '@date-io/moment';
import PropTypes from 'prop-types';
import * as XLSX from 'xlsx';
import Toaster from '../utils/Toaster';
import sampleFeedbackImport from 'src/assets/sampleFile/Share_360_Feedback.xlsx';

import moment from 'moment';

function Feedback() {
  //const [value, setValue] = React.useState(new Date());
  const [basicActive, setBasicActive] = useState('tab1');
  //var [feedback, setFeedbacks] = React.useState([]);
  var [feedbacks, setFeedbacks] = React.useState([]);
  var [feedbackUsers, setFeedbackUsers] = React.useState([]);
  var [userRoles, setUserRoles] = React.useState([]);
  var [roleNames, setRoleNames] = React.useState([]);
  var [viewFeedback, setFeedback] = useState();
  var [page, setPage] = React.useState(1);
  var [skip, setSkip] = React.useState(0);
  var [totalcount, setTotalcount] = React.useState(0);
  var [feedbackUsersTotalcount, setFeedbackUsersTotalcount] = React.useState(0);
  var [userRolesTotalcount, setUserRolesTotalcount] = React.useState(0);

  const defaultPosition = {
    name: '',
    _id: ''
  };
  const defaultFeedback = {
    roleId: '',
    name: '',
    email: []
  };
  const feedbackUser = {
    role: '',
    name: ''
  };
  const feedbackUserError = {
    roleError: '',
    nameError: ''
  };
  const DefaultUserfilter = {
    role: '',
    search: ''
  };
  const DefaultFeedbackfilter = {
    role: '',
    roleName: '',
    search: '',
    date: ''
  };
  const DefaultRoleFilter = {
    search: ''
  };
  const [user, setValues] = useState(feedbackUser);
  const [feedback, setNewFeedback] = useState(defaultFeedback);
  const [userError, setErrorValues] = useState(feedbackUserError);
  const [filter, setFilterValues] = useState(DefaultUserfilter);
  const [feedbackfilter, setFeedbackFilterValues] = useState(
    DefaultFeedbackfilter
  );
  const [roleFilter, setRoleFilterValues] = useState(DefaultRoleFilter);
  const [position, setPosition] = React.useState(defaultPosition);

  const loadMoreFeedbacks = e => {
    skip = page * 10;
    setSkip(skip);
    page = page + 1;
    setPage(page);
    getFeedbacks(feedbackfilter, true);
  };

  const loadMoreFeedbackUsers = e => {
    skip = page * 10;
    setSkip(skip);
    page = page + 1;
    setPage(page);
    getFeedbackUsers(filter, true);
  };

  const loadMoreRoles = e => {
    skip = page * 10;
    setSkip(skip);
    page = page + 1;
    setPage(page);
    getRoles(roleFilter, true);
  };

  const handleInputChange = e => {
    const { name, value } = e.target;
    setValues({
      ...user,
      [name]: value
    });
  };

  const handleFeedbackInputChange = e => {
    const { name, value } = e.target;
    setNewFeedback({
      ...feedback,
      [name]: value
    });
    if (e.target.name === 'roleId') {
      getFeedbackRoleNames(e.target.value);
    }
  };

  const onFileChange = e => {
    const [file] = e.target.files;
    const reader = new FileReader();

    reader.onload = evt => {
      let emailArray = [];
      const bstr = evt.target.result;
      const wb = XLSX.read(bstr, { type: 'binary' });
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      const data = XLSX.utils.sheet_to_json(ws, { header: 1 });
      console.log(data);
      data.forEach(e => {
        emailArray.push(e[0]);
      });
      feedback.email = emailArray;
      setNewFeedback(feedback);
      console.log(emailArray);
    };
    reader.readAsBinaryString(file);
  };

  const setDefaultQueryParams = () => {
    page = 1;
    setPage(page);
    skip = 0;
    setSkip(skip);
  };

  const handleUserRoleFilterChange = e => {
    // page = 1;
    // setPage(page);
    // skip = 0;
    // setSkip(skip);
    setDefaultQueryParams();
    filter.role = e.target.value;
    setFilterValues(filter);
    getFeedbackUsers(filter, false);
  };

  const handleRoleFilterChange = e => {
    setDefaultQueryParams();
    roleFilter.search = e.target.value;
    setRoleFilterValues(roleFilter);
    getRoles(roleFilter, false);
  };

  const handleUserSearchFilterChange = e => {
    // page = 1;
    // setPage(page);
    // skip = 0;
    // setSkip(skip);
    setDefaultQueryParams();
    filter.search = e.target.value;
    setFilterValues(filter);
    getFeedbackUsers(filter, false);
  };

  // Feedback Filter Change

  const handleFeedbackRoleFilterChange = e => {
    // page = 1;
    // setPage(page);
    // skip = 0;
    // setSkip(skip);
    //setDefaultQueryParams();
    feedbackfilter.role = e.target.value;
    feedbackfilter.roleName = '';
    setFeedbackFilterValues(feedbackfilter);
    getFeedbackRoleNames(feedbackfilter.role);
  };

  const handleFeedbackRoleNameFilterChange = e => {
    // page = 1;
    // setPage(page);
    // skip = 0;
    // setSkip(skip);
    setDefaultQueryParams();
    feedbackfilter.roleName = e.target.value;
    setFeedbackFilterValues(feedbackfilter);
    getFeedbacks(feedbackfilter, false);
  };

  const handleFeedbackSearchFilterChange = e => {
    // page = 1;
    // setPage(page);
    // skip = 0;
    // setSkip(skip);
    setDefaultQueryParams();
    feedbackfilter.search = e.target.value;
    setFeedbackFilterValues(feedbackfilter);
    getFeedbacks(feedbackfilter, false);
  };

  const handleFeedbackDateFilterChange = e => {
    // page = 1;
    // setPage(page);
    // skip = 0;
    // setSkip(skip);
    setDefaultQueryParams();
    handleDateChange(e);
    feedbackfilter.date = new Date(e).toLocaleDateString();
    setFeedbackFilterValues(feedbackfilter);
    getFeedbacks(feedbackfilter, false);
  };

  // Feedback Filter Change End

  var [roles, setRoles] = React.useState([]); //'Head-Coach', 'Coach', 'Player'

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = viewFeedback => {
    console.log('viewfeedback>>', viewFeedback);
    setFeedback(viewFeedback);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [open2, shareFeedbackUserModal] = React.useState(false);

  const openShareFeedbackModal = () => {
    setNewFeedback(defaultFeedback);
    shareFeedbackUserModal(true);
  };

  const closeShareFeedbackModal = () => {
    shareFeedbackUserModal(false);
  };

  const shareFeedbackForm = e => {
    const fieldlist = ['roleId', 'name'];
    FormValidation.validation(fieldlist, feedback).then(validation => {
      if (validation) {
        if (feedback.email && feedback.email.length > 0) {
          shareFeedback(feedback);
        } else {
          Toaster.error(
            <div
              style={{
                textAlign: 'center'
              }}
              className={'ps-2'}
            >
              Please upload a valid file
            </div>,
            'topRight'
          );
        }
      }
    });
  };

  const shareFeedback = feedbackMailDetails => {
    console.log(feedbackMailDetails);
    APIRequest.request(
      'POST',
      ConfigAPIURL.shareFeedbackForm,
      JSON.stringify(feedbackMailDetails)
    ).then(response => {
      closeShareFeedbackModal();
      if (response.code === 100 && response.data.responseCode === 109) {
        Toaster.sucess(
          <div
            style={{
              textAlign: 'center'
            }}
            className={'ps-2'}
          >
            Feedback email has been sent
          </div>,
          'topRight'
        );
      }
    });
  };

  const [open3, feedbackUserModal] = React.useState(false);

  const openAddFeedbackUserModal = () => {
    setValues(feedbackUser);
    feedbackUserModal(true);
  };

  const closeAddFeedbackUserModal = () => {
    feedbackUserModal(false);
  };

  useEffect(() => {
    //setRole(roles[0]);
    getRoles({ showAll: true }, false);
    getFeedbacks({}, false);
  }, []);

  const addFeedbackUser = e => {
    e.preventDefault();
    // let isValid = validateForm();
    // if (isValid) {
    //   createFeedbackUser(user);
    // }
    const fieldlist = ['role', 'name'];
    FormValidation.validation(fieldlist, user).then(validation => {
      if (validation) {
        createFeedbackUser(user);
      }
    });
  };

  const addUserRole = e => {
    e.preventDefault();
    const fieldlist = ['name'];
    FormValidation.validation(fieldlist, position).then(validation => {
      if (validation) {
        createUserRole(position);
      }
    });
  };

  const createUserRole = pos => {
    if (pos._id === '') {
      APIRequest.request(
        'POST',
        ConfigAPIURL.createUserRole,
        JSON.stringify({ name: pos.name })
      ).then(response => {
        closePositionModal();
        if (response.code === 100 && response.data.responseCode === 109) {
          Toaster.sucess(
            <div
              style={{
                textAlign: 'center'
              }}
              className={'ps-2'}
            >
              Position created successfully
            </div>,
            'topRight'
          );
          clearFilters();
          getRoles({}, false);
        }
      });
    } else {
      APIRequest.request(
        'POST',
        ConfigAPIURL.updateUserRole,
        JSON.stringify(pos)
      ).then(response => {
        closePositionModal();
        if (response.code === 100 && response.data.responseCode === 109) {
          Toaster.sucess(
            <div
              style={{
                textAlign: 'center'
              }}
              className={'ps-2'}
            >
              Position updated successfully
            </div>,
            'topRight'
          );
          clearFilters();
          getRoles({}, false);
        }
      });
    }
  };

  const getFeedbackRoleNames = role => {
    APIRequest.request(
      'POST',
      ConfigAPIURL.listRoleNames,
      JSON.stringify({ role: role })
    ).then(response => {
      if (response.code === 100 && response.data.responseCode === 109) {
        roleNames = response.data.roleNames;
        setRoleNames(roleNames);
      }
    });
  };

  const getFeedbacks = (filter = {}, isLoadMore = false) => {
    APIRequest.request(
      'POST',
      ConfigAPIURL.getAllFeedbacks + '?skip=' + skip,
      JSON.stringify({ filter: filter })
    ).then(response => {
      if (response.code === 100 && response.data.responseCode === 109) {
        if (feedbacks.length == 0 || !isLoadMore) {
          feedbacks = response.data.resObject.feedbacks;
        } else {
          feedbacks = [...feedbacks, ...response.data.resObject.feedbacks];
        }
        setFeedbacks(feedbacks);
        totalcount = response.data.resObject.totalCount;
        setTotalcount(totalcount);
      }
    });
  };
  const getFeedbackUsers = (filter = {}, isLoadMore = false) => {
    console.log(filter);
    APIRequest.request(
      'POST',
      ConfigAPIURL.getAllFeedbackUsers + '?skip=' + skip,
      JSON.stringify({ filter: filter })
    ).then(response => {
      if (response.code === 100 && response.data.responseCode === 109) {
        if (feedbackUsers.length == 0 || !isLoadMore) {
          feedbackUsers = response.data.resObject.feedbackUsers;
        } else {
          feedbackUsers = [
            ...feedbackUsers,
            ...response.data.resObject.feedbackUsers
          ];
        }
        setFeedbackUsers(feedbackUsers);
        feedbackUsersTotalcount = response.data.resObject.totalCount;
        setFeedbackUsersTotalcount(feedbackUsersTotalcount);
      }
    });
  };

  const getRoles = (filter = {}, isLoadMore = false) => {
    APIRequest.request(
      'POST',
      ConfigAPIURL.getAllRoles + '?skip=' + skip,
      JSON.stringify({ filter: filter })
    ).then(response => {
      if (response.code === 100 && response.data.responseCode === 109) {
        if (filter.showAll) {
          roles = response.data.resObject.userroles;
          setRoles(roles);
        } else {
          if (userRoles.length == 0 || !isLoadMore) {
            userRoles = response.data.resObject.userroles;
          } else {
            userRoles = [...userRoles, ...response.data.resObject.userroles];
          }
          setUserRoles(userRoles);
          userRolesTotalcount = response.data.resObject.totalCount;
          setUserRolesTotalcount(userRolesTotalcount);
        }
      }
    });
  };

  const createFeedbackUser = user => {
    APIRequest.request(
      'POST',
      ConfigAPIURL.createFeedbackUsers,
      JSON.stringify(user)
    ).then(response => {
      closeAddFeedbackUserModal();
      if (response.code === 100 && response.data.responseCode === 109) {
        Toaster.sucess(
          <div
            style={{
              textAlign: 'center'
            }}
            className={'ps-2'}
          >
            User created successfully
          </div>,
          'topRight'
        );
        clearFilters();
        getFeedbackUsers({}, false);
      }
    });
  };

  const clearFilters = () => {
    setFilterValues(DefaultUserfilter);
    setFeedbackFilterValues(DefaultFeedbackfilter);
    setRoleFilterValues(DefaultRoleFilter);
    handleDateChange(null);
  };

  const handleBasicClick = value => {
    if (value === basicActive) {
      return;
    }

    if (value === 'tab1') {
      clearFilters();
      setDefaultQueryParams();
      getFeedbacks({}, false);
      getRoles({ showAll: true }, false);
    } else if (value === 'tab2') {
      clearFilters();
      setDefaultQueryParams();
      getFeedbackUsers({}, false);
      getRoles({ showAll: true }, false);
    } else {
      clearFilters();
      setDefaultQueryParams();
      getRoles({}, false);
    }

    setBasicActive(value);
  };

  const [selectedDate, handleDateChange] = useState(null);

  const [open5, setOpen5] = React.useState(false);

  const openPositionModal = () => {
    setPosition(defaultPosition);
    setOpen5(true);
  };

  const closePositionModal = () => {
    setOpen5(false);
  };

  const [open6, setOpen6] = React.useState(false);

  const editUserRole = e => {
    setPosition(defaultPosition);
    position.name = e.name;
    position._id = e._id;
    setPosition(position);
    setOpen5(true);
    //setOpen6(true);
  };

  const handleClose6 = () => {
    setOpen6(false);
  };

  const handleUserRoleChange = e => {
    const { name, value } = e.target;
    setPosition({
      ...position,
      [name]: value
    });
  };

  return (
    <div
      className="feedback-page p-0"
    >
      <div className='d-flex align-items-center col-md-4 mb-4'>
              <h5 className='text-black fw-bold'>Feedback</h5>
            </div>
      <MDBTabs className="mb-4 pb-1">
        <MDBTabsItem>
          <MDBTabsLink
            onClick={() => handleBasicClick('tab1')}
            active={basicActive === 'tab1'}
          >
            360 Feedback Submissions
          </MDBTabsLink>
        </MDBTabsItem>
        <MDBTabsItem>
          <MDBTabsLink
            onClick={() => handleBasicClick('tab2')}
            active={basicActive === 'tab2'}
          >
            Feedback Users
          </MDBTabsLink>
        </MDBTabsItem>
        <MDBTabsItem>
          <MDBTabsLink
            onClick={() => handleBasicClick('tab3')}
            active={basicActive === 'tab3'}
          >
            Position
          </MDBTabsLink>
        </MDBTabsItem>
      </MDBTabs>

      <MDBTabsContent>
        <MDBTabsPane show={basicActive === 'tab1'}>
          <div className="row">
            <div className="col-md-3">
              <TextField
                className="drop_down_common mb-3"
                label="Position"
                select
                variant="outlined"
                name="role"
                value={feedbackfilter.role}
                onChange={handleFeedbackRoleFilterChange}
              >
                {roles.map(role => (
                  <MenuItem value={role._id}>{role.name}</MenuItem>
                ))}
              </TextField>
            </div>
            <div className="col-md-3">
              <TextField
                className="drop_down_common mb-3"
                label="Name"
                select
                variant="outlined"
                name="roleName"
                value={feedbackfilter.roleName}
                onChange={handleFeedbackRoleNameFilterChange}
              >
                {roleNames.map(roleName => (
                  <MenuItem value={roleName._id}>{roleName.name}</MenuItem>
                ))}
              </TextField>
            </div>
            <div className="col-md-6 text-end">
              <button
                className="btn btn-primary"
                onClick={openShareFeedbackModal}
              >
                Share
              </button>
            </div>
          </div>

          <div>
            <div>
              <div className="batches_btn w-full pt-3 pb-3">
                <div className="row">
                  <div className="col-md-3">
                    <div className="search">
                      <img src={search} alt="" />
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Search Here"
                        name="search"
                        value={feedbackfilter.search}
                        onChange={handleFeedbackSearchFilterChange}
                      />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <MuiPickersUtilsProvider utils={MomentUtils}>
                      <DatePicker
                        keyboard
                        // orientation="landscape"
                        placeholder="DD/MM/YYYY"
                        format={'DD/MM/YYYY'}
                        // handle clearing outside => pass plain array if you are not controlling value outside
                        mask={value =>
                          value
                            ? [
                                /\d/,
                                /\d/,
                                '/',
                                /\d/,
                                /\d/,
                                '/',
                                /\d/,
                                /\d/,
                                /\d/,
                                /\d/
                              ]
                            : []
                        }
                        label="Date"
                        name="date"
                        value={selectedDate}
                        onChange={handleFeedbackDateFilterChange}
                        className="form-control date"
                        animateYearScrolling={false}
                        autoOk={true}
                        clearable
                      />
                    </MuiPickersUtilsProvider>
                    {/* <KeyboardDatePicker
                      disableToolbar
                      disableFuture={true}
                      format="DD/MM/yyyy"
                      margin="normal"
                      autoOk={true}
                      variant="inline"
                      margin="normal"
                      label="Start Date"
                      name="date"
                      value={feedbackfilter.date}
                      onChange={handleFeedbackDateFilterChange}
                      KeyboardButtonProps={{
                        'aria-label': 'change date'
                      }}
                    /> */}
                  </div>
                  <div className="col-md-6 text-end">
                    <div className="align-right">
                      {/* <Tooltip title="Export Users">
                        <IconButton>
                          <CloudDownloadIcon />
                        </IconButton>
                      </Tooltip> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Date</TableCell>
                    <TableCell>Name</TableCell>
                    <TableCell>Email</TableCell>
                    <TableCell>Position</TableCell>
                    <TableCell>Feedback For</TableCell>
                    <TableCell>Average Score</TableCell>
                    <TableCell>Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {feedbacks &&
                    feedbacks.map(feedback => (
                      <TableRow
                        sx={{
                          '&:last-child td, &:last-child th': { border: 0 }
                        }}
                      >
                        <TableCell>{ moment(feedback.createdAtUnixTime * 1000).format("DD-MM-YYYY")}</TableCell>
                        <TableCell>{feedback.name}</TableCell>
                        <TableCell>{feedback.email}</TableCell>
                        <TableCell>{feedback.role.name}</TableCell>
                        <TableCell>{feedback.roleName.name}</TableCell>
                        <TableCell>{feedback.avgScore}</TableCell>

                        <TableCell>
                          <button
                            className="transparent"
                            onClick={() => handleClickOpen(feedback)}
                          >
                            <VisibilityIcon />
                          </button>
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
              {feedbacks.length === 0 ? (
                <h5 className="p-2">No results found.</h5>
              ) : (
                ''
              )}
              <div className="col-lg-12 mt-4 text-center">
                {feedbacks.length > 0 && feedbacks.length < totalcount && (
                  <button
                    className="btn btn-primary m-auto"
                    onClick={loadMoreFeedbacks}
                  >
                    Load More
                  </button>
                )}
              </div>
            </TableContainer>

            <div>
            {viewFeedback && <Dialog open={open} onClose={handleClose} maxWidth="lg">
                <DialogTitle className="fw-700 ">
                  360 Feedback Questionnaire for 
                  {viewFeedback.role.name} -
                  {viewFeedback.roleName.name}
                </DialogTitle>
                <DialogContent>
                  <DialogContentText className="feedback-popupBody">
                    <div className="quest-row">
                      <h6 className="text-black">Player Relationship </h6>
                      <p className="grey mb-0 pb-0">
                        The mentor values the players' input with regard to
                        training decisions
                      </p>
                      <p className="mt-1 pt-1">{viewFeedback.answer1}</p>
                      <p className="grey mb-0 pb-0">
                        The mentor is friendly and approachable{' '}
                      </p>
                      <p className="mt-1 pt-1">{viewFeedback.answer2}</p>
                    </div>

                    <div className="quest-row">
                      <h6 className="text-black">Communication </h6>
                      <p className="grey mb-0 pb-0">
                        The mentor makes a regular effort to speak to each
                        player on a one-to-one basis{' '}
                      </p>
                      <p className="mt-1 pt-1">{viewFeedback.answer3}</p>
                      <p className="grey mb-0 pb-0">
                        The mentor listens to the players when they have
                        something to say{' '}
                      </p>
                      <p className="mt-1 pt-1">{viewFeedback.answer4}</p>
                      <p className="grey mb-0 pb-0">
                        The mentor provides players with valuable feedback about
                        how they are performing{' '}
                      </p>
                      <p className="mt-1 pt-1">{viewFeedback.answer5}</p>
                    </div>

                    <div className="quest-row">
                      <h6 className="text-black">Development </h6>
                      <p className="grey mb-0 pb-0">
                        The mentor recognises what each player needs to do to
                        develop as an player{' '}
                      </p>
                      <p className="mt-1 pt-1">{viewFeedback.answer6}</p>
                      <p className="grey mb-0 pb-0">
                        The mentor shows an interest in I' personal development
                        outside of the game{' '}
                      </p>
                      <p className="mt-1 pt-1">{viewFeedback.answer7}</p>
                      <p className="grey mb-0 pb-0">
                        As the player develops, the mentor develops and adapts
                        to their coaching accordingly{' '}
                      </p>
                      <p className="mt-1 pt-1">{viewFeedback.answer8}</p>
                    </div>

                    <div className="quest-row">
                      <h6 className="text-black">Enjoyment </h6>
                      <p className="grey mb-0 pb-0">
                        The mentor helps to create a good social environment
                        within the training group{' '}
                      </p>
                      <p className="mt-1 pt-1">{viewFeedback.answer9}</p>
                      <p className="grey mb-0 pb-0">
                        The mentor is motivated and happy to be at training{' '}
                      </p>
                      <p className="mt-1 pt-1">{viewFeedback.answer10}</p>
                      <p className="grey mb-0 pb-0">
                        The mentor includes a lot of variety in training{' '}
                      </p>
                      <p className="mt-1 pt-1">{viewFeedback.answer11}</p>
                    </div>

                    <div className="quest-row">
                      <h6 className="text-black">Improvement </h6>
                      <p className="grey mb-0 pb-0">
                        The mentor encourages players' to set goals{' '}
                      </p>
                      <p className="mt-1 pt-1">{viewFeedback.answer12}</p>
                      <p className="grey mb-0 pb-0">
                        The mentor evaluates how each player is progressing in
                        training and competition{' '}
                      </p>
                      <p className="mt-1 pt-1">{viewFeedback.answer13}</p>
                      <p className="grey mb-0 pb-0">
                        I have noticed progression in the players' ability since
                        they have been with the mentor{' '}
                      </p>
                      <p className="mt-1 pt-1">{viewFeedback.answer14}</p>
                      <p className="grey mb-0 pb-0">
                        The mentor knows what works for each player to perform
                        well and individualises their training accordingly{' '}
                      </p>
                      <p className="mt-1 pt-1">{viewFeedback.answer15}</p>
                    </div>

                    <div className="quest-row">
                      <h6 className="text-black">Knowledge </h6>
                      <p className="grey mb-0 pb-0">
                        The mentor has a good knowledge of the game and the
                        event they are coaching{' '}
                      </p>
                      <p className="mt-1 pt-1">{viewFeedback.answer16}</p>
                      <p className="grey mb-0 pb-0">
                        The coach is up to date with the tactical and technical
                        aspects of the event{' '}
                      </p>
                      <p className="mt-1 pt-1">{viewFeedback.answer17}</p>
                    </div>

                    <div className="quest-row">
                      <h6 className="text-black">Organisation </h6>
                      <p className="grey mb-0 pb-0">
                        The mentor is on time to practice sessions and
                        competition{' '}
                      </p>
                      <p className="mt-1 pt-1">{viewFeedback.answer18}</p>
                      <p className="grey mb-0 pb-0">
                        Each prctice session is well prepared and set out by the
                        mentor
                      </p>
                      <p className="mt-1 pt-1">{viewFeedback.answer19}</p>
                      <p className="grey mb-0 pb-0">
                        The mentor plans ahead for key events/ periods
                        throughout the season{' '}
                      </p>
                      <p className="mt-1 pt-1">{viewFeedback.answer20}</p>
                    </div>

                    <div className="quest-row">
                      <h6 className="text-black">Role Modelling/Mentoring</h6>
                      <p className="grey mb-0 pb-0">
                        The mentor is a good role model for the players and
                        other coaches{' '}
                      </p>
                      <p className="mt-1 pt-1">{viewFeedback.answer21}</p>
                      <p className="grey mb-0 pb-0">
                        The mentor shows respect for others and promotes good
                        sportsmanship{' '}
                      </p>
                      <p className="mt-1 pt-1">{viewFeedback.answer22}</p>
                      <p className="grey mb-0 pb-0">
                        The mentor shows respect for others and promotes good
                        sportsmanship{' '}
                      </p>
                      <p className="mt-1 pt-1">{viewFeedback.answer23}</p>
                      <p className="grey mb-0 pb-0">
                        The mentor asks other coaches for advice regarding
                        coaching matters if he/she needs assistance{' '}
                      </p>
                      <p className="mt-1 pt-1">{viewFeedback.answer24}</p>
                    </div>

                    <div className="quest-row">
                      <h6 className="text-black">Teaching & Learning</h6>
                      <p className="grey mb-0 pb-0">
                        The mentor uses encouragement rather than criticism when
                        correcting technique/skills{' '}
                      </p>
                      <p className="mt-1 pt-1">{viewFeedback.answer25}</p>
                      <p className="grey mb-0 pb-0">
                        The mentor teaches players about the
                        event/training/skills, rather than just telling them
                        what to do{' '}
                      </p>
                      <p className="mt-1 pt-1">{viewFeedback.answer26}</p>
                      <p className="grey mb-0 pb-0">
                        The coach knows the answers to the players' game-related
                        questions, or will find out the answer and get back{' '}
                      </p>
                      <p className="mt-1 pt-1">{viewFeedback.answer27}</p>
                    </div>

                    <div className="quest-row">
                      <h6 className="text-black">Monitoring Performance</h6>
                      <p className="grey mb-0 pb-0">
                        The mentor encourages players' to think about/reflect on
                        their training and progess{' '}
                      </p>
                      <p className="mt-1 pt-1">{viewFeedback.answer28}</p>
                      <p className="grey mb-0 pb-0">
                        The mentor reflects on his/her own coaching performance
                        and ways to continually improve{' '}
                      </p>
                      <p className="mt-1 pt-1">{viewFeedback.answer29}</p>
                      <p className="grey mb-0 pb-0">
                        The mentor recognises players' efforts and goal
                        achievements{' '}
                      </p>
                      <p className="mt-1 pt-1">{viewFeedback.answer30}</p>
                      <p className="grey mb-0 pb-0">
                        The mentor's main focus is players' longterm development
                        & performance outcomes in competition{' '}
                      </p>
                      <p className="mt-1 pt-1">{viewFeedback.answer31}</p>
                    </div>

                    <div className="quest-row">
                      <h6 className="text-black">
                        List the mentor's three most effective strengths
                      </h6>
                      <p className="mt-1 pt-1">{viewFeedback.answer32}</p>
                      <p className="mt-1 pt-1">{viewFeedback.answer33}</p>
                      <p className="mt-1 pt-1">{viewFeedback.answer34}</p>
                    </div>

                    <div className="quest-row">
                      <h6 className="text-black">
                        Are there any areas of the mentor's performance you feel
                        need further development?
                      </h6>
                      <p className="mt-1 pt-1">{viewFeedback.answer35}</p>
                      <p className="mt-1 pt-1">{viewFeedback.answer36}</p>
                      <p className="mt-1 pt-1">{viewFeedback.answer37}</p>
                    </div>
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <div className="col-12 text-right">
                    <button onClick={handleClose} className="btn btn-primary">
                      Close
                    </button>
                  </div>
                </DialogActions>
              </Dialog>}

              <Dialog open={open2} onClose={closeShareFeedbackModal}>
                <DialogTitle className="fw-700 ">Share</DialogTitle>
                <DialogContent>
                  <DialogContentText>
                    <TextField
                      className="drop_down_common mb-3 mt-4"
                      label="Role"
                      id="roleId"
                      select
                      variant="outlined"
                      name="roleId"
                      value={feedback.roleId}
                      onChange={handleFeedbackInputChange}
                    >
                      {roles.map(role => (
                        <MenuItem value={role._id}>{role.name}</MenuItem>
                      ))}
                    </TextField>
                    <TextField
                      className="drop_down_common mb-3"
                      label="Name"
                      select
                      variant="outlined"
                      name="name"
                      id="name"
                      value={feedback.name}
                      onChange={handleFeedbackInputChange}
                    >
                      {roleNames.map(roleName => (
                        <MenuItem value={roleName.name}>
                          {roleName.name}
                        </MenuItem>
                      ))}
                    </TextField>
                    <div className="batches_drag_drop mt-2">
                      <input
                        type="file"
                        name="file"
                        id="file"
                        className="form-control mt-2"
                        onChange={onFileChange}
                      />
                    </div>
                    <div className="mt-2">
                      <a
                        href={sampleFeedbackImport}
                        download="Share_360_Feedback.xlsx"
                        className="d-inline-block"
                      >
                        Download Sample Import
                      </a>
                    </div>
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <button
                    onClick={closeShareFeedbackModal}
                    className="btn btn-secondary shadow-0"
                  >
                    Cancel
                  </button>
                  <button
                    onClick={shareFeedbackForm}
                    className="btn btn-primary shadow-0"
                  >
                    Submit
                  </button>
                </DialogActions>
              </Dialog>
            </div>
          </div>
        </MDBTabsPane>
        <MDBTabsPane show={basicActive === 'tab2'}>
          <div className="row">
            <div className="col-md-3">
              <TextField
                className="drop_down_common mb-3"
                label="Position"
                select
                name="role"
                variant="outlined"
                value={filter.role}
                onChange={handleUserRoleFilterChange}
              >
                {roles.map(role => (
                  <MenuItem value={role._id}>{role.name}</MenuItem>
                ))}
              </TextField>
            </div>
            {/* <div className="col-md-3">
              <TextField
                className="drop_down_common mb-3"
                label="Name"
                select
                variant="outlined"
              ></TextField>
            </div> */}
            <div className="col-md-9 text-end">
              <button
                className="btn btn-primary"
                onClick={openAddFeedbackUserModal}
              >
                ADD
              </button>
            </div>
          </div>

          <div>
            <div>
              <div className="batches_btn w-full">
                <div className="row">
                  <div className="col-md-3">
                    <div className="search">
                      <img src={search} alt="" />
                      <input
                        type="text"
                        name="search"
                        id=""
                        className="form-control"
                        placeholder="Search Here"
                        value={filter.search}
                        onChange={handleUserSearchFilterChange}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell>Role</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {feedbackUsers &&
                    feedbackUsers.map(feedbackUser => (
                      <TableRow
                        sx={{
                          '&:last-child td, &:last-child th': { border: 0 }
                        }}
                      >
                        <TableCell>{feedbackUser.name}</TableCell>
                        <TableCell>{feedbackUser.userRole[0]?.name}</TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
              {feedbackUsers.length === 0 ? <h4>No results found.</h4> : ''}
              <div className="col-lg-12 mt-4 text-center">
                {feedbackUsers.length > 0 &&
                  feedbackUsers.length < feedbackUsersTotalcount && (
                    <button
                      className="btn btn-primary m-auto"
                      onClick={loadMoreFeedbackUsers}
                    >
                      Load More
                    </button>
                  )}
              </div>
            </TableContainer>
            <div>
              <Dialog open={open3} onClose={closeAddFeedbackUserModal} maxWidth="xs">
                <DialogTitle className="fw-700 ">Add User</DialogTitle>
                <DialogContent>
                  <DialogContentText>
                    <TextField
                      className="drop_down_common mb-3 mt-4"
                      label="Role"
                      variant="outlined"
                      select
                      value={user.role}
                      onChange={handleInputChange}
                      name="role"
                      id="role"
                    >
                      {roles.map(role => (
                        <MenuItem value={role._id}>{role.name}</MenuItem>
                      ))}
                    </TextField>
                    {/* {userError.roleError}
                    {userError.roleError !== '' ? (
                      <div style={{ color: 'red' }}>{userError.roleError}</div>
                    ) : (
                      ''
                    )} */}
                    {/* {Object.keys(userError).map(key => {
                      return (
                        <div style={{ color: 'red' }}>{userError[key]}</div>
                      );
                    })} */}
                    {/* <label className="mt-4 fw-600">Role</label>
                    <input
                      type="text"
                      className="form-control"
                      value={user.role}
                      onChange={handleInputChange}
                      name="role"
                    /> */}
                    <label className="fw-600">Name</label>
                    <input
                      type="text"
                      className="form-control"
                      value={user.name}
                      onChange={handleInputChange}
                      name="name"
                      id="name"
                    />
                    {/* <TextField
                      className="drop_down_common mb-3"
                      label="Name"
                      variant="outlined"
                      value={user.name}
                      onChange={handleInputChange}
                      name="name"
                    ></TextField> */}
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <div className="col-12 text-end mb-2">
                  <Button
                  className='btn btn-secondary shadow-0'
                    variant="secondary"
                    onClick={closeAddFeedbackUserModal}
                  >
                    Cancel
                  </Button>
                  <Button onClick={addFeedbackUser} className='btn btn-primary shadow-0 ms-2' variant="primary">
                    Submit
                  </Button>
                  </div>
                </DialogActions>
              </Dialog>
            </div>
          </div>
        </MDBTabsPane>
        <MDBTabsPane show={basicActive === 'tab3'}>
          <div className="row">
            <div className="col-md-3">
              {/* <TextField
                className="drop_down_common mb-3"
                label="Position"
                select
                name="role"
                variant="outlined"
              >
                <MenuItem></MenuItem>
              </TextField> */}
            </div>
            <div className="col-md-9 text-end">
              <button className="btn btn-primary mb-3" onClick={openPositionModal}>
                ADD
              </button>
            </div>
          </div>

          <div>
            <div>
              <div className="batches_btn w-full">
                <div className="row">
                  <div className="col-md-3">
                    <div className="search">
                      <img src={search} alt="" />
                      <input
                        type="text"
                        name="search"
                        id="search"
                        className="form-control"
                        placeholder="Search Here"
                        value={roleFilter.search}
                        onChange={handleRoleFilterChange}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Position Name</TableCell>
                    <TableCell>Edit</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {userRoles &&
                    userRoles.map(userRole => (
                      <TableRow
                        sx={{
                          '&:last-child td, &:last-child th': { border: 0 }
                        }}
                      >
                        <TableCell>{userRole.name}</TableCell>
                        <TableCell>
                          <button
                            className="transparent text-black"
                            onClick={() => editUserRole(userRole)}
                          >
                            <EditIcon />
                          </button>
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
              {userRoles.length === 0 ? <h4>No results found.</h4> : ''}
              <div className="col-lg-12 mt-4 text-center">
                {userRoles.length > 0 &&
                  userRoles.length < userRolesTotalcount && (
                    <button
                      className="btn btn-primary m-auto"
                      onClick={loadMoreRoles}
                    >
                      Load More
                    </button>
                  )}
              </div>
            </TableContainer>
            <div>
              <Dialog open={open5} onClose={closePositionModal}>
                <DialogTitle className="fw-700 ">
                  {position._id !== '' ? 'Update' : 'Add'} Position
                </DialogTitle>
                <DialogContent>
                  <DialogContentText>
                    <label className="fw-600">Position</label>
                    <input
                      type="input"
                      className="form-control"
                      name="name"
                      id="name"
                      value={position.name}
                      onChange={handleUserRoleChange}
                    />
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                <div className="col-12 text-end mb-2">
                  <Button className='btn btn-secondary shadow-0' variant="secondary" onClick={closePositionModal}>
                    Cancel
                  </Button>
                  <Button className='btn btn-primary shadow-0 ms-2' onClick={addUserRole} variant="primary">
                    Submit
                  </Button>
                  </div>
                </DialogActions>
              </Dialog>
              {/* <Dialog open={open6} onClose={handleClose6}>
                <DialogTitle className="fw-700 ">Edit Position</DialogTitle>
                <DialogContent>
                  <DialogContentText>
                    <TextField
                      className="drop_down_common mb-3 mt-4 w-100"
                      label="Edit Position Name"
                      variant="outlined"
                      text
                      name="role"
                      id="role"
                    >
                      <MenuItem></MenuItem>
                    </TextField>
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button variant="secondary" onClick={handleClose6}>
                    Cancel
                  </Button>
                  <Button variant="primary">Submit</Button>
                </DialogActions>
              </Dialog> */}
            </div>
          </div>
        </MDBTabsPane>
      </MDBTabsContent>
    </div>
  );
}

export default Feedback;
