import React, { Component } from 'react';
import { Route, Redirect, withRouter } from 'react-router-dom';
import ConfigAPIURL from '../config/ConfigAPIURL';
import APIRequest from '../utils/APIRequest';
import LocalStorage from '../config/LocalStorage';

import Toaster from '../utils/Toaster';
class AuthNewGuard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            haveAcces: false,
            loaded: false
        };
    }
    UNSAFE_componentWillReceiveProps = () => {
        this.checkAcces();
    };
    UNSAFE_componentWillMount = () => {
        this.checkAcces();
    };

    checkAcces = () => {
        const { history } = this.props;
        let email = localStorage.getItem('userEmail');
        APIRequest.request('POST', ConfigAPIURL.sessionTraineeValidation, JSON.stringify({
                email: email
            }))
            .then(response => {
                if (
                    response.code === 100 &&
                    response.data !== undefined &&
                    response.data.responseCode === 109
                ) {
                    if (response.data.user !== undefined && response.data.user !== null) {

                        LocalStorage.userId = response.data.user._id;
                        LocalStorage.userName = response.data.user.name;
                        LocalStorage.tier = response.data.user.tier;
                        LocalStorage.userEmail = response.data.user.email;
                        LocalStorage.userMobile = response.data.user.mobileName;
                        LocalStorage.role = response.data.user.userType;
                        LocalStorage.avatar = response.data.user.avatar;
                        LocalStorage.notificationCount = response.data.user.notificationCount;
                        localStorage.setItem('role', response.data.user.userType);
                    }
                    if (
                        response.data.user.userType === 'trainee' &&
                        (history.location.pathname.startsWith('/app') || history.location.pathname.startsWith('/course')) &&
                        (!response.data.user.name ||
                            !response.data.user.email ||
                            !response.data.user.mobileNo ||
                            !response.data.user.state ||
                            !response.data.user.city ||
                            !response.data.user.gender ||
                            !response.data.user.dob ||
                            !response.data.user.batchName ||
                            !response.data.user.course ||
                            !response.data.user.ageProof)
                    ) {
                        this.setState({
                            haveAcces: true,
                            loaded: true
                        });
                        Toaster.error(
                            'Please complete your profile information.',
                            'topRight'
                        )
                    } else if (
                        response.data.user.userType === 'trainee' &&
                        (history.location.pathname.startsWith('/app') || history.location.pathname.startsWith('/course'))
                    ) {
                        this.setState({
                            haveAcces: true,
                            loaded: true
                        });
                    } else if (
                        response.data.user.userType === 'faculty' &&
                        (history.location.pathname.startsWith('/faculty') || history.location.pathname.startsWith('/app'))
                    ) {
                        this.setState({
                            haveAcces: true,
                            loaded: true
                        });
                    } else {
                        history.push('/login');
                    }
                } else if (
                    response.code === 100 &&
                    response.data !== undefined &&
                    response.data.responseCode === 110 &&
                    !history.location.pathname.includes('/user/purchase')
                ) {
                    if (response.data.user !== undefined && response.data.user !== null) {
                        LocalStorage.userName = response.data.user.name;
                        LocalStorage.userEmail = response.data.user.email;
                        LocalStorage.userMobile = response.data.user.mobileName;
                        LocalStorage.userType = response.data.userType;
                        LocalStorage.userProfileImage = response.data.user.profileImage;
                    }
                    history.push('/user/purchase');
                } else if (
                    response.code === 100 &&
                    response.data !== undefined &&
                    response.data.responseCode === 108
                ) {
                    LocalStorage.userName = '';
                    LocalStorage.userEmail = '';
                    LocalStorage.userMobile = '';
                    LocalStorage.userType = '';
                    LocalStorage.userProfileImage = '';
                    LocalStorage.notificationCount = 0;
                    history.push('/login');
                } else {
                    history.push('/login');
                }
            })
            .catch(() => {
                history.push('/');
            });
    };

    render() {
        const { component: Component, ...rest } = this.props;
        const { loaded, haveAcces } = this.state;
        if (!loaded) return null;
        return ( <
            Route {...rest }
            render = {
                props => {
                    return haveAcces === true ? ( <
                        Component {...props }
                        />
                    ) : ( <
                        Redirect to = "/login" / >
                    );
                }
            }
            />
        );
    }
}

export default withRouter(AuthNewGuard);