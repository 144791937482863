import React, { useState } from 'react';
import download from '../../assets/images/downloadicon.png';
import ArticleIcon from '@mui/icons-material/Article';
import PermMediaIcon from '@mui/icons-material/PermMedia';
import { useLocation, useParams, useHistory } from 'react-router-dom';
import { useEffect } from 'react';
import APIRequest from '../utils/APIRequest';
import ConfigAPIURL from '../config/ConfigAPIURL';
import AWS from 'aws-sdk';
import LocalStorage from 'src/admin/config/LocalStorage';
import Toaster from 'src/admin/utils/Toaster';
import doc from '../../assets/images/Doc.png';
import moment from 'moment';
import parse from 'html-react-parser';

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

export default function ViewAssignment() {
  const history = useHistory();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const userid = params.get('userid');
  var [details, setDetails] = useState();
  var [aws, setAws] = useState(LocalStorage.aws);
  var [incoursedetails, setIncourseDetails] = useState([]);
  var [incatscore, setIncatScore] = useState([]);
  var [incatid, setIncatId] = useState([]);
  var [insum, setInSum] = useState(0);
  var [postcoursedetails, setPostcourseDetails] = useState([]);
  var [postcatscore, setPostcatScore] = useState([]);
  var [postcatid, setPostcatId] = useState([]);
  var [postsum, setPostSum] = useState(0);
  const [show, setSubmitConfirm] = useState(false);
  const handleCloseConfirm = () => setSubmitConfirm(false);
  const handleIsConfirmSubmit = () => {
    setSubmitConfirm(true)
  }

  useEffect(() => {
    getAssignmentDetail();
  }, []);

  const getAssignmentDetail = () => {
    APIRequest.request(
      'GET',
      ConfigAPIURL.getadminassignmentdetail + userid,
      ''
    ).then(response => {
      details = response.data.asignmentUser;
      setDetails(details);
      getIncourseCategory()
    });
   
  };
  const handlePostScoreChange = (e, index) => {
    postcatscore[index] = e.target.value;
    postcatid[index] = e.target.name;
    const postsum = postcatscore
      .map(function(elt) {
        // assure the value can be converted into an integer
        return /^\d+$/.test(elt) ? parseInt(elt) : 0;
      })
      .reduce(function(a, b) {
        // sum all resulting numbers
        return a + b;
      });
    setPostSum(postsum);
  };

  const handleInScoreChange = (e, index) => {
    incatscore[index] = e.target.value;
    incatid[index] = e.target.name;
    const insum = incatscore
      .map(function(elt) {
        // assure the value can be converted into an integer
        return /^\d+$/.test(elt) ? parseInt(elt) : 0;
      })
      .reduce(function(a, b) {
        // sum all resulting numbers
        return a + b;
      });
    setInSum(insum);
  };

  const handleDownload = url => {
    window.location.href = url
    Toaster.sucess('Your file will be downloaded soon.', 'topRight');
    AWS.config.update({
      credentials: new AWS.CognitoIdentityCredentials({
        IdentityPoolId: aws.CognitoIdentityCredentials.IdentityPoolId
      }),
      region: aws.CognitoIdentityCredentials.region
    });
    let extractUrl = url.split('/');
    console.log('extractUrl', extractUrl);
    var s3 = new AWS.S3({ signatureVersion: 'v4' });
    let originalUrl = extractUrl[extractUrl.length - 1];
    // var extention = originalUrl.split('.');
    // console.log(extention.length);
    // if(extention.length > 1){
    //   originalUrl = extention[0]
    // } else {
      
    // }
    let bucket = aws.bucket.split('/');
    const params = {
      Bucket: bucket[0] + '/'+ extractUrl[extractUrl.length -2],
      Key: originalUrl
    };
    function downloadBlob(blob, name = `${originalUrl}`) {
      // Convert your blob into a Blob URL (a special url that points to an object in the browser's memory)
      const blobUrl = URL.createObjectURL(blob);
      // Create a link element
      const link = document.createElement('a');
      // Set link's href to point to the Blob URL
      link.href = blobUrl;
      link.download = name;
      // Append link to the body
      document.body.appendChild(link);
      // Dispatch click event on the link
      // This is necessary as link.click() does not work on the latest firefox
      link.dispatchEvent(
        new MouseEvent('click', {
          bubbles: true,
          cancelable: true,
          view: window
        })
      );

      // Remove link from body
      document.body.removeChild(link);
    }
    let template = extractUrl[extractUrl.length - 1];
    s3.getObject(params, (err, data) => {
      if (err) {
        console.log('error in fetching', err, err.stack);
      } else {
        let blob = new Blob([data.Body], { type: data.ContentType });
        let link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = template;
        link.click();
      }
    });
  };
 
  const getFileName = fileName => {
    let tempFileName = fileName.split('/');

    // tempFileName = tempFileName[tempFileName.length -1].split('_');
    return tempFileName[tempFileName.length - 1];
  };
  const onReSubmit = e => {
    // e.preventDefault()
    //const isValid = formValidation()
      const data = {
        status: 'resubmit',
        answer: '',
      };

      if (details.status == 'completed') {
        APIRequest.request(
          'POST',
          ConfigAPIURL.adminresubmitassignment + userid,
          JSON.stringify(data)
        ).then(response => {
          if (response.code === 100 && response.data.responseCode === 109) {
            Toaster.sucess('Assignment request for resubmit placed  successfully.', 'topRight');
            history.push('/admin/assignment-manage?assigmentid=' + details.assignment._id);
          } else {
            Toaster.error('Unable to resubmit', 'topRight');
            // history.push('/admin/assignment-manage?assigmentid=' + details.assignment._id);
          }
        });
      } else {
        if (details.status != 'completed') {
          Toaster.error(
            'Only completed assignments can be rejected',
            'topRight'
          );
          history.push('/admin/assignment-manage?assigmentid=' + details.assignment._id);
         // /admin/assignment-manage?assigmentid=62ff5d0550e23105143e2bc0
        }
      }
    
  };

  const onReject = e => {
    // e.preventDefault()
    //const isValid = formValidation()
    var valid = false;
    for (let i = 0; i < incatscore.length; i++) {
      if (incatscore[i] == '') {
        valid = false;
      } else {
        valid = true;
      }
    }
    for (let j = 0; j < postcatscore.length; j++) {
      if (postcatscore[j] == '') {
        valid = false;
      } else {
        valid = true;
      }
    }
    if (valid == true) {
      let pre = incatscore.map((x, i) => {
        return { inCourseCategory: incatid[i], categoryScore: x };
      });
      let post = postcatscore.map((x, i) => {
        return { postCourseCategory: postcatid[i], categoryScore: x };
      });
      const data = {
        preCourseScore: pre,
        postCourseScore: post,
        status: 'reject'
      };

      if (details.status == 'completed') {
        APIRequest.request(
          'POST',
          ConfigAPIURL.adminsubmitscore + userid,
          JSON.stringify(data)
        ).then(response => {
          if (response.code === 100 && response.data.responseCode === 109) {
            Toaster.sucess('Assignment rejected successfully.', 'topRight');
            history.push('/admin/assignment-manage?assigmentid=' + details.assignment._id);
          } else {
            Toaster.error('Unable to reject', 'topRight');
            history.push('/admin/assignment-manage?assigmentid=' + details.assignment._id);
          }
        });
      } else {
        if (details.status != 'completed') {
          Toaster.error(
            'Only completed assignments can be rejected',
            'topRight'
          );
          history.push('/admin/assignment-manage?assigmentid=' + details.assignment._id);
         // /admin/assignment-manage?assigmentid=62ff5d0550e23105143e2bc0
        }
      }
    } else {
      Toaster.error('Please fill all the fields', 'topRight');
    }
  };

  const getIncourseCategory = () => {
    const data = { course: details && details.course };
    APIRequest.request(
      'POST',
      ConfigAPIURL.getadminincategory,
      JSON.stringify(data)
    ).then(response => {
      if (response.code === 100 && response.data.responseCode === 109) {
        incoursedetails = response.data.inCourseCategories;
        setIncourseDetails(incoursedetails);
        for (let i = 0; i < incoursedetails.length; i++) {
          incatscore.push('');
          incatid.push('');
          setIncatScore(incatscore);
          setIncatId(incatid);
          incatid[i] = incoursedetails[i]._id;
        }
        getPostcourseCategory();
      }
    });
  };
  const getPostcourseCategory = () => {
    const data = { course: details && details.course };
    APIRequest.request(
      'POST',
      ConfigAPIURL.getadminpostcategory,
      JSON.stringify(data)
    ).then(response => {
      if (response.code === 100 && response.data.responseCode === 109) {
        postcoursedetails = response.data.postCourseCategories;
        setPostcourseDetails(postcoursedetails);
        let a = [];
        for (let i = 0; i < postcoursedetails.length; i++) {
          a.push('');
          setPostcatScore(a);
          setPostcatId(postcatid);
          postcatid[i] = postcoursedetails[i]._id;
        }
      }
    });
  };
  const onApprove = e => {
    var valid = false;
    for (let i = 0; i < incatscore.length; i++) {
      if (incatscore[i] == '') {
        valid = false;
      } else {
        valid = true;
      }
    }
    for (let j = 0; j < postcatscore.length; j++) {
      if (postcatscore[j] == '') {
        valid = false;
      } else {
        valid = true;
      }
    }
    if (valid == true) {
      let pre = incatscore.map((x, i) => {
        return { inCourseCategory: incatid[i], categoryScore: x };
      });
      let post = postcatscore.map((x, i) => {
        return { postCourseCategory: postcatid[i], categoryScore: x };
      });
      const data = {
        preCourseScore: pre,
        postCourseScore: post,
        status: 'approve'
      };

      if (details.status == 'completed') {
        APIRequest.request(
          'POST',
          ConfigAPIURL.adminsubmitscore + userid,
          JSON.stringify(data)
        ).then(response => {
          if (response.code === 100 && response.data.responseCode === 109) {
            Toaster.sucess('Score submitted successfully.', 'topRight');
            history.push('/admin/assignment-manage?assigmentid=' + details.assignment._id);
          } else {
            Toaster.error('Unable to submit', 'topRight');
            history.push('/admin/assignment-manage?assigmentid=' + details.assignment._id);
          }
        });
      } else {
        if (details.status != 'completed') {
          Toaster.error(
            'Scores can only be submitted to completed assignments',
            'topRight'
          );
          history.push('/admin/assignment-manage?assigmentid=' + details.assignment._id);
        }
      }
    } else {
      Toaster.error('Please fill all the fields', 'topRight');
    }
  };

 const onCancel = () =>{
    history.push('/admin/assignment-manage?assigmentid=' + details.assignment._id);
  }

  return (
    <div className="view-assignment p-4">
      <h5 className="fw-700">{details && details.assignment.title}</h5>

      <div className="bg-white">
        <div className="row mt-4 p-4">
          <div className="col-md-2">
            <label className="fw-600 mb-0">Name</label>
            <p className="grey">{details && details.user.name}</p>
          </div>
          <div className="col-md-4">
            <label className="fw-600 mb-0">Email</label>
            <p className="grey">{details && details.user.email}</p>
          </div>
          <div className="col-md-3">
            <label className="fw-600 mb-0">Course</label>
            <p className="grey">
              {details && details.course && details.course.title}
            </p>
          </div>
          <div className="col-md-3">
            <label className="fw-600 mb-0">Batch</label>
            <p className="grey">{details && details.user.batchName}</p>
          </div>
          <div className="col-md-2">
            <label className="fw-600 mb-0">Submitted Date</label>
            <p className="grey">
              {details &&
                details.submissionDate != 0 &&
                moment(details.submissionDate * 1000).format('DD-MM-YYYY')}
            </p>
          </div>
          <div className="col-md-4">
            <label className="fw-600 mb-0">Status</label>
            {/* <p className="grey text-capitalize">{details && details.status}</p> */}

           <div>
           {(details && details.status == 'pending') ?
                      <span className='badge-brown'>Pending</span>
                      : ''}
                      {(details && details.status == 'resubmit') ?
                      <span className='badge-brown'>Resubmit</span>
                      : ''}
                    {(details && details.status == 'completed') ?
                      <span className='badge-green'>Completed</span>
                      : ''}
                    {(details && details.status == 'approve') ?
                      <span className='badge-blue'>Approved</span>
                      : ''}
                    {(details && details.status == 'reject') ?
                      <span className='badge-red'>Reject</span>
                      : ''}
           </div>
                      
          </div>
          <div className="col-md-3">
            <label className="fw-600 mb-0">Evaluated By</label>
            <p className="grey text-capitalize">
              {details && details.updatedBy && details.updatedBy.name}
            </p>
          </div>
          <div className="col-md-3">
            <label className="fw-600 mb-0">Last Updated</label>
            <p className="grey text-capitalize">
              {details &&
                details.updatedAt != 0 &&
                moment(details.updatedAt * 1000).format('DD-MM-YYYY h:mm a')}
            </p>
          </div>
        </div>
      </div>

      <div className="bg-white">
        <div className="row mt-4 p-4">
          <div className="col-sm-12">
            <label className="fw-600 mb-0">Submitted Answer</label>
            {details && details.answer  && <div className='rt-content' dangerouslySetInnerHTML={{ __html:parse(details.answer)}}></div>}
          </div>
          {details && details.status !='resubmit' &&
            details.files.map(item => (
              <div className="col-md-12">
                <div className="lesson-download mt-3" title={getFileName(item.fileName)}>
                  <img src={doc} alt="" className="lesson-img" />
                  <div>
                    <span className="fw-700 text-capitilize text-nowrap">
                      {getFileName(item.fileName)}
                    </span>
                    <span className="grey text-capitalize text-nowrap">
                      Type : {item.fileType}
                    </span>
                  </div>
                  <button
                    className="transparent"
                    onClick={() => {
                      handleDownload(item.fileName);
                    }}
                  >
                    <img src={download} alt="" />
                  </button>
                </div>
              </div>
            ))}
        </div>
      </div>

      <div>
        <div className="mt-4">
          <div>
            {/* <h6 className='fw-700 text-black'>Score</h6> */}
            {/* <div className="mt-2">
          <span className='fw-600 text-capitalize'>{details && details.assignment.user.name}</span>
          <div className="row">
            <div className="col-md-3">
            <input type="text" name="" id="" value={details && details.score} className='form-control' disabled="disabled"/>
            </div>
          </div>
        </div> */}
 {details && details.status == 'completed' && (
          <div className="row score mt-4">
            <div className="col-md-6">
              <div className="card-main bg-white ">
                <label className="mb-0 pb-0 fw-700 d-block">In Course</label>
                <small className="grey mt-0 pt-0 fw-400">Online, viva, field / lab work</small>
                <hr />
                <div className="mt-3">
                  <table class="table table-borderless">
                    <tbody>
                      {incoursedetails &&
                        incoursedetails.map((item, index) => (
                          <tr>
                            <td width={'80%'}>{item.title}</td>
                            <td width={'20%'}>
                              <input
                                onChange={e => handleInScoreChange(e, index)}
                                value={incatscore[index]}
                                className="score-input form-control"
                                type="number"
                                min="0"
                                max="10"
                                step="1"
                                pattern="[0-9]*"
                                inputmode="numeric"
                                name={item._id}
                                id=""
                              />
                            </td>
                          </tr>
                        ))}

                      <tr>
                        <td width={'80%'} className="font-weight-bold">
                          Total
                        </td>
                        <td width={'20%'} className="font-weight-bold">
                          {insum}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            <div className=" col-md-6">
              <div className="card-main bg-white">
                <label className="mb-0 pb-0 fw-700 d-block">Post Course</label>
                <small className="grey mt-0 pt-0 fw-400">
                Assignment
                </small>
                <hr />
                <div className="mt-3">
                  <table class="table table-borderless">
                    <tbody>
                      {postcoursedetails &&
                        postcoursedetails.map((item, index) => (
                          <tr>
                            <td width={'80%'}>{item.title}</td>
                            <td width={'20%'}>
                              <input
                                onChange={e => handlePostScoreChange(e, index)}
                                value={postcatscore[index]}
                                className="score-input form-control"
                                type="number"
                                min="0"
                                max="10"
                                step="1"
                                pattern="[0-9]*"
                                inputmode="numeric"
                                name={item._id}
                                id=""
                              />
                            </td>
                          </tr>
                        ))}

                      <tr>
                        <td width={'80%'} className="font-weight-bold">
                          Total
                        </td>
                        <td width={'20%'} className="font-weight-bold">
                          {postsum}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <h4 className="fw-500 mt-3">Grand Total - {postsum + insum} </h4>
          </div>
        )}
            {(details && details.status == 'approve') ||
            (details && details.status == 'reject') ? (
              <div className="row score mt-3">
                <div className="col-md-6">
                  <div className="card-main bg-white left-score">
                    <label className="mb-0 pb-0 fw-700 d-block">
                      In Course
                    </label>
                    <small className="grey mt-0 pt-0 fw-400">Online, viva, field / lab work</small>
                    <hr />
                    <div className="mt-3">
                      <table class="table table-borderless">
                        <tbody>
                          {details.preCourseScore.map(item => (
                            <tr>
                              <td width={'80%'}>
                                {item.inCourseCategory.title}
                              </td>
                              <td width={'20%'}>
                                <input
                                  disabled="disabled"
                                  value={item.categoryScore}
                                  className="score-input form-control"
                                  type="text"
                                  name=""
                                  id=""
                                />
                              </td>
                            </tr>
                          ))}

                          <tr>
                            <td width={'80%'} className="font-weight-bold">
                              Total
                            </td>
                            <td width={'20%'} className="font-weight-bold">
                              {details.preCourseTotal}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>

                <div className=" col-md-6">
                  <div className="card-main bg-white right-score">
                    <label className="mb-0 pb-0 fw-700 d-block">
                      Post Course
                    </label>
                    <small className="grey mt-0 pt-0 fw-400">
                    Assignment
                    </small>
                    <hr />

                    <div className="mt-3">
                      <table class="table table-borderless">
                        <tbody>
                          {details.postCourseScore.map(item => (
                            <tr>
                              <td width={'80%'}>
                                {item.postCourseCategory &&
                                  item.postCourseCategory.title}
                              </td>
                              <td width={'20%'}>
                                <input
                                  value={item.categoryScore}
                                  disabled="disabled"
                                  className="score-input form-control"
                                  type="text"
                                  name=""
                                  id=""
                                />
                              </td>
                            </tr>
                          ))}

                          <tr>
                            <td width={'80%'} className="font-weight-bold">
                              Total
                            </td>
                            <td width={'20%'} className="font-weight-bold">
                              {details.postCourseTotal}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div className="col-12 mt-3">
                  <h4 className="fw-500">
                    Grand Total - {details.grandTotal}{' '}
                  </h4>
                </div>
              </div>
            ) : (
              ''
            )}
          </div>
        </div>
        {details && details.status == 'completed' && (
          <div className="row mt-4 p-4">
            <div className="col-sm-12">
              <div className="text-end">
                <div onClick={onCancel} className="btn btn-secondary me-2">Cancel</div>
                <div onClick={handleIsConfirmSubmit} className="btn btn-danger me-2">
                  Resubmit
                </div>
                <div onClick={onApprove} className="btn btn-primary">
                  Submit
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <Modal show={show} onHide={handleCloseConfirm}>
        <Modal.Header closeButton>
          <Modal.Title>
          <h6 className='fw-bold'>Assignment Confirmation</h6>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure the assignment has to be resubmitted?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseConfirm}>
            Cancel
          </Button>
          <Button variant="primary" onClick={onReSubmit}>
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
    
  );
}
