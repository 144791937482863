import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import MenuItem from '@material-ui/core/MenuItem';
import Select from 'react-select'
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import APIRequest from '../utils/APIRequest';
import ConfigAPIURL from '../config/ConfigAPIURL';
import isEmpty from '../utils/isEmpty';
import Chip from "@mui/material/Chip";
import OutlinedInput from "@mui/material/OutlinedInput";
import Box from "@mui/material/Box";
import { useTheme } from '@mui/material/styles';
import Toaster from 'src/admin/utils/Toaster';
import { RichTextEditor } from '@mantine/rte';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const names = [
  'Oliver Hansen',
  'Van Henry',
  'April Tucker',
  'Ralph Hubbard',
  'Omar Alexander',
  'Carlos Abbott',
  'Miriam Wagner',
  'Bradley Wilkerson',
  'Virginia Andrews',
  'Kelly Snyder',
];

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}
export default function Invite() {

  var [selectedBatch, setSelectedBatch] = React.useState([]);
  var [selectedCourse, setSelectedCourse] = React.useState('');
  const [open, setOpen] = React.useState(false);
  var [options, setOptions] = useState([])
  var [batch, setBatch] = useState([])
  var [courseOptions, setCourseOptions] = useState([])
  var [batchLists, setBatchLists] = useState([])
  var [userOptions, setUserOptions] = useState([])
  const [checked, setChecked] = React.useState(true);
  const [emailerr, setEmailError] = useState({});
  const [meetingerr, setMeetingError] = useState({});
  const [subjecterr, setSubjectErr] = useState({})
  var [subject, setSubject] = useState('')
  const handleBatchChange = (e) => {
    let batchs = e.map(e => (e.value));
    setSelectedBatch(batchs)

    getUsersList(selectedCourse, batchs);
  }

  const theme = useTheme();
  var [personName, setPersonName] = useState([]);

  const handleCourseChange = (e) => {
    setSelectedCourse(e.target.value)
    getUsersList(e.target.value, selectedBatch);
  }
  var [editorcontent, setEditorContent] = useState('')

  const onEditorChange = (e) => {
    setEditorContent(e);
  }
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleEmailChange = (newValue) => {
    let useremails = newValue.map(e => (e.label.replace(/'/g, '"')))
    setPersonName(useremails);
  }

  const handleClose = () => {
    setPersonName()
    setChecked(true)
    setOpen(false);
    getCourse()
    setEditorContent()
    setSubject()
    setMeetingError({ '': '' })
    setSubjectErr({ '': '' })
  };
  const handleCheckChange = () => {
    setChecked(!checked);
    if (checked == false) {
      setPersonName()
    }
  }

  const handleSubChange = (e) => {
    subject = e.target.value
    setSubject(e.target.value)
  }
  useEffect(() => {
    getCourse()

  }, [])

  const getBatch = () => {

    APIRequest.request('POST', ConfigAPIURL.listOfDropdowns, JSON.stringify({ name: "Batch Name" })).then(
      response => {
        if (response.code === 100 && response.data.responseCode === 109) {
          options = response.data.result.options
          batch = options.filter(e => e.active && !isEmpty(e.value)).map(e => e.value)
          setBatch(batch);
        }
      }
    );
  }

  const getCourse = () => {

    APIRequest.request('GET', ConfigAPIURL.adminAllModuleList, '').then(
      response => {
        if (response.code === 100 && response.data.responseCode === 109) {
          courseOptions = response.data.module;
          setCourseOptions(courseOptions);
          selectedCourse = courseOptions[0]['_id'];
          setSelectedCourse(courseOptions[0]['_id'])
          getBatch()
        }
      }
    );
  }

  const getUsersList = (course, batch) => {

    APIRequest.request('GET', ConfigAPIURL.baseUrl + '/admin/user/batch-users?courseName=' + course + '&batchName=' + batch).then(res => {
      if (!isEmpty(res) && res.code === 100) {
        if (res.data.responseCode === 109) {
          setUserOptions(res.data.traineeUsers)
          userOptions = res.data.traineeUsers
        }
      }
    });
  }

  const sendInvite = (e) => {
    if (checked == true) {
      personName = userOptions.map(e => (e.email));
      setPersonName(personName);
      e.preventDefault()
      const isValid = formValidation()
      if (isValid || personName == '') {
        editorcontent = editorcontent.replace(/(<([^>]+)>)/ig, '')
        setEditorContent(editorcontent)
        if (personName == '') {
          Toaster.error(
            'No users found.',
            'topRight'
          );
        }
        else {
          const data = {
            'emailIds': personName,
            'meetingLink': editorcontent,
            'subject': subject
          }
          APIRequest.request('POST', ConfigAPIURL.sendMeeting, JSON.stringify(data)).then(
            response => {
              if (response.code === 100 && response.data.responseCode === 109) {
                Toaster.sucess(
                  'Notification sent successfully.',
                  'topRight'
                );
                handleClose()
              }
            }
          );
        }
      }
    }
    else {
      e.preventDefault()
      const isValid = formValidation()
      if (isValid) {

        editorcontent = editorcontent.replace(/(<([^>]+)>)/ig, '')
        const data = {
          'emailIds': personName,
          'meetingLink': editorcontent,
          'subject': subject
        }

        APIRequest.request('POST', ConfigAPIURL.sendMeeting, JSON.stringify(data)).then(
          response => {
            if (response.code === 100 && response.data.responseCode === 109) {
              Toaster.sucess(
                'Notification sent successfully.',
                'topRight'
              );
              handleClose()
            }
          }
        );
      }
    }
  }

  const formValidation = () => {
    const emailerr = {};
    const meetingerr = {};
    const subjecterr = {};
    let isValid = true;

    if (personName == undefined || personName == '') {
      emailerr.firstNameShort = "Please input Emails"
      isValid = false
    }
    if (editorcontent == undefined || editorcontent == '') {
      meetingerr.emailempty = "Please input meeting link"
      isValid = false
    }
    if (subject == undefined || subject == '') {
      subjecterr.firstNameShort = "Please input Subject"
      isValid = false
    }

    setEmailError(emailerr);
    setMeetingError(meetingerr);
    setSubjectErr(subjecterr)
    return isValid;
  }

  const options1 = [
    { value: 'batch1', label: 'batch1' },
    { value: 'batch2', label: 'batch2' },
    { value: 'batch3', label: 'batch3' }
  ]
  
  return (
    <div className='invite-popup'>
      <Button className="btn btn-primary me-2" variant="outlined" onClick={handleClickOpen}>
        NOTIFICATION
      </Button>
      <Dialog open={open} onClose={handleClose} maxWidth="md">
        <DialogTitle className='fw-700'>Send Notification</DialogTitle>
        <DialogContent>
          <div className="row">
          <div className='col-sm-6 mt-4'>
            <TextField
              className='drop_down_common'
              label="Select Course"
              select
              variant="outlined"
              value={selectedCourse}
              onChange={handleCourseChange}
            >
              {courseOptions.map(({ title ,_id}, index) => <MenuItem value={_id} >{title}</MenuItem>)}
            </TextField>
          </div>
          {/* <div className='mt-4 col-sm-6'>
            <TextField
              className='drop_down_common'
              label="Select Batch"
              select
              variant="outlined"
              value={selectedBatch}
              onChange={handleBatchChange}
            >
              {batch.map((index) => <MenuItem value={index} >{index}</MenuItem>)}
            </TextField>
          </div> */}
          <div className='mt-4 col-sm-6 multi-select-batch'>
            <label>Select Batch</label>
              <Select
                defaultValue=''
                isMulti
                name="colors"
                closeMenuOnSelect={false}
                options={batch.map((value) => ({ label: value, value: value }))}
                onChange={handleBatchChange}
                placeholder="Select Batches"
                className="basic-multi-select"
                classNamePrefix="select"
              />
            </div>
          <div className="mt-4">
            <input type="checkbox" name="" id="" checked={checked} onChange={handleCheckChange} />&nbsp;&nbsp;All Users
            {checked ? '' : <div>
              <Select
                labelId="demo-multiple-chip-label"
                id="demo-multiple-chip"
                isMulti
                options={userOptions.map(e => ({ label: e.email, value: e._id }))}
                onChange={newValue => handleEmailChange(newValue)}
                menuPortalTarget={document.body}
        menuPosition="fixed"
        
        styles={{
          menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
          menu: (provided) => ({ ...provided, zIndex: 9999 })
        }}
              >
              </Select>
              {Object.keys(emailerr).map((key) => {
                return <div style={{ color: "red" }}>{emailerr[key]}</div>
              })}
            </div>}
          </div>
          </div>
          <div className='mt-4'>
            <TextField
              id=""
              label="Enter Subject"
              autoComplete="something-unsupported"
              required
              variant="outlined"
              fullWidth
              value={subject}
              onChange={handleSubChange}
            />
            {Object.keys(subjecterr).map((key) => {
              return <div style={{ color: "red" }}>{subjecterr[key]}</div>
            })}
          </div>
   
          <div className='mt-4 rich_editor' >
            {/* <CKEditor
              editor={ClassicEditor}
              data=""
              onReady={editor => {
                // You can store the "editor" and use when it is needed.

              }}
              onChange={(event, editor) => {
                const editordata = editor.getData();
                onEditorChange(editordata)
              }}
              onBlur={(event, editor) => {

              }}
              onFocus={(event, editor) => {

              }}
            /> */}
            <RichTextEditor onChange={onEditorChange}
            />
            {Object.keys(meetingerr).map((key) => {
              return <div style={{ color: "red" }}>{meetingerr[key]}</div>
            })}
          </div>
        </DialogContent>
        <DialogActions>
          <Button className='btn btn-primary' onClick={handleClose}>Cancel</Button>
          <Button className='btn btn-primary' type='submit' onClick={sendInvite}>Submit</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
