
import React, { useCallback } from 'react'
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import MenuItem from '@material-ui/core/MenuItem';
import { useEffect, useState, useRef } from 'react';
import APIRequest from '../utils/APIRequest';
import ConfigAPIURL from '../config/ConfigAPIURL';
import isEmpty from '../utils/isEmpty';
import sample from 'src/assets/sampleFile/sample.csv'

export default function Send() {
  const [selectedBatch, setSelectedBatch] = useState();
  var [selectedCourse, setSelectedCourse] = useState();
  var [options, setOptions] = useState([])
  var [batch, setBatch] = useState([])
  var [selected, setSelected] = useState([])
  var [courseOptions, setCourseOptions] = useState([])
  const [open, setOpen] = React.useState(false);
  const [file, setFile] = useState()

  const [courseerr ,setCourseError] = useState({});
  const [batcherr ,setBatchError] = useState({});
  const [fileerr ,setFileError] = useState({});


  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleBatchChange = (e) => {
    setSelectedBatch(e.target.value);
  }
  const handleCourseChange = (e) => {
    setSelectedCourse(e.target.value);
  }
  const handleClose = () => {
    setOpen(false);
    setFile()
    getBatch()
    getCourse()
  };

  

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  }


  useEffect(() => {
    getBatch()
    getCourse()
  }, [])

  const getBatch = () => {
    APIRequest.request('POST', ConfigAPIURL.listOfDropdowns,  JSON.stringify({ name:"Batch Name"})).then(
      response => {
        if (response.code === 100 && response.data.responseCode === 109) {
          options = response.data.result.options
          setOptions(options);
          batch = options.filter(e => e.active && !isEmpty(e.value)).map(e => e.value)
          setBatch(batch);
          setSelectedBatch(batch[0])
        }
      }
    );
  }

  const getCourse= () =>{
    APIRequest.request('GET', ConfigAPIURL.adminAllModuleList, '').then(
      response => {
        if (response.code === 100 && response.data.responseCode === 109) {
          courseOptions = response.data.module
          selectedCourse = courseOptions[0]['_id'];
          setCourseOptions(courseOptions);
          setSelectedCourse(selectedCourse)
        }
      }
    );
  }

const onSubmit= (e) =>{
  e.preventDefault()
  const isValid = formValidation()
  if(isValid){


  const formData = new FormData();
	formData.append('file', file);
  formData.append('courseName', selectedCourse);
  formData.append('batchName', selectedBatch);

  APIRequest.multipartForDataRequest('POST', ConfigAPIURL.baseUrl+'/admin/user/import-user-csv', formData).then(
    response => {
      if (response.code === 100 && response.data.responseCode === 109) {
        window.alert(response.data.message);
        handleClose();
      }
    }
  );
}
}


const formValidation = () =>{
  const courseerr = {};
  const batcherr = {};
  const fileerr = {};
 
  let isValid = true;

  if(selectedCourse == undefined || selectedCourse == ''){
    courseerr.firstNameShort = "Please input Course"
      isValid = false
  }
  if(selectedBatch == undefined || selectedBatch == ''){
    batcherr.emailempty = "Please input Batch"
      isValid = false
  }
  if(file == undefined){
    fileerr.phoneempty = "Please input file"
      isValid = false
  }

      setCourseError(courseerr);
      setBatchError(batcherr);
      setFileError(fileerr)
      
      return isValid;
}

  return (
    <div className='send_popup'>
      <Button className="btn btn-primary" variant="outlined" onClick={handleClickOpen}>
        INVITE
      </Button>
      <Dialog open={open} onClose={handleClose} maxWidth="xs">
        <DialogTitle className='fw-700'>Invite Users</DialogTitle>
        <DialogContent>
          <div className='mt-4'>
            {/* <label className='d-block invite_label'>Course</label> */}
            <TextField
              className='drop_down_common'
              label="Select Course"
              select
              variant="outlined"
               value={selectedCourse}
              onChange={handleCourseChange}
            >
           {courseOptions.map(({ title,_id }, index) => <MenuItem value={_id} >{title}</MenuItem>)}
            </TextField>
            {Object.keys(courseerr).map((key)=>{
                                return <div style={{color : "red"}}>{courseerr[key]}</div>
                            })}
          </div>
          <div className='mt-5'>
            {/* <label className='d-block invite_label'>Batch</label> */}
            <TextField
              className='drop_down_common'
              label="Select Batch"
              select
              variant="outlined"
              value={selectedBatch}
              onChange={handleBatchChange}
              >
            {batch.map(( index) => <MenuItem value={index} >{index}</MenuItem>)}
            </TextField>
            {Object.keys(batcherr).map((key)=>{
                                return <div style={{color : "red"}}>{batcherr[key]}</div>
                            })}
          </div>
          <div className='batches_drag_drop'>
          <input onChange={handleFileChange} type="file" name="" id="" className='form-control mt-2' />
          {Object.keys(fileerr).map((key)=>{
                                return <div style={{color : "red"}}>{fileerr[key]}</div>
                            })}
          </div>

          <div className='mt-4'>
          <a href={sample} download="sample.csv" className='d-inline-block'>Download Sample Import</a>
          </div>
        </DialogContent>
        
        <DialogActions>
          <Button className='btn btn-primary' onClick={handleClose}>Cancel</Button>
          <Button className='btn btn-primary' onClick={onSubmit}>Submit</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
