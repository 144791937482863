import React from 'react';
import AWS from 'aws-sdk';
import { Modal, ProgressBar } from 'react-bootstrap';
import Toaster from '../utils/Toaster';
export default class FileUpload extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      accessKeyId: this.props.aws.accessKeyId,
      secretAccessKey: this.props.aws.secretAccessKey,
      region: this.props.aws.region,
      progress: 0,
      modelShow: false
    };
    this._s3Upload = this._s3Upload.bind(this);
  }

  handleModalClose = () => {
    this.setState({
      modelShow: false
    });
  };
  _s3Upload = () => {
    this.setState({ modelShow: true }, () => {
      AWS.config.update({
        credentials: new AWS.CognitoIdentityCredentials({
          IdentityPoolId: this.props.aws.CognitoIdentityCredentials
            .IdentityPoolId
        }),
        region: this.props.aws.CognitoIdentityCredentials.region
      });
      var s3 = new AWS.S3({ signatureVersion: 'v4' });
      let fileExtension = this.props.file.name.split('.');
      var filename =
        Math.floor(Date.now() / 1000) +
        '.' +
        fileExtension[fileExtension.length - 1];
      var params = {
        Bucket: this.props.bucket,
        Key: filename,
        ContentType: this.props.file.type,
        ACL: 'public-read',
        Body: Buffer.from(this.props.base64, 'base64'),
        ContentEncoding: 'base64'
      };

      var request = s3.putObject(params);
      request
        .on('httpUploadProgress', progress => {
          let progressValue = Math.ceil(
            (progress.loaded / progress.total) * 100
          );
          this.setState({
            progress: progressValue
          });
        })
        .on('success', response => {
          this.setState({ modelShow: false, progress: 0 }, () => {
            Toaster.sucess('Successfully uploaded', 'topRight');
            this.props.callback(
              'https://' +
                response.request.httpRequest.endpoint.hostname +
                response.request.httpRequest.path
            );
          });
        })
        .on('error', (error, response) => {
          console.log(error);
          // Toaster.error("File upload failed, try after some time", "topRight");
        })
        .on('complete', response => {})
        .send();
    });
  };
  
  render() {
    return (
      <div>
        <Modal
          show={this.state.modelShow}
          onHide={this.handleModalClose}
          style={{ marginTop: '100px' }}
        >
          <Modal.Header>
            <Modal.Title>Uploading....</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <ProgressBar
              striped
              variant="success"
              now={this.state.progress}
              label={`${this.state.progress}%`}
            />
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}
