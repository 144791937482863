import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Accordion, Card, Button, ListGroup } from 'react-bootstrap';
import { FaCaretDown, FaCaretUp } from 'react-icons/fa';
import 'bootstrap/dist/css/bootstrap.min.css';
import LocalStorage from './config/LocalStorage';
import { connect } from 'react-redux';
import Toaster from './utils/Toaster';
import SideBarMenu from './SideBarMenu';
// import { withTranslation } from 'react-i18next';
import ReactDOM from 'react-dom';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import { Typography, Box, Badge, Avatar } from '@material-ui/core';
// import './admin.css';
import './Sidebar.css';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Tooltip } from '@material-ui/core';
export default class SideBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      height: 0,
      // inboundSelectedRow: LocalStorage.inboundSelectedRow,
      subMenuClickEnable: true,
      activeItem: ''
    };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    this.toggleLocation = this.toggleLocation.bind(this);
  }

  componentDidMount = () => {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
    this._removeEmptyMenu();
  };
  _removeEmptyMenu = () => {
    var node = ReactDOM.findDOMNode(this);
    [].forEach.call(
      document.querySelectorAll('.sideNavFixed .accordion'),
      el => {
        let child = false;
        if (el.querySelectorAll('.accordion > div:not([class])').length > 0) {
          [].forEach.call(
            el.querySelectorAll('.accordion > div:not([class])'),
            divResult => {
              if (divResult.childElementCount > 0) {
                return (child = true);
              }
            }
          );
          if (!child) {
            el.remove();
          }
        }
      }
    );
  };
  componentWillUnmount = () => {
    this.updateWindowDimensions();
    window.removeEventListener('resize', this.updateWindowDimensions);
  };

  updateWindowDimensions = () => {
    this.setState({ height: window.innerHeight });
  };
  getStyles() {
    const styles = {};
    styles.height = `${this.state.height - 56}px`;
    return styles;
  }
  toggleLocation = () => {
    this.setState({ subMenuClickEnable: true });
    this.setState(prevState => ({}));
  };
  locationChange = (url, condition) => {
    if (condition) {
      if (this.state.subMenuClickEnable) {
        window.location.href = url;
        this.toggleLocation();
      }
    } else {
      Toaster.error('Please select a row.', 'topRight');
    }
  };
  locationSubMenuChange = (url, condition) => {
    if (condition) {
      window.location.href = url;
      this.toggleLocation();
    } else {
      Toaster.error('Please select a row.', 'topRight');
    }
  };
  handleClick = item => {
    this.setState(prevState => ({ [item]: !prevState[item] }));
  };
  render() {
    const incompleteIcon = (
      <FaCaretDown style={{ float: 'right', marginTop: '5px' }}></FaCaretDown>
    );
    const completeIcon = (
      <FaCaretUp style={{ float: 'right', marginTop: '5px' }}></FaCaretUp>
    );
    let drawerClass = [];
    let mainClass = [];
    if (this.props.drawerPos === 1) {
      drawerClass.push('drawerMin');
      mainClass.push('mainMin');
      mainClass.push('aside_scrollbar');
    } else if (this.props.drawerPos === 2) {
      drawerClass.push('drawerOpen');
      mainClass.push('mainOpen');
      mainClass.push('aside_scrollbar');
    } else {
      drawerClass = [];
      mainClass = [];
    }
    const location = window.location.hash;
    let locationName = location.substring(location.lastIndexOf('/') + 1);

    const setActive = url => {
      if (this.state.activeItem === '') {
        return url === 'user';
      } else {
        return url === this.state.activeItem;
      }
    };

    return (
     <div className='sidbar-outer'>
        <div className="sideNavFixed">
        {/* <div>
          <div className='align-center mt-4'>
          <Avatar style={{ backgroundColor: 'orange',}}>
            {LocalStorage.userName[0]}
          </Avatar>
          <Tooltip title={LocalStorage.userName}>
            <p
              style={{
                fontSize: '16px',
                color: '#FFFF',
                fontWeight: '500',
                margin:'10px 8px'
              }}
            >
              {LocalStorage.userName.length > 9
                ? LocalStorage.userName.slice(0, 9)
                : LocalStorage.userName}
            </p>
          </Tooltip>
          </div>
        </div> */}
        <div
          style={{
            margin: '10px',
            boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
            borderRadius: '8px'
          }}
        >
          <ListGroup as="ul">
            {SideBarMenu.menu.map(menu => (
              <Link
                to={menu.linkPage ? menu.link : '#'}
                style={{ textDecorationLine: 'none' }}
              >
                <ListGroup.Item
                  as="li"
                  action
                  active={setActive(menu.urlEndWith)}
                  onClick={() => {
                    this.setState({ activeItem: menu.urlEndWith });
                  }}
                >
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    {menu.icon}
                    <h6 style={{ marginLeft: '5px' }}>{menu.display}</h6>
                  </div>
                </ListGroup.Item>
              </Link>
            ))}
          </ListGroup>
        </div>
      </div>
     </div>
    );
  }
}
const mapStateToProps = state => {
  return state;
};
// export default withTranslation('translations')(connect(mapStateToProps)(SideBar));
