import React, { Component } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import Logout from './utils/Logout';
import Page404 from './templates/Page404';
import CircularProgress from '@material-ui/core/CircularProgress';
import batches from './batches/Batches';
import assignmentTabs from './assignment/AssignmentTabs';
import ViewAssignment from './assignment/ViewAssignment';
import feedback from './feedback/feedback';
import CreateAssignment from './assignment/CreateAssignmentPage';
import Certificates from './certificates/Certificates';


const User = React.lazy(() => import('./user/v2/user'));
const Module = React.lazy(() => import('./module/v2/courses'));
const Plan = React.lazy(() => import('./plan/Plans'));
const Results = React.lazy(() => import('./results/v2/results'));
const Quiz = React.lazy(() => import('./quiz/v2/quiz'));
const Chapter = React.lazy(() => import('./chapter/v2/chapter'));
const Profile = React.lazy(() => import('./profile/Profile'));
const Dashboard = React.lazy(() => import('./Dashboard'));
const Services = React.lazy(() => import('./services/Services'));
const Settings = React.lazy(() => import('./settings/v2/settings'));
const Batches= React.lazy(() => import('./batches/Batches'));
const AssignmentTabs= React.lazy(() => import('./assignment/AssignmentTabs'));
const Assignment= React.lazy(() => import('./assignment/Assignment'));
const CreateAssignmentPage= React.lazy(() => import('./assignment/CreateAssignmentPage'));
const Assessment= React.lazy(() => import('./assessment/Assessment'))
const ChangePassword= React.lazy(() => import('./change-password/ChangePassword'))
const Feedback= React.lazy(() => import('./feedback/feedback'))


const FacultyAssignmentTabs= React.lazy(() => import('./faculty-assignment/FacultyAssignmentTabs'));
const FacultyAssignment= React.lazy(() => import('./faculty-assignment/FacultyAssignment'));
const FacultyViewAssignment= React.lazy(() => import('./faculty-assignment/FacultyViewAssignment'));
const FacultyAssessment= React.lazy(() => import('./faculty-assessment/FacultyAssessment'));

export default class Content extends Component {
  render() {
    return (
      <Switch>
        <React.Suspense
          fallback={
            <div
              style={{
                width: '100%',
                height: '90vh',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              {<CircularProgress />}
            </div>
          }
        >
          <Route
            exact
            path="/admin"
            render={() => <Redirect to="/admin/user" />}
          />
          <Route exact path={['/admin/dashboard']} component={Dashboard} />
          <Route exact path={['/admin/user']} component={User} />
          <Route exact path={['/admin/course']} component={Module} />
          <Route exact path={['/admin/plan']} component={Plan} />
          <Route exact path={['/admin/quiz']} component={Quiz} />
          <Route exact path={['/admin/quiz/results']} component={Results} />
          <Route exact path={['/admin/chapter']} component={Chapter} />
          <Route exact path={['/admin/profile']} component={Profile} />
          <Route exact path={['/admin/services']} component={Services} />
          <Route exact path={['/admin/settings']} component={Settings} />
          <Route exact path={['/admin/batches']} component={Batches} />

          <Route exact path={['/admin/assignment-manage']} component={AssignmentTabs} />
          <Route exact path={['/admin/assignment']} component={Assignment} />
          <Route exact path={['/admin/create-assignment']} component={CreateAssignmentPage} />
          <Route exact path={['/admin/view-assignment']} component={ViewAssignment} />
          <Route exact path={['/admin/Assessment']} component={Assessment} />
          <Route exact path={['/admin/certificates']} component={Certificates} />
          <Route exact path={['/admin/change-password']} component={ChangePassword} />

          <Route exact path={['/admin/faculty/assignment-manage']} component={FacultyAssignmentTabs} />
          <Route exact path={['/admin/faculty/assignment']} component={FacultyAssignment} />
          <Route exact path={['/admin/faculty/view-assignment']} component={FacultyViewAssignment} />
          <Route exact path={['/admin/faculty/assessment']} component={FacultyAssessment} />
          <Route exact path={['/admin/feedback']} component={Feedback} />
          
          <Route
            exact
            path={['/admin/logout']}
            render={() => {
              Logout.logout();
            }}
          />

          {/* <Route render={() => <h3>Coming soon.</h3>} /> */}
          {/* <Redirect to="/404" /> */}
        </React.Suspense>
        <Route component={Page404} />
      </Switch>
    );
  }
}
