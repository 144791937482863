import React, { createContext, useEffect, useReducer, useState } from 'react';
import SplashScreen from 'src/components/SplashScreen';

import firebase from 'src/lib/firebase';

import Toaster from 'src/admin/utils/Toaster';
import APIRequest from 'src/admin/utils/APIRequest';
import ConfigAPIURL from 'src/admin/config/ConfigAPIURL';
import SlideInDialog from "src/templates/dialog/SlideInDialog";
import isEmpty from 'src/admin/utils/isEmpty';
import LocalStorage from 'src/admin/config/LocalStorage';

const Signup = React.lazy(() => import("src/components/Signup"));
const initialAuthState = {
  isAuthenticated: false,
  isInitialised: false,
  user: null
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'AUTH_STATE_CHANGED': {
      const { isAuthenticated, user } = action.payload;
      return {
        ...state,
        isAuthenticated,
        isInitialised: true,
        user
      };
    }
    default: {
      return { ...state };
    }
  }
};

const AuthContext = createContext({
  ...initialAuthState,
  method: 'FirebaseAuth',
  createUserWithEmailAndPassword: () => Promise.resolve(),
  signInWithEmailAndPassword: () => Promise.resolve(),
  signInWithGoogle: () => Promise.resolve(),
  logout: () => Promise.resolve()
});

export const AuthProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialAuthState);
  const [slideInDialog, setSlideInDialog] = useState(false);
  const [slideInProfileNotActiveDialog, setSlideInProfileNotActiveDialog] = useState(false);
  const [slideInSignupDialog, setSlideInSignupDialog] = useState(false);
  const [user, setUser] = useState(null);
  const signInWithEmailAndPassword = (email, password) => {
    return firebase.auth().signInWithEmailAndPassword(email, password);
  };

  const signInWithGoogle = () => {
    const provider = new firebase.auth.GoogleAuthProvider();
    return firebase.auth().signInWithPopup(provider);
  };

  const createUserWithEmailAndPassword = async (
    // name,
    email,
    password
  ) => {
    return firebase.auth().createUserWithEmailAndPassword(
      // name,
      email,
      password
    );
  };

  const logout = () => {
    localStorage.setItem('Faculty' , ' ')
    localStorage.setItem('Trainee' , ' ')
    return firebase.auth().signOut();
  };

  const isLogin = (user) => {
    APIRequest.request(
      'POST',
      ConfigAPIURL.sessionFirebaseValidation,
      JSON.stringify({
        userId: user.uid,
        email: user.email,
        userName: user.email,
        name: user.displayName,
        avatar: user.photoURL
      })
    ).then(response => {
      if (response !== undefined && response !== null) {
        if (response.code === 100 && response.data.responseCode === 109) {

          LocalStorage.userEmail = user.email;
          dispatch({
            type: 'AUTH_STATE_CHANGED',
            payload: {
              isAuthenticated: true,
              user: {
                id: response.data.user._id,
                avatar: response.data.user.avatar,
                email: response.data.user.email,
                name: response.data.user.name || response.data.user.email,
                role: response.data.user.userType,
                tier: response.data.user.tier,
                mobileNo: response.data.user.mobileNo || '',
                country: response.data.user.country || '',
                city: response.data.user.city || '',
                state: response.data.user.state || ''
              }
            }
          });
        } else if (response.data.responseCode === 115) {
          Toaster.error(
            'Your Account has to be approved to login.',
            'topRight'
          );
          logout();
          dispatch({
            type: 'AUTH_STATE_CHANGED',
            payload: {
              isAuthenticated: false,
              user: null
            }
          });
        } 
        else if (response.data.responseCode === 108) {
          // logout();
          setUser(response.data.user);
          setSlideInProfileNotActiveDialog(true);
          dispatch({
            type: 'AUTH_STATE_CHANGED',
            payload: {
              isAuthenticated: false,
              user: null
            }
          });
        }
        else if (response.data.responseCode === 125) {
          // logout();
          setUser(response.data.user);
          setSlideInDialog(true);
          dispatch({
            type: 'AUTH_STATE_CHANGED',
            payload: {
              isAuthenticated: false,
              user: null
            }
          });
        }  else if (response.data.responseCode === 103) {
          setUser(response.data.user);
          setSlideInSignupDialog(true);
          dispatch({
            type: 'AUTH_STATE_CHANGED',
            payload: {
              isAuthenticated: false,
              user:null
            }
          });
        } 
        else {
          dispatch({
            type: 'AUTH_STATE_CHANGED',
            payload: {
              isAuthenticated: false,
              user: null
            }
          });
        }
      }
    });
  }
  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged(user => {
  
      if (user) {
        // Here you should extract the complete user profile to make it available in your entire app.
        // The auth state only provides basic information.
        isLogin(user);
      } else {
        dispatch({
          type: 'AUTH_STATE_CHANGED',
          payload: {
            isAuthenticated: false,
            user: null
          }
        });
      }
    });

    return unsubscribe;
  }, [dispatch]);

  if (!state.isInitialised) {
    return <SplashScreen />;
  }

  const handleSlideInDialog = () => {
    setSlideInDialog(false);
    setSlideInSignupDialog(false);
    dispatch({
      type: 'AUTH_STATE_CHANGED',
      payload: {
        isAuthenticated: false,
        user: null
      }
    });
  };
  const handleSlideInNotActiveDialog = () => {
    setSlideInProfileNotActiveDialog(false);
    dispatch({
      type: 'AUTH_STATE_CHANGED',
      payload: {
        isAuthenticated: false,
        user: null
      }
    });
  };
  
  const handleLogoutRequest = (response) => {
    if (response === 'agree') {
      APIRequest.request(
        'PUT',
        ConfigAPIURL.logoutUsers,
        JSON.stringify({ userId: user })
      ).then((response) => {
        if (response !== undefined && response !== null) {
          if (response.code === 100 && response.data.responseCode === 109) {
            
           window.location.reload();
            Toaster.sucess(
              'You have successfully logged out from other devices. You can login now.',
              'topRight'
            );
            handleSlideInDialog();
          }
        }
      });
    } else {
      handleSlideInDialog();
    }
  };


  return (
    <>
    <AuthContext.Provider
      value={{
        ...state,
        method: 'FirebaseAuth',
        createUserWithEmailAndPassword,
        signInWithEmailAndPassword,
        signInWithGoogle,
        logout
      }}
    >
      {children}
      
      <SlideInDialog
title={""}
contentComponent={"Already logged in some other device. Do you want to logout from other devices?"}
fullWidth={true}
maxWidth={'sm'}
dialogActionsStatus={true}
slideInDialog={slideInDialog}
setSlideInDialog={handleSlideInDialog}
handleDeleteRequest={handleLogoutRequest}
/>
<SlideInDialog
title={""}
contentComponent={"You Profile is inactive.Please contact to admin"}
fullWidth={true}
maxWidth={'sm'}
dialogActionsStatus={true}
slideInDialog={slideInProfileNotActiveDialog}
setSlideInDialog={handleSlideInNotActiveDialog}
handleDeleteRequest={handleSlideInNotActiveDialog}
/>

<React.Suspense fallback={<div>Loading...</div>}>
<SlideInDialog
title={""}
contentComponent={"You are not registered in the system"}
fullWidth={true}
maxWidth={'sm'}
dialogActionsStatus={true}
slideInDialog={slideInSignupDialog}
setSlideInDialog={handleSlideInDialog}
handleDeleteRequest={handleSlideInDialog}
/>
</React.Suspense>
    </AuthContext.Provider>

   
</>
  );
};

export default AuthContext;
